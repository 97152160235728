<section class="max--width section">
  <div class="container">
    <div fxLayout="row" fxLayout.lt-md="column">
      <div fxFlex="28%" class="text__align--left"></div>

      <div fxFlex="72%" class="text__align--left">
        <div class="container">
          <div fxLayout="row" fxLayout.lt-md="column">
            <div fxFlex="100%" class="text__align--left">
              <ng-template #loadingSevenDaysWeather>
                <mat-progress-bar mode="indeterminate"></mat-progress-bar>
              </ng-template>

              <compass-seven-days-weather
                *ngIf="
                  sevenDaysWeather$ | async as sevenDaysWeather;
                  else loadingSevenDaysWeather
                "
                [sevenDaysWeather]="sevenDaysWeather"
              >
              </compass-seven-days-weather>
            </div>
          </div>
        </div>
        <div class="container">
          <div fxLayout="row" fxLayout.lt-md="column">
            <div fxFlex="33%" class="text__align--left"></div>
            <div fxFlex="33%" class="text__align--left"></div>
            <div fxFlex="33%" class="text__align--left"></div>
          </div>
        </div>

        <ng-container> </ng-container>
      </div>
    </div>
  </div>
</section>
