<lab-page-wrapper>
    <compass-map></compass-map>

    <!-- MAP -->
    <!--<compass-map-loader *ngIf="mapIsOn && business$ | async as business;" [setup]="business" style="width: 100%;"
        style="width: 100%;"></compass-map-loader>
-->

    <!-- TABLE -->
    <section>
        <div class="container container-start--tight">
            <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="8%">
                <div fxFlex="100%">
                    <compass-business-table *ngIf="!mapIsOn" style="width: 100%;">
                    </compass-business-table>
                </div>
            </div>
        </div>
    </section>

    <!-- MENU -->

    <compass-start-tight-menu>
        <compass-business-menu [setup]='lateral_menu'></compass-business-menu>
    </compass-start-tight-menu>

    <!-- MENU OPEN SIDENAV -->
    <compass-start-tight-sidenav>
        <ng-template #loading_business>
            <mat-progress-bar mode="indeterminate"></mat-progress-bar>
        </ng-template>
        <!-- MENU OPEN CONTENT -->
        <compass-business-form *ngIf="isOpen && isFilterStage(activeStage) && business$ | async as business"
            [setup]="business" style="width: 100%;"></compass-business-form>

        <compass-business-indicators *ngIf="!isFilterStage(activeStage)" style="width: 100%;">
        </compass-business-indicators>
    </compass-start-tight-sidenav>

    <compass-end-right-sidenav-base>
        <compass-indicators-sidenav *ngIf="clickedShape && clickedShape === 'indicators'">
        </compass-indicators-sidenav>
    </compass-end-right-sidenav-base>
</lab-page-wrapper>
