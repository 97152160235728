import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';
import { Router } from '@angular/router';
import { CategoryService } from '@compass/categories/data-access-category';
import { HttpErrorResponse, HttpEventType } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { of } from 'rxjs';
import { Poi, SidenavPoisApiService } from './sidenav-pois-api.service';
import { SidenavPoisLevelsService } from './sidenav-pois-levels.service';
import { GeoCoordsService } from '@compass/geo-coords/data-access';
import { Observable } from 'rxjs/Observable';

export interface PoiDb {
  type: string;
  properties: PoiProperty;
  geometry: PoiGeometry;
}

export interface PoiProperty {
  id: string;
  class_: string;
  id_alimarket: null;
  categoria: string;
  id_categoria: number;
  key_categoria: string;
  sub_categoria: string;
  key_sub_categoria: string;
  nombre: string;
  direccion: string;
  id_cc: null;
  nombre_cc: null;
  sup_m2: null;
  comentarios: string;
}

export interface PoiGeometry {
  type: string;
  coordinates: number[];
}

export interface PoiForm {
  key_sub_categoria: string;
  nombre: string;
  direccion: string;
  comentarios: string;
  x: number;
  y: number;
}

@Injectable()
export class PoiService {
  private fileUploadUrl: string =
    '/abacusvalidator/file_val/glocally/collection/point_poi/upload/';
  private poisUrl = '/abacusgis/core/glocally/collection/point_poi/';
  public pois$ = new BehaviorSubject(undefined);
  public poi$ = new BehaviorSubject(undefined);
  categories$ = this.categoryService.categories$;
  public studyPois$ = new BehaviorSubject(undefined);
  categoriesList$ = this.sidenavPoisLevelsService.categoriesList$;
  transportList$ = this.sidenavPoisLevelsService.transportList$;
  localCategoriesList$ = this.sidenavPoisLevelsService.localCategoriesList$;

  constructor(
    private http: HttpClient,
    private router: Router,
    private categoryService: CategoryService,
    private sidenavPoisApiService: SidenavPoisApiService,
    private sidenavPoisLevelsService: SidenavPoisLevelsService,
    private geoCoordsService: GeoCoordsService
  ) {
  }


  getAll() {
    this.pois$.next(undefined);
    return this.http
      .get(`${this.poisUrl}?query={"properties.id_categoria": 20}`)
      .pipe(map((pois: PoiDb[]) => pois.map((p) => p.properties)))
      .subscribe((mappedPois: PoiProperty[]) => {
        this.pois$.next(mappedPois);
      });
  }

  getById(id: string) {
    this.poi$.next(undefined);
    return this.http
      .get(`${this.poisUrl}?query={"properties.id" : "${id}"}`)
      .pipe(map((pois: PoiDb[]) => pois?.shift()))
      .subscribe((poi: PoiDb) => {
        this.poi$.next(poi);
      });
  }

  /**
   * Returns the POIs which uses the passed keySubCategory.
   *
   * @param keySubCategory
   * @param excludeDeleted
   */
  getBySubCategoryKey(keySubCategory: string, excludeDeleted: boolean = true) {
    let query = `"properties.key_sub_categoria":"${keySubCategory}"`;

    if (excludeDeleted) {
      query += `,"properties.remove":{"$exists": false}`;
    }

    return this.http
      .get(`${this.poisUrl}?query={${query}}`)
      .pipe(map((pois: PoiDb[]) => pois));
  }


  /**
   * Get an array of all used categories in all the POIs (including deleted ones)
   */
  getUsedCategories(): Observable<any> {
    return this.http
      .get(`${this.poisUrl}?distinct=properties.key_sub_categoria`);
  }

  buildPoi(formValues: any): PoiDb {
    const { x, y, ...poi } = formValues;
    poi.class_ = 'point_poi';
    poi.categoria = 'Glocally Pois';
    poi.id_categoria = 20;
    poi.key_categoria = 'glocally_pois';
    poi.sub_categoria = this.categories$.value.find(
      (c) => c.key_sub_categoria === poi.key_sub_categoria
    )?.sub_categoria;
    const newPoi: PoiDb = {
      type: 'Feature',
      properties: poi,
      geometry: {
        type: 'Point',
        coordinates: [Number(x), Number(y)]
      }
    };
    return newPoi;
  }

  addPoi(poi: PoiProperty) {
    this.pois$.next(undefined);
    const newPoi: PoiDb = this.buildPoi(poi);
    return this.http
      .post<PoiDb>(`${this.poisUrl}`, newPoi)
      .subscribe((d: any) => {
        this.router.navigate(['/pois']);
      });
  }

  updatePoi(id: string, poi: PoiProperty) {
    const newPoi: PoiDb = this.buildPoi(poi);
    newPoi.properties.id = id;
    return this.http
      .put<PoiDb>(`${this.poisUrl}`, newPoi)
      .subscribe((d: any) => {
        this.router.navigate(['/pois']);
      });
  }

  deletePoi(id: string) {
    this.pois$.next(undefined);
    return this.http
      .delete<PoiDb>(`${this.poisUrl}${id}`)
      .subscribe((d: any) => {
        this.getAll();
      });
  }

  clearPoi() {
    this.poi$.next(undefined);
  }

  public upload(formData, fileName) {
    return this.http
      .post<any>(this.fileUploadUrl, formData, {
        reportProgress: true,
        observe: 'events'

      })
      .pipe(
        map((event) => {
          switch (event.type) {
            case HttpEventType.Response:
              this.getAll();
              return event;
          }
        }),
        catchError((error: HttpErrorResponse) => {
          return of(error);
        })
      );
  }

  getPOIsUrl(type: string, study: any) {
    let query = `?query=`;
    switch (type) {
      case 'global': {
        query += `{"properties.key_categoria":{"$nin":["transporte","glocally_pois"]}}`;
        break;
      }
      case 'local': {
        query += `{"properties.key_categoria":"glocally_pois"}`;
        break;
      }
      case 'transport': {
        query += `{"properties.key_categoria":"transporte"}`;
        break;
      }
    }

    return `${this.poisUrl}${query}&${this.geoCoordsService.getFrom(study)}`;
  }

  getAreaGlobal(study: any) {
    if (study.properties.saved_global_pois) {
      return this.categoriesList$.next(study.properties.saved_global_pois);
    }
    if (!this.categoriesList$.value) {
      // important to keep track when stage changes
      this.studyPois$.next(undefined);
      return this.http
        .get(this.getPOIsUrl('global', study))
        .subscribe((studyPois: PoiDb[]) => {
          this.studyPois$.next(studyPois);
          const nested_data = this.sidenavPoisApiService.nestCategories(
            studyPois,
            'key_categoria'
          );
          const mapped_data = this.sidenavPoisApiService.setFourLevelsProps(
            nested_data,
            studyPois
          );
          this.categoriesList$.next(mapped_data);
        });
    }
  }

  getAreaLocal(study: any) {
    if (!this.localCategoriesList$.value) {
      // important to keep track when stage changes
      this.studyPois$.next(undefined);
      this.categoriesList$.next(undefined);
      if (study.properties.saved_local_pois) {
        return this.localCategoriesList$.next(
          study.properties.saved_local_pois
        );
      }
      return this.http
        .get(this.getPOIsUrl('local', study))
        .subscribe((studyPois: PoiDb[]) => {
          this.studyPois$.next(studyPois);
          let nested_data = this.sidenavPoisApiService.nestTwoLevelsPois(
            studyPois,
            'key_sub_categoria'
          );
          let mapped_data = this.sidenavPoisApiService.setTwoLevelsProps(
            nested_data,
            studyPois
          );
          this.localCategoriesList$.next(mapped_data);
        });
    }
  }

  getAreaTransport(study: any) {
    if (!this.transportList$.value) {
      // important to keep track when stage changes
      if (study.properties.saved_transport) {
        return this.transportList$.next(study.properties.saved_transport);
      }
      return this.http
        .get(this.getPOIsUrl('transport', study))
        .subscribe((studyPois: PoiDb[]) => {
          this.studyPois$.next(studyPois);
          const nested_data = this.sidenavPoisApiService.nestTwoLevelsPois(
            studyPois,
            'key_sub_categoria'
          );
          const mapped_data = this.sidenavPoisApiService.setTwoLevelsProps(
            nested_data,
            studyPois
          );
          this.transportList$.next(mapped_data);
        });
    }
  }
}
