import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";

@Injectable({ providedIn: "root" })
export class MapBoxService {
  constructor(private http: HttpClient) { }
  // basic, light, dark
  getMapBoxUrl = (style) => {
    switch (style) {

      case 'light':
        return [
          {
            key: "light",
            value:
              "https://api.mapbox.com/styles/v1/adegracia/cjacly1ud4fg52rpkca3b85hj/tiles/256/{z}/{x}/{y}?access_token=pk.eyJ1IjoiYWRlZ3JhY2lhIiwiYSI6ImNpdXBybDMzMTAxajAyeXA0ZnE0cTN3dGkifQ.Sq6NjjMcGmc5JzPAC7Tnzg",
          },
        ];


        break;
      case 'dark':
        return [
          {
            key: "dark",
            value:
              "https://api.mapbox.com/styles/v1/adegracia/ckc5y66vk0rqq1io1mjiiyq0i/tiles/256/{z}/{x}/{y}?access_token=pk.eyJ1IjoiYWRlZ3JhY2lhIiwiYSI6ImNpdXBybDMzMTAxajAyeXA0ZnE0cTN3dGkifQ.Sq6NjjMcGmc5JzPAC7Tnzg",
          },
        ];
        break;

      case 'transport_light':
        return [
          {
            key: "transport_light",
            value:
              "https://tile.thunderforest.com/transport/{z}/{x}/{y}.png?apikey=0b5c3b0e9ca944fc86d8fb7d1d88da4c",
          },
        ];


        break;



      default:
        return [
          {
            key: "basic",
            value:
              "https://api.mapbox.com/styles/v1/abacus-consulting/cjxem7nyt09cj1cmkiz7v371c/tiles/256/{z}/{x}/{y}?access_token=pk.eyJ1IjoiYWJhY3VzLWNvbnN1bHRpbmciLCJhIjoiY2p4ZW00Ync1MGN4ejN6czAybGF3cXowYiJ9.Gy0d0IYnSxyJCjuPp8GRUw",
          },
        ];


    }

  };


}
//  TOKENS from the past
// basic
// "https://api.mapbox.com/styles/v1/abacus-consulting/cjxem7nyt09cj1cmkiz7v371c/tiles/256/{z}/{x}/{y}?access_token=",
// pk.eyJ1IjoiYWJhY3VzLWNvbnN1bHRpbmciLCJhIjoiY2p4ZW00Ync1MGN4ejN6czAybGF3cXowYiJ9.Gy0d0IYnSxyJCjuPp8GRUw

// Light
// "https://api.mapbox.com/styles/v1/adegracia/cjacly1ud4fg52rpkca3b85hj/tiles/256/{z}/{x}/{y}?access_token=",
// "pk.eyJ1IjoiYWRlZ3JhY2lhIiwiYSI6ImNpdXBybDMzMTAxajAyeXA0ZnE0cTN3dGkifQ.Sq6NjjMcGmc5JzPAC7Tnzg",

// Dark
// https://api.mapbox.com/styles/v1/adegracia/ckc5y66vk0rqq1io1mjiiyq0i.html?fresh=true&title=view&access_token=
// pk.eyJ1IjoiYWRlZ3JhY2lhIiwiYSI6ImNpdXBybDMzMTAxajAyeXA0ZnE0cTN3dGkifQ.Sq6NjjMcGmc5JzPAC7Tnzg



