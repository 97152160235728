import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from '@compass/material'
import { CrudIconsComponent } from './crud-icons/crud-icons.component';
import { CancelButtonComponent } from './cancel-button/cancel-button.component';
import { RouterModule } from '@angular/router';

@NgModule({
  imports: [CommonModule, MaterialModule, RouterModule],
  declarations: [CrudIconsComponent, CancelButtonComponent],
  exports: [CrudIconsComponent, CancelButtonComponent]
})
export class SharedCrudElementsModule { }
