import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InmueblesService } from './inmuebles.service';
import { GeoCoordsService } from '@compass/geo-coords/data-access';

@NgModule({
  imports: [CommonModule],
  providers: [InmueblesService, GeoCoordsService],
})
export class InmueblesDataAccessModule {}
