import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UsersListComponent } from './list/users-list.component';
import { SharedFeatureUserManagementModule, UserDetailComponent } from '@compass/shared/feature-user-management';
import { SharedModule } from '../shared/shared.module';
import { UsersRoutingModule } from './users-routing.module';
import { UsersFormComponent } from './form/users-form.component';


@NgModule({
  declarations: [
    UsersListComponent,
    UsersFormComponent,
  ],
  imports: [
    CommonModule,
    UsersRoutingModule,
    SharedModule,
    SharedFeatureUserManagementModule
  ]
})
export class UsersModule {
}
