import { Component, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import { PoiProperties, PoiBusiness } from '../business-poi.type';
import { BusinessReshapeService } from '../business.reshape.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';


@Component({
  selector: 'compass-business-table',
  templateUrl: './business-table.component.html',
  styleUrls: ['./business-table.component.scss']
})


export class BusinessTableComponent implements OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  displayedColumns: string[] = ['denominacion', 'descripcion_cnae', 'rating_morosidad', 'sigla'];
  dataSource;

  constructor(private businessReshapeService: BusinessReshapeService) {

    this.businessReshapeService.getPoisList().subscribe((geo: PoiBusiness[]) => {
      this.dataSource = new MatTableDataSource(geo.map(g => g.properties));
    })

  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }
  ngOnInit(): void { }
}
