import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Router } from '@angular/router';
import { CryptService } from './crypt.service';

export interface Authenticate {
  username: string;
  password: string;
}

export enum Role {
  Franchised = 'franchised',
  User = 'user',
  Admin = 'admin',
}

export class User {
  username: string;
  email?: string;
  role?: Role;
  password?: string;
  access_token?: string;
  menu?: UserMenu[];
}

export interface UserMenu {
  brand_id: string;
  region_id: string;
  store_id: string;
}

@Injectable({ providedIn: 'root' })
export class AuthService {
  private userLoginUrl = '/api/auth/login';
  public currentUser$ = new BehaviorSubject<User>(undefined);
  public userError$ = new BehaviorSubject<String>(undefined);
  public dialogRef: any;

  constructor(
    private router: Router,
    private http: HttpClient,
    private cryptService: CryptService
  ) {
    this.currentUser$.next(JSON.parse(localStorage.getItem('compassUser')));
  }

  getCurrentUser() {
    return this.currentUser$.asObservable();
  }

  setCurrentUser(user: User) {
    // login successful if there's a user
    if (user) {
      // store user details in local storage to keep user logged in between page refreshes
      localStorage.setItem('compassUser', JSON.stringify(user));
      this.currentUser$.next(user);
    }
  }

  validateUser(username: string, password: string): Observable<User> {
    return this.http
      .post<any>(this.userLoginUrl, {
        username,
        password,
      })
      .pipe(
        map((user: User) => {
          return user;
        })
      );
  }

  encryptedLogin(username: string, password: string) {
    const encryptedPass = this.cryptService.encryptPassword(password);
    this.login(username, encryptedPass);
  }

  login(username: string, password: string) {
    this.validateUser(username, password).subscribe((user) => {
      this.setCurrentUser(user);
      this.router.navigate(['/']);
    });
  }

  logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('compassUser');
    this.currentUser$.next(undefined);
    this.router.navigate(['/login']);
  }

  getNativeWindow() {
    return window;
  }

  openNewTab(url: string) {
    this.getNativeWindow().open(url, '_self');
  }
}
