import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StartLeftSidenavBaseComponent } from './start-left-sidenav-base/start-left-sidenav-base.component';
import { StartLeftButtonOpenComponent } from './start-left-button-open/start-left-button-open.component';
import { StartLeftButtonCloseComponent } from './start-left-button-close/start-left-button-close.component';
import { StartLeftSidenavService } from './start-left-sidenav.service'
import { MaterialModule } from '@compass/material'
@NgModule({
  imports: [CommonModule, MaterialModule],
  exports: [StartLeftSidenavBaseComponent, StartLeftButtonOpenComponent, StartLeftButtonCloseComponent],
  declarations: [StartLeftSidenavBaseComponent, StartLeftButtonOpenComponent, StartLeftButtonCloseComponent],
  providers: [StartLeftSidenavService]
})
export class StartLeftSidenavModule { }
