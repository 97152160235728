<div class="container">
  <mat-slide-toggle [checked]="showOnMap" (change)="this.toggleShowPois($event)">
    {{ showOnMap ? 'Ocultar puntos' : 'Mostrar puntos' }}
  </mat-slide-toggle>

  <form [formGroup]="form">
    <p class="filter-title">Filtrar por sexo</p>
    <mat-button-toggle-group formControlName="gender">
      <mat-button-toggle [value]="null">Todos</mat-button-toggle>
      <mat-button-toggle value="M">Mujer</mat-button-toggle>
      <mat-button-toggle value="H">Hombre</mat-button-toggle>
    </mat-button-toggle-group>

    <p class="filter-title">Categorias</p>
    <compass-form-autocomplete
      objectName="categorías"
      [options]="categories"
      (onOptionSelected)="onCategoriesChange($event)"
    ></compass-form-autocomplete>


    <p class="filter-title">Rango de edad</p>
    <compass-row-chart
      inputChartId="filas-edad"
      [corssfilterDimensionFunc]="this.ratingCrossfilterFunc"
    ></compass-row-chart>

    <!--<compass-bar-chart
      chartId="filas-edad"
      [corssfilterDimensionFunc]="this.clientTypeFilterFunc"
    ></compass-bar-chart>
-->

  </form>
</div>
