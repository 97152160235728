import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

export interface DialogData {
  title?: string;
  content?: string;
  cancelText?: string;
  confirmText?: string;
}


@Component({
  selector: 'compass-compass-mat-dialog',
  templateUrl: './compass-mat-dialog.component.html',
  styleUrls: ['./compass-mat-dialog.component.scss']
})
export class CompassMatDialogComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<CompassMatDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {
  }

  ngOnInit(): void {
  }

  actionClick(action: any): void {
    this.dialogRef.close(action);
  }
}
