import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StartTightMenuComponent } from './start-tight-menu/start-tight-menu.component';
import { StartTightSidenavComponent } from './start-tight-sidenav/start-tight-sidenav.component';
import { StartTightSidenavService } from './start-tight-sidenav/start-tight-sidenav.service';
import { StartTightStage } from './start-tight-menu/start-tight-stage.type';
@NgModule({
  declarations: [StartTightMenuComponent, StartTightSidenavComponent],
  imports: [CommonModule],
  exports: [StartTightMenuComponent, StartTightSidenavComponent],
  providers: [StartTightSidenavService]
})
export class StartTightMenuModule { }


