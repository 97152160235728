import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TableComponent } from './table/table.component';
import { RouterModule } from '@angular/router';
import { MaterialModule } from '@compass/material';

@NgModule({
  imports: [CommonModule, RouterModule, MaterialModule],
  declarations: [TableComponent],
  exports: [TableComponent],
})
export class UiManagementTableModule {}
