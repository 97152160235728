import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import '@angular/common/locales/global/es';
import { HttpClientModule } from '@angular/common/http';
import { MaterialModule } from '@compass/material';
import { SharedFeatureAuthModule } from '@compass/shared/feature-auth';
import { MapModule, MapService } from '@compass/map';
import { MainMenuComponent } from './page-navigation/components/main-menu/main-menu.component';
import { FormBuilder } from '@angular/forms';
import { environment } from '../environments/environment';
import { AuthModule } from '@compass/auth';
import { BusinessModule } from './business/business.module';
import { LineTransitDataAccessModule, LineTransitService } from '@compass/line-transit/data-access';
import { UsersModule } from './users/users.module';
import { PoiService } from '@compass/pois/data-access-poi';
import { StudyService } from '@compass/studies/data-access-study';
import { StudiesListComponent } from './studies/studies-list/studies-list.component';
import { SharedMatDialogModule } from '@compass/shared/mat-dialog';


@NgModule({
  declarations: [
    AppComponent,
    MainMenuComponent,
    StudiesListComponent
  ],
  entryComponents: [
    MainMenuComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    MaterialModule,
    SharedFeatureAuthModule,
    MapModule,
    AuthModule,
    BusinessModule,
    LineTransitDataAccessModule,
    UsersModule,
    SharedMatDialogModule
  ],
  providers: [
    StudyService,
    MapService,
    PoiService,
    LineTransitService,
    FormBuilder,
    {
      provide: 'environment',
      useValue: environment
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
