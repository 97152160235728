import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';

export interface EgmDb {
  key: string;
  class_: string;
  geo_level: string;
  id: string;
  estudio: string;
  tipologia: string;
  medio: string;
  geo_level_id: string;
  geo: string;
  target: string;
  valor: number;
  source: string;
}

@Injectable()
export class EgmAimcDataService {
  private egmUrl = '/abacusgis/core/glocally/collection/data_egm/';
  public egmCountry$ = new BehaviorSubject(undefined);
  public egmProvince$ = new BehaviorSubject(undefined);
  private prevProvince: string;

  constructor(private http: HttpClient) {}

  getEgmCountry() {
    return this.http
      .get(`${this.egmUrl}?query={"key":"data_egm_area_country_ES"}`)
      .pipe(map((egm: EgmDb[]) => egm))
      .subscribe((egm: EgmDb[]) => {
        this.egmCountry$.next(egm);
      });
  }

  getEgmProvince(province: string) {
    if (this.prevProvince !== province) {
      this.egmProvince$.next(undefined);
      return this.http
        .get(
          `${this.egmUrl}?query={"key":"data_egm_area_province_${province}"}`
        )
        .pipe(map((egm: EgmDb[]) => egm))
        .subscribe((egm: EgmDb[]) => {
          this.egmProvince$.next(egm);
          this.prevProvince = province;
        });
    }
  }
}
