import { Component, Input } from '@angular/core';
import { User, UserService } from './user.service';

@Component({
  selector: 'compass-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss'],
})
export class UsersComponent {
  @Input('createUrl') createUrl: string = '/usuarios/agregar/'; // default old value
  @Input('editUrl') editUrl: string;

  users$ = this.userService.users$;

  displayedColumns = [
    {
      key: 'username',
      display: 'Nombre',
      crud: true,
    },
    {
      key: 'email',
      display: 'Email',
    },
    {
      key: 'role',
      display: 'Perfil',
      role: true,
    },
  ];

  constructor(private userService: UserService) {
    this.userService.getAll();

    console.log('CREATE URL', this.createUrl);
  }

  changeFromDialog(user: User): void {
    this.userService.deleteUser(user.username);
  }
}
