import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';
import { Router } from '@angular/router';
import { Indicator } from '@compass/utils/navigation';

@Injectable()
export class IndicatorService {
  public activeIndicator$: BehaviorSubject<Indicator> = new BehaviorSubject<Indicator>(undefined);
  public analyticsIndicators$ = new BehaviorSubject<any>(undefined);

  constructor(private http: HttpClient, private router: Router) { }

  getActiveIndicator() {
    return this.activeIndicator$.asObservable();
  }

  setActiveIndicator(indicator: Indicator): BehaviorSubject<any> {
    if (indicator) {
      this.activeIndicator$.next(indicator);
    }

    return this.activeIndicator$;
  }

  setAnalyticsIndicators(indicators: Indicator[], dic: any) : void{
    const fullIndicators = indicators.map((i) => {
      let found = dic.find((d) => d.key === i.key);
      i.screen_name = found?.screen_name;
      i.grupo = found?.grupo;
      i.formato = found?.formato;

      return i;
    });

    this.analyticsIndicators$.next(indicators);
  }

  removeFromAnalyticsIndicators(indicator: Indicator): void {
    const index = this.analyticsIndicators$.value.indexOf(indicator);

    if (index >= 0) {
      this.analyticsIndicators$.value.splice(index, 1);
    }
  }
}
