import { Inject, Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthService } from '@compass/shared/feature-auth';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  private environment: any;
  private servicesUser: string;
  private servicesPass: string;

  constructor(
    @Inject('environment') environment,
    private authService: AuthService
  ) {
    this.environment = environment;
  }


  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    // add auth header with jwt if user is logged in and request is to api url
    const currentUser = this.authService.currentUser$.value;
    const isLoggedIn = currentUser && currentUser.access_token;
    const isApiUrl = request.url.startsWith('/api');
    const isGoogle = request.url.includes('google');
    const isFileUpload = request.url.includes('upload');

    if (isLoggedIn && isApiUrl) {
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${currentUser.access_token}`
        }
      });
    } else if (isLoggedIn && !isGoogle) {
      this.loadServiceLogin();

      // remove this when upload security is ready
      // file upload security
      if (!isFileUpload) {
        const token = btoa(`${this.servicesUser}:${this.servicesPass}`);
        request = request.clone({
          setHeaders: {
            Authorization: `Basic ${token}`
          }
        });
      }
    }

    //reset the error observable
    this.authService.userError$.next(undefined);

    return next.handle(request);
  }


  /**
   * Load service login data. If data not setted in environment, it will throw an exception
   *
   * @private
   */
  private loadServiceLogin() {
    // check if environment is injected
    if(!this.environment) {
      console.error('Config data not setted');
    }

    // check if user is setted
    if(!this.environment.servicesUser || !this.environment.servicesPass) {
      console.error('Missing service configuration, please check the app configuration.');
    }

    this.servicesUser = this.environment.servicesUser;
    this.servicesPass = this.environment.servicesPass;
  }

}
