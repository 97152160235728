import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';
import { Router } from '@angular/router';
import { CategoryService } from '@compass/categories/data-access-category';
import { HttpErrorResponse, HttpEventType } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { of } from 'rxjs';
import { slugify } from '@compass/utils/slugify';
import { GeoCoordsService } from '@compass/geo-coords/data-access';
import { MarkerOptionsApiService } from '../../../../utils/navigation/src/lib/marker-options-api.service';
import { Observable } from 'rxjs/Observable';
import { PoiDb } from '@compass/pois/data-access-poi';


export interface Patrimonio {
  type: string;
  properties: PatrimonioProperty;
  geometry: PatrimonioGeometry;
}

export interface PatrimonioProperty {
  id: string;
  class_: string;
  categoria: string;
  sub_categoria: string;
  key_sub_categoria: string;
  proveedor?: string;
  soporte: string;
  cliente: string;
  nombre: string;
  medio: string;
  direccion: string;
  comentarios: string;
  cp: string;
  cumun: string;
  nmun: string;
  cprov: string;
  nprov: string;
  cccaa: string;
  nccaa: string;
  descripcion: string;
  imagen: string;
  valoracion: string;
  puntuacion: string;
  impactos: string;
  individuos: string;
  status?: string;
  markerOptions: null | any
}

export interface PatrimonioGeometry {
  type: string;
  coordinates: number[];
}

export interface PatrimonioForm extends PatrimonioProperty {
  x: number;
  y: number;
}

class patrimonio implements Patrimonio {
  type = 'Feature';
  properties: PatrimonioProperty;
  geometry = {
    type: 'Point',
    coordinates: [0, 0]
  };

  constructor(
    form: PatrimonioForm,
    subCategoria: string,
    id?: string
  ) {
    this.properties = {
      id: id ?? undefined,
      class_: 'point_patrimonio',
      categoria: 'Patrimonio',
      sub_categoria: subCategoria,
      key_sub_categoria: form.key_sub_categoria,
      proveedor: form.proveedor,
      soporte: form.soporte,
      cliente: form.cliente ? form.cliente : '',
      direccion: form.direccion,
      comentarios: form.comentarios,
      nombre: form.nombre,
      medio: form.medio,
      cp: form.cp,
      cumun: form.cumun,
      nmun: form.nmun,
      cprov: form.cprov,
      nprov: form.nprov,
      cccaa: form.cccaa,
      nccaa: form.nccaa,
      descripcion: form.descripcion,
      imagen: form.imagen,
      valoracion: form.valoracion,
      puntuacion: form.puntuacion,
      impactos: form.impactos,
      individuos: form.individuos,
      markerOptions: null
    };
    this.geometry.coordinates = [Number(form.x), Number(form.y)];
  }
}

@Injectable({ providedIn: 'root' })
export class PatrimonioService {

  private patrimonioDevUrl = '/assets/data/heritage/heritage.json';
  private patrimoniosUrl = '/abacusgis/core/glocally/collection/point_patrimonio/';
  public patrimonios$ = new BehaviorSubject<PatrimonioProperty[]>(undefined);
  public patrimonio$ = new BehaviorSubject<Patrimonio>(undefined);
  // Global list
  public heritagePois$: BehaviorSubject<any> = new BehaviorSubject<any>(undefined);

  categories$ = this.categoryService.categories$;
  private fileUploadUrl: string =
    '/abacusvalidator/file_val/glocally/collection/point_patrimonio/upload/';

  constructor(
    private http: HttpClient,
    private router: Router,
    private categoryService: CategoryService,
    private geoCoordsService: GeoCoordsService,
    private markerOptionsApiService: MarkerOptionsApiService
  ) {
  }


  // PRO API
  handleError(error: HttpErrorResponse) {
    const kk = null;
    return of(kk);
  }

  public getHeritagePois() {
    return this.heritagePois$.asObservable();
  }

  public setHeritagePois(pois) {
    this.heritagePois$.next(pois);
  }


  getAll() {
    this.patrimonios$.next(undefined);
    return this.http
      .get(`${this.patrimoniosUrl}`)
      .pipe(
        map((patrimonios: Patrimonio[]) => patrimonios.map((p) => p.properties))
      )
      .subscribe((mappedPatrimonios: PatrimonioProperty[]) => {
        this.patrimonios$.next(mappedPatrimonios);
      });
  }

  getById(id: string) {
    return this.http
      .get(`${this.patrimoniosUrl}?query={"properties.id" : "${id}"}`)
      .pipe(map((patrimonios: Patrimonio[]) => patrimonios?.shift()))
      .subscribe((patrimonio: Patrimonio) => {
        this.patrimonio$.next(patrimonio);
      });
  }

  /**
   * Returns the POIs which uses the passed keySubCategory.
   *
   * @param keySubCategory
   * @param excludeDeleted
   */
  getBySubCategoryKey(keySubCategory: string, excludeDeleted: boolean = true) {
    let query = `"properties.key_sub_categoria":"${keySubCategory}"`;

    if (excludeDeleted) {
      query += `,"properties.remove":{"$exists": false}`;
    }

    return this.http
      .get(`${this.patrimoniosUrl}?query={${query}}`)
      .pipe(map((pois: PoiDb[]) => pois));
  }

  getUsedCategories(): Observable<any> {
    return this.http
      .get(`${this.patrimoniosUrl}?distinct=properties.key_sub_categoria`);
  }

  addPatrimonio(form: PatrimonioForm) {
    const subCategoria = this.categories$.value.find(
      (c) => c.key_sub_categoria === form.key_sub_categoria
    )?.sub_categoria;
    const newPatrimonio = new patrimonio(form, subCategoria);
    return this.http
      .post<Patrimonio>(`${this.patrimoniosUrl}`, newPatrimonio)
      .subscribe((d: any) => {
        this.router.navigate(['/patrimonios']);
      });
  }

  updatePatrimonio(id: string, form: any) {
    const subCategoria = this.categories$.value.find(
      (c) => c.key_sub_categoria === form.key_sub_categoria
    )?.sub_categoria;
    const updatedPatrimonio = new patrimonio(form, subCategoria, id);
    return this.http
      .put<Patrimonio>(`${this.patrimoniosUrl}`, updatedPatrimonio)
      .subscribe((d: any) => {
        this.router.navigate(['/patrimonios']);
      });
  }

  deletePatrimonio(id: string) {
    return this.http
      .delete<Patrimonio>(`${this.patrimoniosUrl}${id}`)
      .subscribe((d: any) => {
        this.getAll();
      });
  }

  clearPatrimonio() {
    this.patrimonio$.next(undefined);
  }

  public upload(formData, fileName) {
    return this.http
      .post<any>(this.fileUploadUrl, formData, {
        reportProgress: true,
        observe: 'events'
      })
      .pipe(
        map((event) => {
          switch (event.type) {
            case HttpEventType.Response:
              this.getAll();
              return event;
          }
        }),
        catchError((error: HttpErrorResponse) => {
          return of(error);
        })
      );
  }

  normalizeNodes(nodes) {
    return nodes.map((node) => {
      node['properties']['id'] = node['properties']['id']
        ? node['properties']['id']
        : '';
      node['properties']['categoria'] = node['properties']['categoria']
        ? node['properties']['categoria']
        : '';

      node['properties']['key_sub_categoria'] = node['properties']['key_sub_categoria']
        ? node['properties']['key_sub_categoria']
        : '';
      node['properties']['sub_categoria'] = node['properties']['sub_categoria']
        ? node['properties']['sub_categoria']
        : '';
      node['properties']['medio'] = node['properties']['medio']
        ? node['properties']['medio']
        : '';
      node['properties']['nombre'] = node['properties']['nombre']
        ? node['properties']['nombre']
        : '';
      node['properties']['soporte'] = node['properties']['soporte']
        ? node['properties']['soporte']
        : '';

      node['properties']['proveedor'] = node['properties']['proveedor']
        ? node['properties']['proveedor']
        : '';

      node['properties']['cliente'] = node['properties']['cliente']
        ? node['properties']['cliente']
        : '';
      node['properties']['status'] = node['properties']['cliente'] && node['properties']['cliente'] !== ''
        ? 'Comprado'
        : 'Libre';
      node['properties']['icono'] = node['properties']['icono']
        ? node['properties']['icono']
        : node['properties']['imagen']
          ? slugify(node['properties']['imagen'])
          : 'default';

      node['markerOptions'] = this.markerOptionsApiService.getMarkerOptions(node);


      return node;
    });
  }

  // DEV API fn

  public getHeritageData = () => {
    return this.http.get(`${this.patrimonioDevUrl}`).pipe(
      catchError(this.handleError),
      map((data: any) => {
        // map data here if needed
        let normalizeNodes = this.normalizeNodes(data);
        this.heritagePois$.next(normalizeNodes);
        return normalizeNodes;
      })
    );
  };


  // to see --> saved_patrimonio
  // pro api
  getByAreaFrom(study: any) {
    if (study.properties.saved_patrimonio) {
      return this.heritagePois$.next(study.properties.saved_patrimonio);
    }
    if (!this.heritagePois$.value) {
      return this.http
        .get(`${this.patrimoniosUrl}?${this.geoCoordsService.getFrom(study)}`)
        .subscribe((heritages: any[]) => {
          let normalizeNodes = this.normalizeNodes(heritages);
          this.heritagePois$.next(normalizeNodes);
        });
    }
  }
}
