import { Component, Input, OnInit } from '@angular/core';
export interface ScaleSetup {
  percentileScale: Array<any>,
  display: string,
  max: number,
  min: number
}
@Component({
  selector: 'compass-map-color-scale',
  templateUrl: './map-color-scale.component.html',
  styleUrls: ['./map-color-scale.component.scss']
})

export class MapColorScaleComponent implements OnInit {
  @Input() scaleSetup: ScaleSetup

  percentileScale: Array<any>;
  display: string;
  max: number;
  min: number;

  constructor(

  ) { }

  ngOnInit(): void {
    this.percentileScale = this.scaleSetup.percentileScale;
    this.display = this.scaleSetup.display;
    this.max = this.scaleSetup.max;
    this.min = this.scaleSetup.min;
  }

}
