import { Component, OnInit, Input } from '@angular/core';
import { StartTightSidenavService } from '@compass/page-navigation/start-tight-menu';
import { BusinessViewerService } from '../business-viewer.service';
import { StartTightStage } from '@compass/page-navigation/start-tight-menu';

@Component({
  selector: 'compass-business-menu',
  templateUrl: './business-menu.component.html',
  styleUrls: ['./business-menu.component.scss']
})

export class BusinessMenuComponent implements OnInit {
  @Input() setup: StartTightStage[];


  constructor(
    private businessViewerService: BusinessViewerService,
    private StartTightSidenavService: StartTightSidenavService
  ) {
  }

  openLateralSidenav(e) {
    this.StartTightSidenavService.setIsOpen(true);
    return false;
  }

  goTo(event, stage) {
    event.stopPropagation();
    event.preventDefault();
    this.businessViewerService.setActiveStage$(stage);

    switch (stage.key) {
      case 'filter': {
        this.openLateralSidenav(event);

        break;
      }
      case 'map': {
        this.businessViewerService.setMapIsOn(true);

        break;
      }
      case 'table': {
        this.businessViewerService.setMapIsOn(false);
        break;
      }
      case 'indicators': {
        this.businessViewerService.setMapIsOn(true);
        this.openLateralSidenav(event);

        break;
      }
      default: {
        return false;
      }
    }
    return false;
  }

  ngOnInit(): void {
  }

}


