import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CompassMatDialogComponent } from './compass-mat-dialog/compass-mat-dialog.component';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { FlexModule } from '@angular/flex-layout';

@NgModule({
  imports: [CommonModule, MatDialogModule, MatButtonModule, FlexModule],
  declarations: [CompassMatDialogComponent]
})
export class SharedMatDialogModule {
}
