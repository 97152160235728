<div class="navigation">
  <ng-container *ngIf="navigation.length">
    <button mat-icon-button title="Volver al nivel superior" (click)="backLevel()">
      <mat-icon>chevron_left</mat-icon>
    </button>

    <div class="element">{{ navigation[navigation.length - 1].key }}</div>
  </ng-container>
</div>


<!--
<mat-checkbox [(ngModel)]="allSelected"
              [indeterminate]="someSelected"
              (change)="toggleAllInList()"
>
  Seleccionar todos
</mat-checkbox>-->

<hr>

<ng-container *ngFor="let item of actualList | keyvalue">
  <div class="option-row">
    <mat-checkbox [(ngModel)]="item.value.selected"
                  [title]="item.key"
                  [checked]="item.value.selected || this.allSelected"
                  (change)="toggleOne(item.value)"
    >
      {{ item.key }}
    </mat-checkbox>
    <button mat-button (click)="nextLevel(item)"
            *ngIf="item.value.children && item.value.children != {}"
            [title]="'Ver elementos del grupo ' + item.key"
    >
      <mat-icon>read_more</mat-icon>
    </button>
  </div>

</ng-container>

