import { Component } from '@angular/core';
import { Input } from '@angular/core';
import { OnChanges } from '@angular/core';
import { SimpleChange } from '@angular/core';
import { ChangeDetectionStrategy } from '@angular/core';
import { ViewEncapsulation } from '@angular/core';
import { DimensionsService } from '../dimensiones.service';
import { DefaultVarsService } from '../default-vars.service';
import { PieChartReshapeService } from '../pie-chart/pie-chart-reshape.service';
import { ColorsService } from '@compass/utils/misc';

import * as d3 from 'd3';

@Component({
  selector: 'compass-pie-chart',
  templateUrl: './pie-chart.component.html',
  styleUrls: ['./pie-chart.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PieChartComponent implements OnChanges {
  @Input() pieChart: any;
  @Input() clusterSelected;
  @Input() segmentSelected;

  key: string;
  class: string;
  chartId: string;

  // data
  dataChart: Array<any>;
  count: string;
  countVal: string;

  // services
  getDimensions: any;
  resize_delay: number;
  timeout: any = false;
  reshape: any;

  // containers
  hostElement; // Native element hosting the SVG container
  win: any = window; // in use
  vBody: any;
  container: any;
  svg: any;
  chartLegend: any;
  chartWrapper: any;
  chartNumber: any;
  slices: any;
  tooltipEl: any;

  // pie chart
  pie: any;
  arc: any;
  pieData: any;
  pieDataPrevious: any; // Arc segment parameters for previous data set - used for transitions
  // params
  params: any;
  dataBase: any;
  tooltip: any;
  style: any;
  margin: any;
  innerRadius: number;
  width: number;
  height: number;
  radius: number;
  dictionary: Array<any>;
  viewChartLegend: boolean;

  // scales
  colors: any;
  colorScale: any;

  prevTarget: string;

  constructor(
    private colorsService: ColorsService,
    private defaultVarsService: DefaultVarsService,
    private pieChartReshapeService: PieChartReshapeService,
    private dimensionsService: DimensionsService
  ) {}

  private setColorScale() {
    this.colorScale = d3.scaleOrdinal().range(this.colors);
    // Below is an example of using custom colors
    // this.colorScale = d3.scaleOrdinal().domain([0,1,2,3]).range(['#1f77b4', '#ff7f0e', '#2ca02c', '#d62728']);
  }

  private arcTween = (datum, index) => {
    const interpolation = d3.interpolate(this.pieDataPrevious[index], datum);
    this.pieDataPrevious[index] = interpolation(0);
    return (t) => {
      return this.arc(interpolation(t));
    };
  };

  private destroyTooltip() {
    this.tooltipEl = null;
    this.vBody
      .select('#' + this.tooltip.id)
      .html('')
      .remove();
  }

  private destroyChart = (): void => {
    d3.select('#' + this.chartId).html('');
    // Stop resize events
    d3.select(this.win).on('resize', null);
  };

  // reshape the data
  reshapedata(initial = true) {
    this.dataChart = this.reshape(this.pieChart.data, [
      this.clusterSelected,
      this.segmentSelected,
    ]);

    this.pieData = this.pie(this.dataChart);
    if (initial) {
      this.pieDataPrevious = this.pieData;
    }

    // render everything!
    this.render();
  }

  // draw SVGs
  public render(): void {
    let dimensions: any = this.getDimensions(
      this.svg,
      '#' + this.chartId,
      this.margin
    );
    this.width = dimensions.width;
    this.height = dimensions.height;
    this.radius = Math.min(this.width, this.height) / 2;

    this.svg
      .attr('width', this.width + this.margin.right + this.margin.left)
      .attr('height', this.height + this.margin.top + this.margin.bottom)
      .attr(
        'viewBox',
        '0 0 ' +
          (this.width + this.margin.left + this.margin.right) +
          ' ' +
          (this.height + this.margin.top + this.margin.bottom)
      )
      .attr('preserveAspectRatio', 'xMaxYMax meet');

    this.chartWrapper.attr(
      'transform',
      'translate(' + this.width / 2 + ',' + (this.height + 16) / 2 + ')'
    );
    this.chartNumber.attr(
      'transform',
      'translate(' + this.width / 2 + ',' + (this.height + 16) / 2 + ')'
    );

    this.arc = d3
      .arc()
      .innerRadius(this.radius / this.innerRadius)
      .outerRadius(this.radius);

    this.slices = this.chartWrapper.selectAll('path').data(this.pieData);

    this.slices.transition().duration(750).attrTween('d', this.arcTween);

    // Enter new arcs
    this.slices
      .enter()
      .append('svg:path')
      .attr('fill', (d, i) => this.colorScale(i))
      .attr('d', this.arc)
      .attr('stroke', 'white')
      .attr('stroke-width', '2px')
      .style('opacity', 1)
      .each((d, i, arr) => {
        d3.select(arr[i])._current = d;
      }); // store the initial angles

    this.destroyTooltip();
    this.drawChartNumber();
  }
  drawChartNumber() {
    let number = this.chartNumber
      .selectAll('text.number')
      .data(this.dataChart.filter((d) => d.medio === this.segmentSelected));

    number
      .exit()
      .attr('class', 'exit')
      .transition()
      .duration(this.resize_delay / 2)
      .remove();

    number
      .enter()
      .append('svg:text')
      .attr('class', 'number')
      .attr('transform', (d) => {
        return 'translate(' + 0 + ', 0)';
      })
      .attr('x', 12)
      .attr('y', 12)
      .attr('dy', '0em')
      .attr('dx', '-2.4em')
      .attr('font-size', '18px')
      .attr('fill', 'black')
      .text((d, i) => {
        return d[this.count] + '%';
      });
    number = this.chartNumber.selectAll('text.number');
    number
      .transition()
      .duration(this.resize_delay)
      .attr('transform', (d) => {
        return 'translate(' + 0 + ',0)';
      })
      .text((d, i) => {
        return d[this.count] + '%';
      });
  }

  // init all svg + data
  public runAll = (): void => {
    ////////// initialize scales //////////
    this.setColorScale();
    // pie
    this.pie = d3
      .pie()
      .value((d) => +d[this.count])
      .sort(null);

    // ELEMENTS
    this.container = d3.select('#' + this.chartId); // placeholder div for svg

    this.svg = this.container
      .selectAll('svg')
      .data([{}])
      .enter()
      .append('svg:svg');

    this.chartLegend = this.svg
      .selectAll('g.chartLegend')
      .data([{}])
      .enter()
      .append('svg:g')
      .attr('class', 'chartLegend')
      .attr('transform', 'translate(' + this.margin.left + ',' + 0 + ')');

    this.chartWrapper = this.svg
      .selectAll('g.chartWrapper')
      .data([{}])
      .enter()
      .append('svg:g')
      .attr('class', 'chartWrapper');

    this.chartNumber = this.svg
      .selectAll('g.chartNumber')
      .data([{}])
      .enter()
      .append('svg:g')
      .attr('class', 'chartNumber');

    this.win.addEventListener('resize', () => {
      clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        this.render();
      }, this.resize_delay);
    });

    this.reshapedata();
  };

  public init(): void {
    this.destroyTooltip();
    this.destroyChart();
    this.runAll();
  }
  ngAfterViewInit(): void {
    this.pieChart['change'] = 'afterViewInit';
    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      this.init();
    }, this.resize_delay);

    //window.dispatchEvent(new Event('resize'));
  }

  ngOnChanges() {
    this.height = this.pieChart.design.height;
    this.innerRadius = this.pieChart.design.innerRadius;
    this.key = this.pieChart.key;
    this.chartId = this.key;
    this.class = this.pieChart.class;
    this.count = this.pieChart.properties.key;
    this.countVal = this.pieChart.properties.value;
    this.colors = this.pieChart.design.style.colors;
    this.resize_delay = this.defaultVarsService.resize_delay;
    this.margin = this.pieChart.design.margin;
    this.style = this.pieChart.design.style;
    this.dictionary = this.pieChart.dictionary;
    this.vBody = d3.select('body');
    this.tooltip = this.pieChart.tooltip;
    this.viewChartLegend = this.pieChart.viewChartLegend;

    this.reshape = this.pieChartReshapeService.reshape;
    this.getDimensions = this.dimensionsService.getDimensions;

    // not init, selector Change
    if (this.pie) {
      this.pieChart['change'] = 'selector';
      clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        this.reshapedata(false);
      }, this.resize_delay / 4);
      this.prevTarget = this.clusterSelected;
    }
    return '';
  }
}
