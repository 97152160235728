import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class GeoCoordsService {
  constructor() {}

  getFrom(study: any) {
    const areaValue = Object.entries(study.properties.areas)[0][1] as any;
    // si es estudio creado por codigo postal, aplanar un nivel más el array
    if (areaValue.geometry.type == 'MultiPolygon') {
      const bricksAreas = Object.entries(
        study.properties.bricks_areas
      )[0][1] as any;
      const CPs = new Set(
        bricksAreas.map((i) => i.properties.area_zip_code_id)
      );
      const query = `area_collection={"area_zip_code":${JSON.stringify([
        ...CPs,
      ])}}`;
      return query;
    }
    const flatCoordinates = areaValue.geometry.coordinates.flat(1);
    return `area=${JSON.stringify(flatCoordinates)}`;
  }
}
