<section>
    <div class="container ul__list">
        <mat-selection-list #indicators [multiple]="false">
            <mat-list-option *ngFor="let indicator of indicatorGroupSelected.indicator.values" [value]="indicator">
                <p (click)="onClickIndicator($event, indicator)"><small>{{indicator.screen_name}}</small></p>
            </mat-list-option>
        </mat-selection-list>

        <!-- <p><small> Option selected: {{indicators.selectedOptions.selected[0]?.value}}</small></p> -->

    </div>
</section>