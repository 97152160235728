import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CrossfilterComponent } from './crossfilter/crossfilter.component';
import { BarChartComponent } from './bar-chart/bar-chart.component';
import { RowChartComponent } from './row-chart/row-chart.component';
import { FlexModule } from '@angular/flex-layout';
import { MatIconModule } from '@angular/material/icon';


@NgModule({
  imports: [CommonModule, FlexModule, MatIconModule],
  declarations: [
    CrossfilterComponent,
    BarChartComponent,
    RowChartComponent
  ],
  exports: [
    CrossfilterComponent,
    BarChartComponent,
    RowChartComponent
  ]
})
export class CrossfilterModule {
}
