import { Component, OnInit } from '@angular/core';
import { NavbarContentComponent } from '../../../../../../../libs/page-navigation/sidenav/src/lib/classes/navbar-content-component';

@Component({
  selector: 'lab-main-menu',
  templateUrl: './main-menu.component.html',
  styleUrls: ['./main-menu.component.scss']
})
export class MainMenuComponent extends NavbarContentComponent implements OnInit {

  constructor() {
    super();
  }

  ngOnInit(): void {
  }

}
