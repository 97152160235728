<mat-progress-bar mode="indeterminate" *ngIf="loading"></mat-progress-bar>

<div *ngIf="!loading && !data.length" class="text-center">
  No se han encontrado datos de negocios en este área.
  <button class="w-100" mat-button (click)="sidenavService.backInHistory()">volver</button>
</div>

<div class="container" *ngIf="data.length">
  <form [formGroup]="form">
    <!-- CLIENTS -->
    <p class="filter-title">Filtrar por clientes</p>
    <mat-button-toggle-group formControlName="client">
      <mat-button-toggle [value]="null">Todos</mat-button-toggle>
      <mat-button-toggle [value]="true">Clientes</mat-button-toggle>
      <mat-button-toggle [value]="false">No clientes</mat-button-toggle>
    </mat-button-toggle-group>
    <!--/ CLIENTS -->

    <!-- ACTIVITIES -->
    <p class="filter-title">Actividades</p>
    <compass-form-autocomplete
      objectName="actividades"
      [options]="this.activities"
      (onOptionSelected)="activitySelected($event)"
    ></compass-form-autocomplete>
    <!--/ACTIVITIES -->

    <p class="filter-title">Rangos</p>
    <!-- MOROSIDAD -->
    <compass-bar-chart
      chartId="ratio_morisidad"
      title="Ratio de morosidad"
      class="margin__top--m"
      [corssfilterDimensionFunc]="this.crossfilterRatioMorisidad"
    ></compass-bar-chart>
    <!--/MOROSIDAD -->

    <!-- NUM EMPLEADOS -->
    <compass-bar-chart
      chartId="total_empleados"
      title="Nº Empleados"
      class="margin__top--m"
      [corssfilterDimensionFunc]="this.crossfilterTotalEmpleados"
    ></compass-bar-chart>
    <!--/NUM EMPLEADOS -->

    <!-- VENTAS -->
    <compass-bar-chart
      chartId="ventas"
      title="Ventas"
      valueSuffix="€"
      class="margin__top--m"
      [corssfilterDimensionFunc]="this.crossfilterVentas"
    ></compass-bar-chart>
    <!--/VENTAS -->


    <!-- ANTIGUEDAD -->
    <compass-bar-chart
      chartId="ano_inicio_actividad"
      title="Antiguedad (años)"
      valueSuffix=" años"
      class="margin__top--m"
      [corssfilterDimensionFunc]="this.crossfilterTotalEmpleados"
    ></compass-bar-chart>
    <!--/ANTIGUEDAD -->
  </form>
</div>
