import { Injectable } from "@angular/core";
import { TooltipStyleService } from "../tooltipStyle.service";
import { ColorsService } from "@compass/utils/misc";
import * as d3 from 'd3';

@Injectable()
export class RangeSliderLayoutService {

    colors: any = this.colorsService.colors;
    tooltipValues: any = this.tooltipStyleService.tooltipValues;
    layoutSelected: any;

    constructor(
        private colorsService: ColorsService,
        private tooltipStyleService: TooltipStyleService
    ) { }


    public layouts: any[] = [
        {
            // vertical bar chart
            key: "rangeSlider_morosidad",
            class: "chart-area chart-container",
            section: "business",
            design: {
                height: 100,
                width: 340,
                heightFactor: 24,
                margin: {
                    top: 10,
                    bottom: 0,
                    left: 40,
                    right: 0
                },
                stroke: {
                    width: 0.2,
                    color: this.colors.black,
                },
                style: {
                    colors: [
                        this.colors.purple,
                        this.colors.gray_light,
                        this.colors.sky,
                        this.colors.red
                    ],
                    paddingInner: 0.2,
                    padding: 0.05,
                    align: 0.1,
                    legend: {
                        fontSize: "10px",
                    },
                    averages: {},
                },
            },
            viewChartLegend: null,
            xVal: {
                name: null,
            },
            yVal: [
                { key: null, name: null, value: null },
            ],
            dictionary: [
                {
                    values: null,
                    key: null,
                },
            ],
            tooltip: {
                id: null,
                style: this.tooltipValues,
                header: null,//   key [string, "personas_hogar"], value [number], category[string, "Resto"] or index[string, "tienda_3_Resto"]
                text: null
            },
            timeout: 250,
            resize_delay: 250,
            minY: 0.0001,
            yScale: d3.scaleLinear(),
            yTicks: 4,
            freezeMin: false
        }
    ];

    public getLayout(key: string): any {
        let layout = this.layouts.filter((l) => {
            return l.key === key;
        });

        return layout;
    }
}
