<div class="container">
  <mat-form-field appearance="fill">
    <mat-label>Capa de clusters</mat-label>
    <mat-select required (selectionChange)="drawPois()" [formControl]="selectedLayer">
      <mat-option *ngFor="let option of layerOptions" [value]="option.key"
                  [attr.selected]="option.key === selectedLayer"
      >
        {{ option.value }}
      </mat-option>
    </mat-select>
  </mat-form-field>
</div>

<lab-category-nested-list
  [listData]="pois"
  [levels]="nestedListLevelsName"
  [elementHtml]="nestedListElementHtml"
  (selectedElements)="onPoisSelected($event)"
></lab-category-nested-list>
