import { Injectable } from '@angular/core';
import { MapBoxService } from '../../../misc/src/lib/map-box.service';
import * as L from 'leaflet';

@Injectable({
    providedIn: 'root',
})
export class CompassTopoLayersService {

    // main vars
    zoom: number = 10;
    minZoom: number = 14;
    maxZoom: number = 20;
    maxNativeZoom: number = 19;
    zIndex: number = 0;
    ovcAttribution: string = 'Cartografía Catastral de la Dirección General del Catastro.';


    // topographic IGN
    // https://www.ign.es/web/ign/portal/ide-area-nodo-ide-ign
    // Orthophoto ign
    // http://www.ign.es/resources/noticias/Noticia_20131030.pdf
    // mapa raster
    // http://www.ign.es/wms-inspire/mapa-raster?request=GetCapabilities&service=WMS
    // LiDAR
    // Modelo Digital de Superficies LiDAR generado por composición de cuatro capas: sombreado, edificaciones, vegetación e hidrografía.
    osmUrl = 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png';
    osmAttrib = 'Map data &copy; OpenStreetMap contributors';
    ignMapaRasterUrl = 'https://ign.es/wmts/mapa-raster?service=WMTS&request=GetTile&version=1.0.0&Format=image/jpeg&layer=MTN&style=default&tilematrixset=GoogleMapsCompatible&TileMatrix={z}&TileRow={y}&TileCol={x}';
    ignPnoa = "https://www.ign.es/wms-c/PNOA/PNOA";
    ignBaseUrl = 'https://www.ign.es/wmts/ign-base?service=WMTS&request=GetTile&version=1.0.0&Format=image/jpeg&layer=IGNBaseTodo&style=default&tilematrixset=GoogleMapsCompatible&TileMatrix={z}&TileRow={y}&TileCol={x}';
    ignLidarUrl = 'https://wmts-mapa-lidar.idee.es/lidar?&SERVICE=WMTS&REQUEST=GetTile&VERSION=1.1.1&LAYER=EL.GridCoverageDSM&STYLE=default&FORMAT=image/png&TILEMATRIXSET=GoogleMapsCompatible&TileMatrix={z}&TileRow={y}&TileCol={x}';
    defensaUrl = "https://wms-defensa.idee.es/mapas?";
    thunderforestUrl = this.mapBoxService.getMapBoxUrl('transport_light')[0].value;
    mapBoxDarkUrl = this.mapBoxService.getMapBoxUrl('dark')[0].value;
    mapBoxLightUrl = this.mapBoxService.getMapBoxUrl('light')[0].value;


    public topoLayers = {
        openStreetMapLayer: L.tileLayer(this.osmUrl, {
            attribution: this.osmAttrib,
            maxZoom: this.maxZoom,
            maxNativeZoom: 18,
            zIndex: this.zIndex
        }),
        ignMapaRasterLayer: L.tileLayer(this.ignMapaRasterUrl, {
            maxZoom: this.maxZoom,
            minZoom: 1,
            attribution: "© IGN",
            zIndex: this.zIndex

        }),
        ignOrthoimageCoverageLayer: L.tileLayer.wms(this.ignPnoa, {
            layers: 'OI.OrthoimageCoverage',
            format: 'image/jpeg',
            transparent: true,
            maxZoom: this.maxZoom,
            attribution: "© IGN PNOA",
            zIndex: this.zIndex

        }),
        ignBaseLayer: L.tileLayer(this.ignBaseUrl, {
            maxZoom: this.maxZoom,
            minZoom: 1,
            attribution: "© IGN",
            zIndex: this.zIndex

        }),
        ignLidarLayer: L.tileLayer(this.ignLidarUrl, {
            maxZoom: this.maxZoom,
            minZoom: 1,
            attribution: "© IGN",
            zIndex: this.zIndex

        }),
        defensacegetM682Layer: L.tileLayer.wms(this.defensaUrl, {
            layers: 'ceget_M682',
            format: 'image/png',
            minZoom: 1,
            maxZoom: this.maxZoom,
            transparent: true,
            attribution: "© IGN, CEGET, CECAF",
            zIndex: this.zIndex

        }),
        // transport with token
        thunderforestTransportLayer:
            L.tileLayer(this.thunderforestUrl, {
                maxZoom: this.maxZoom,
                minZoom: 1,
                attribution: "© Thunderforest",
                zIndex: this.zIndex

            }),
        // transport with token
        mapBoxDarkLayer:
            L.tileLayer(this.mapBoxDarkUrl, {
                maxZoom: this.maxZoom,
                minZoom: 1,
                attribution: "© MapBox",
                zIndex: this.zIndex

            }),
        mapBoxLightLayer:
            L.tileLayer(this.mapBoxLightUrl, {
                maxZoom: this.maxZoom,
                minZoom: 1,
                attribution: "© MapBox",
                zIndex: this.zIndex

            }),
    }

    constructor(private mapBoxService: MapBoxService) { }

    public get(layers: string) {
        if (layers === 'topoLayers') {
            return this.topoLayers;
        }
        return {};
    }
}
