import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StartLeftListComponent } from './start-left-list/start-left-list.component';

@NgModule({
  imports: [CommonModule],
  declarations: [StartLeftListComponent],
  exports: [StartLeftListComponent],
})
export class StartLeftMenuModule { }
