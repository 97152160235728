import { NgModule } from '@angular/core';
import { Routes } from '@angular/router';
import { RouterModule } from '@angular/router';
import { AuthGuard } from '@compass/auth';

const routes: Routes = [
  { path: 'login', loadChildren: () => import('./login/login.module').then(m => m.LoginModule) },
  {
    path: '',
    canActivate: [AuthGuard], // apply guard for all this routes
    children: [
      { path: '', redirectTo: '/studies', pathMatch: 'full' },
      { path: 'studies', loadChildren: () => import('./studies/studies.module').then(m => m.StudiesModule) },
      { path: 'ecuacion', loadChildren: () => import('./ecuacion/eucacion.module').then(m => m.StoresModule) },
      {
        path: 'geomarketing',
        loadChildren: () => import('./geomarketing/geomarketing.module').then(m => m.StoresModule)
      },
      { path: 'campaigns', loadChildren: () => import('./campaigns/campaigns.module').then(m => m.CampaignsModule) },
      { path: 'analytics', loadChildren: () => import('./analytics/analytics.module').then(m => m.AnalyticsModule) },
      { path: 'mobility', loadChildren: () => import('./mobility/mobility.module').then(m => m.MobilityModule) },
      { path: 'business', loadChildren: () => import('./business/business.module').then(m => m.BusinessModule) },
      { path: 'users', loadChildren: () => import('./users/users.module').then(m => m.UsersModule) },
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
