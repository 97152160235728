import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SidenavComponent } from './sidenav/sidenav.component';
import { MaterialModule } from '@compass/material';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

@NgModule({
  imports: [
    CommonModule,
    MaterialModule,
    MatProgressSpinnerModule
  ],
  exports: [
    CommonModule,
    SidenavComponent
  ],
  declarations: [
    SidenavComponent,
  ]
})
export class PageNavigationSidenavModule {
}
