<ul class="nav-ul">
  <li *ngFor="let option of options" [ngClass]="option.active ? 'active' : 'inactive'">
    <a href="#" (click)="loadComponent($event, option)"
       *ngIf="option.canSee === undefined || option.canSee"
       [attr.data-cy]="option.key">
      <span class="material-icons">{{ option.icon }}</span>

      <span>{{ option.title }}</span>

      <span *ngIf="option.options && !option.active" class="material-icons">keyboard_arrow_down</span>
      <span *ngIf="option.options && option.active" class="material-icons">keyboard_arrow_up</span>
    </a>

    <!-- SUBELEMENTS -->
    <ul class="nav-ul" *ngIf="option.active">
      <li *ngFor="let subOption of option.options" [className]="{active: option.active}">
        <a href="#" (click)="loadComponent($event, subOption)"
           [ngClass]="subOption.active ? 'active' : 'inactive'" [attr.data-cy]="subOption.key">
          <span class="material-icons">
              {{ subOption.icon }}
          </span>
          <span>{{ subOption.title }}</span>
        </a>
      </li>
    </ul>
    <!--/ SUBELEMENTS -->
  </li>
</ul>
