import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'compass-start-left-list',
  templateUrl: './start-left-list.component.html',
  styleUrls: ['./start-left-list.component.scss']
})
export class StartLeftListComponent implements OnInit {
  @Input() setup: any;
  menuItems: any[];

  constructor(private router: Router) { }

  goToPage(page) {
    let route = '/' + page;
    this.router.navigate([route]);
  }

  ngOnInit(): void {
    this.menuItems = this.setup.menuItems;
  }

}
