import { Inject, Injectable } from '@angular/core';
import { ApiService, ApiServiceInterface } from '@compass/shared/api-service';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class PoisService extends ApiService implements ApiServiceInterface {
  url = `/abacusgis/core/${this.NAMESPACE}/collection/point_poi`;
  devUrl = null;

  pois$: BehaviorSubject<any> = new BehaviorSubject<any>([]);
  actualPoi$: BehaviorSubject<any> = new BehaviorSubject<any>([]);

  constructor(
    @Inject('environment') environment,
    private http: HttpClient
  ) {
    super(environment);
  }

  all(): Observable<any> {
    return this.http
      .get(`${this.url}?query={"properties.id_categoria": 20}`)
      .pipe(
        map((pois: any) => {
          this.pois$.next(pois);
          return pois;
        })
      );
  }

  allInStudy(studyId, category = null) {
    return this.http.get(`${this.url}?study_id=${studyId}&query={${this.getCategoryQuery(category)}}`);
  }

  find(id: string | number): Observable<any> {
    return this.http
      .get(`${this.url}?query={"properties.id": ${id}}`)
      .pipe(
        map((pois: any) => {
          let poi = pois.pop();
          this.actualPoi$.next(poi);
          return poi;
        })
      );
  }

  create(data: any): Observable<any> {
    return undefined;
  }

  delete(id: string | number): Observable<any> {
    return this.http.delete(`${this.url}/${id}`);
  }

  update(id: string | number, data: any): Observable<any> {
    return undefined;
  }

  getCategoryQuery(category: string): string {
    let query = '"properties.key_categoria":{"$nin":["transporte","glocally_pois"]}';

    switch (category) {
      case 'local': {
        query = '"properties.key_categoria":"glocally_pois"';
        break;
      }
      case 'transport': {
        query = '"properties.key_categoria":"transporte"';
        break;
      }
    }

    return  query;
  }
}
