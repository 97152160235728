import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BricksApiService } from './bricks-api.service';



@NgModule({
  declarations: [],
  imports: [CommonModule],
  exports: [],
  providers: [BricksApiService]
})
export class BrickApiModule { }
