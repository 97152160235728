import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PoiService } from './poi.service';
import { SidenavPoisLevelsService } from './sidenav-pois-levels.service';
import { SidenavPoisApiService } from './sidenav-pois-api.service';
import { GeoCoordsService } from '@compass/geo-coords/data-access';

@NgModule({
  imports: [CommonModule],
  providers: [
    PoiService,
    SidenavPoisLevelsService,
    SidenavPoisApiService,
    GeoCoordsService,
  ],
})
export class PoisDataAccessPoiModule { }
//
