export * from './lib/utils-d3.module';
export { LocaleEsService } from './lib/locale-es.service';
export { TooltipStyleService } from './lib/tooltipStyle.service';
export { DimensionsService } from './lib/dimensiones.service';
export { DefaultVarsService } from './lib/default-vars.service';
export { AxisTitleService } from './lib/axis-title.service';
export { MeasureService } from './lib/measure.service';
export { GroupedHorizontalBarchartComponent } from "./lib/grouped-horizontal-barchart/grouped-horizontal-barchart.component";
export { GroupedVerticalBarchartComponent } from "./lib/grouped-vertical-barchart/grouped-vertical-barchart.component";

export { GroupedVerticalBarLayoutService } from './lib/layout-wrappers/grouped-vertical-bar-layout.service';
export { GroupedHorizontalBarLayoutService } from './lib/layout-wrappers/grouped-horizontal-bar-layout.service';
export { PieChartComponent } from "./lib/pie-chart/pie-chart.component";
export { PieChartLayoutService } from "./lib/layout-wrappers/pie-chart-layout.service";
export { HeatMatrixComponent } from "./lib/heat-matrix/heat-matrix.component";
export { HeatMatrixLayoutService } from "./lib/layout-wrappers/heat-matrix-layout.service";
export { HeatMatrixReshapeService } from "./lib/heat-matrix/heat-matrix-reshape.service";
export { RangeSliderComponent } from './lib/range-slider/range-slider.component';
export { RangeSliderService } from './lib/range-slider/range-slider.service';
export { RangeSliderLayoutService } from './lib/layout-wrappers/range-slider-layout.service';
export { RangeSliderValue } from './lib/range-slider/range-slider.service';

