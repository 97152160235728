import { Component, OnInit, Input } from '@angular/core';
import { SimpleChange } from "@angular/core";
import { ChangeDetectionStrategy } from "@angular/core";
import { ViewEncapsulation } from "@angular/core";
import { DimensionsService } from "../dimensiones.service";
import { DefaultVarsService } from "../default-vars.service";
import { LocaleEsService } from "../locale-es.service";
import { AxisTitleService } from "../axis-title.service";
import { MeasureService } from "../measure.service";
import { ColorsService } from '@compass/utils/misc';
import { WordingService } from "@compass/utils/dictionaries";

import * as d3 from "d3";
@Component({
  selector: 'compass-heat-matrix',
  templateUrl: './heat-matrix.component.html',
  styleUrls: ['./heat-matrix.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeatMatrixComponent implements OnInit {
  @Input() setup: any;
  @Input() hour: Date;
  win: any = window; // in use
  // data
  data: any;
  layout: any;
  design: any;
  style: any;
  rawData: any;
  dataChart: any;
  xLabels: Array<string>;
  yLabels: Array<string>;
  // var
  timeout: any = false;
  resize_delay: number;
  default_time: number;
  chartId: string;
  key: string;
  class: string;

  //containers
  container: any;
  svg: any;
  chartLegend: any;
  legendColors: any;
  chartOuter: any;
  chartInner: any;

  // sizes
  margin: any;
  height: number;
  width: number;
  // Scales;
  xScale: any;
  yScale: any;
  xDomain: any;
  yDomain: any;
  colors: Array<string>;
  colorScale: any;
  // axis
  xAxis: any;
  yAxis: any;
  // color max min
  maxVal: number;
  minVal: number;

  // color legend
  rectData: any;
  legend: any;
  legendScale: any;
  lAxis: any;
  legendAxis: any;
  legendDomain: Array<number>







  constructor(private dimensionsService: DimensionsService,
    private defaultVarsService: DefaultVarsService,
    private colorsService: ColorsService,
    private axisTitleService: AxisTitleService,
    private measureService: MeasureService,
    private localeEsService: LocaleEsService,
    private wordingService: WordingService) { }

  reshapedata() {
    this.rawData = this.data.children;
    //this.xLabels = this.data.xLabels;
    //this.yLabels = this.data.yLabels;

    this.maxVal = d3.max(this.rawData, d => d.valor);
    this.minVal = d3.min(this.rawData, d => d.valor);


    this.dataChart = d3.nest().key((d) => {
      return d.yVal;
    }).entries(this.rawData).map(function (obj) {
      obj.values = obj.values.map(function (values) {
        return {
          key: values.xVal,
          value: values.valor
        };
      });
      return obj;
    });

    this.yDomain = this.dataChart.map((d) => {
      return d["key"];
    });
    this.xDomain = this.dataChart[0].values.map((d) => {
      return d["key"];
    });

    // render everything!
    this.render();

  }

  public render(): void {
    let dimensions: any = this.dimensionsService.getDimensions(
      this.svg,
      "#" + this.chartId,
      this.margin
    );
    this.width = dimensions.width;
    this.height = dimensions.height;

    this.xScale.domain(this.xDomain).range([0, this.width]);
    this.yScale.domain(this.yDomain).range([0, this.height]);
    this.colorScale.domain([this.minVal, this.maxVal]).range(this.colors);

    // Apply to svg
    this.svg
      .attr("width", this.width + this.margin.right + this.margin.left)
      .attr("height", this.height + this.margin.top + this.margin.bottom)
      .attr(
        "viewBox",
        "0 0 " +
        (this.width + this.margin.left + this.margin.right) +
        " " +
        (this.height + this.margin.top + this.margin.bottom)
      )
      .attr("preserveAspectRatio", "xMaxYMax meet");

    this.drawAxis();
    this.drawRows();
    this.drawCells();
    this.drawColorRects();
    this.drawTextValues();
    if (this.legend.visible) {
      this.drawLegend();
    }


  }
  drawAxis = () => {
    this.chartOuter
      .selectAll(".x.axis")
      .attr("transform", `translate(0, 0)`)
      .transition()
      .duration(this.default_time)
      .call(this.xAxis);
    this.chartOuter
      .selectAll(".y.axis")
      .attr("transform", "translate(0, 0)")
      .transition()
      .duration(this.default_time)
      .call(this.yAxis);
  }
  drawRows = () => {
    let rows = this.chartInner
      .selectAll("g.row")
      .data(this.dataChart);
    // Exit the nodes
    rows
      .exit()
      .attr("class", "exit")
      .transition()
      .duration(this.default_time / 2)
      .remove();
    rows
      .enter()
      .append("svg:g")
      .attr("class", "row")
      .attr("transform", d => {
        return "translate(0," + -this.yScale(d.key) + ")";
      });

    rows = this.chartInner.selectAll("g.row");
    rows
      .transition()
      .duration(this.default_time)
      .attr("transform", d => {
        return "translate(0," + this.yScale(d.key) + ")";
      });
  }
  drawCells = () => {

    let cells = this.chartInner
      .selectAll("g.row")
      .selectAll("g.cell")
      .data(d => {
        return d.values
      });

    cells
      .exit()
      .attr("class", "exit")
      .transition()
      .duration(this.default_time / 2)
      .remove();
    cells
      .enter()
      .append("svg:g")
      .attr("class", "cell")
      .attr("cursor", "pointer")
      .attr("transform", d => {
        return "translate(" + this.xScale(d.key) + ",0)";
      });
    cells = this.chartInner.selectAll("g.cell");
    cells
      .transition()
      .duration(this.default_time)
      .attr("transform", d => {
        return "translate(" + this.xScale(d.key) + ",0)";
      });

  }
  drawColorRects = () => {
    let colorRects = this.chartInner
      .selectAll("g.cell")
      .selectAll('rect.colorRect')
      .data((d) => {
        return [d];
      });

    colorRects
      .exit()
      .attr("class", "exit")
      .transition()
      .duration(this.default_time / 2)
      .remove();

    colorRects
      .enter()
      .append("svg:rect")
      .attr("class", "colorRect")
      .attr("x", 0)
      .attr("y", 0)
      .attr("height", this.yScale.bandwidth())
      .attr("width", this.xScale.bandwidth())
      .attr("fill-opacity", 0)
      .attr("stroke-opacity", 0)
      .attr("fill", "#5f249f");
    colorRects = this.chartInner.selectAll("g.cell").selectAll("rect.colorRect");

    colorRects
      .transition()
      .duration(this.default_time)
      .attr("x", 0)
      .attr("y", 0)
      .attr("height", this.yScale.bandwidth())
      .attr("width", this.xScale.bandwidth())
      .attr("fill-opacity", 1)
      .attr("stroke-opacity", 1)
      .attr("fill", (d, i) => {
        return this.colorScale(d.value);
      })

  }

  drawTextValues = () => {
    let textValues = this.chartInner
      .selectAll("g.cell")
      .selectAll('text.textValues')
      .data((d) => {
        return [d];
      });

    textValues
      .exit()
      .attr("class", "exit")
      .transition()
      .duration(this.default_time / 2)
      .remove();

    textValues
      .enter()
      .append("svg:text")
      .attr("class", "textValues")
      .attr("x", this.xScale.bandwidth() / 2)
      .attr("y", this.yScale.bandwidth() / 2)
      .attr("dy", "0.5em")
      .attr("dx", "0.1em")
      .attr("text-anchor", "middle")
      .attr("font-size", "12px")
      .text((d, i) => {
        return d.value
      });

    textValues = this.chartInner.selectAll("g.cell").selectAll("text.textValues");
    textValues
      .transition()
      .duration(this.default_time)
      .text((d, i) => {
        return d.value
      });

  }
  drawColorSteps = () => {
    this.rectData = [];
    let i = 0;
    let factor = (this.maxVal - this.minVal) / this.legend.steps;
    for (i; i < this.colors.length; i++) {
      this.rectData.push({
        key: i,
        value: (i + 1) * factor
      });
    }

    let legendRects = this.legendColors
      .selectAll("rect.legendRect")
      .data(this.rectData);

    legendRects
      .exit()
      .attr("class", "exit")
      .transition()
      .duration(this.default_time / 2)
      .remove();

    legendRects
      .enter()
      .append("svg:rect")
      .attr("class", "legendRect")
      .attr("x", (d, i) => {
        return (this.legend.width / this.legend.steps) * i
      })
      .attr("y", 0)
      .attr("height", this.legend.height)
      .attr("width", this.legend.width / this.legend.steps)
      .attr("fill-opacity", 0)
      .attr("stroke-opacity", 0)
      .attr("fill", (d, i) => {
        return this.colors[i]
      })

    legendRects = this.legendColors.selectAll("rect.legendRect");
    legendRects
      .transition()
      .duration(this.default_time)
      .attr("x", (d, i) => {
        return (this.legend.width / this.legend.steps) * i
      })
      .attr("y", 0)
      .attr("height", this.legend.height)
      .attr("width", this.legend.width / this.legend.steps)
      .attr("fill-opacity", 1)
      .attr("stroke-opacity", 1)
      .attr("fill", (d, i) => {
        return this.colors[i]
      })


  }
  drawLegendScale = () => {
    this.legendScale = d3.scaleLinear();
    this.legendDomain = [this.minVal, this.maxVal];
    this.lAxis = d3.axisTop(this.legendScale).ticks(this.rectData.length + 1);
    this.legendScale
      .rangeRound([0, this.legend.width])
      .domain(this.legendDomain)
      .nice();

    this.chartLegend
      .selectAll("g.legendAxis")
      .attr("transform", `translate(0,  ${this.legend.margin.top})`)
      .transition()
      .duration(this.default_time)
      .call(this.lAxis);

  }

  drawLegend = () => {
    this.drawColorSteps();
    this.drawLegendScale();
  };

  public runAll = (): void => {
    // SCALES HERE
    this.xScale = d3.scaleBand().paddingInner(this.style.paddingInner);
    this.yScale = d3.scaleBand().padding(this.style.padding);
    this.xAxis = d3.axisTop(this.xScale);
    this.yAxis = d3.axisLeft(this.yScale);
    this.colorScale = d3.scaleQuantize();

    // ELEMENTS
    this.container = d3.select("#" + this.chartId); // placeholder div for svg
    this.svg = this.container
      .selectAll("svg")
      .data([{}])
      .enter()
      .append("svg:svg");

    this.chartLegend = this.svg
      .selectAll("g.chartLegend")
      .data([{}])
      .enter()
      .append("svg:g")
      .attr("class", "chartLegend")
      .attr("transform", "translate(" + this.margin.left + "," + (this.height - this.margin.bottom + 16) + ")");

    this.legendColors = this.chartLegend
      .selectAll("g.legendColors") // chart without axis to clipPath
      .data([{}])
      .enter()
      .append("svg:g")
      .attr("class", "legendColors")
      .attr("transform", "translate(" + 0 + "," + this.legend.margin.top + ")");;

    this.chartLegend
      .selectAll("g.legendAxis") // chart without axis to clipPath
      .data([{}])
      .enter()
      .append("svg:g")
      .attr("class", "legendAxis");



    this.chartOuter = this.svg
      .selectAll("g.chartOuter")
      .data([{}])
      .enter()
      .append("svg:g")
      .attr("class", "chartOuter")
      .attr(
        "transform",
        "translate(" + this.margin.left + "," + this.margin.top + ")"
      );

    this.chartInner = this.chartOuter
      .selectAll("g.chartInner") // chart without axis to clipPath
      .data([{}])
      .enter()
      .append("svg:g")
      .attr("class", "chartInner");

    // Axis groups
    this.chartOuter
      .selectAll(".x.axis")
      .data([{}])
      .enter()
      .append("svg:g")
      .attr("class", "x axis");
    this.chartOuter
      .selectAll(".y.axis")
      .data([{}])
      .enter()
      .append("svg:g")
      .attr("class", "y axis");
    this.win.addEventListener("resize", () => {
      clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        this.render();
      }, this.resize_delay);
    });

    this.reshapedata();



  }

  public destroyChart = (): void => {
    d3.select("#" + this.chartId).html("");
    // Stop resize events
    d3.select(this.win).on("resize", null);
  };


  public init(): void {
    //this.destroyTooltip();
    this.destroyChart();
    this.runAll();
  }
  ngAfterViewInit(): void {
    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      this.init();
    }, this.resize_delay);

    //window.dispatchEvent(new Event('resize'));
  }



  ngOnChanges(changes: { [propName: string]: SimpleChange }) {
    //console.log('changes', changes, this.svg, this.chartSelected, this.chartId, this.margin);
    let changeObj = Object.keys(changes);
    this.data = this.setup.data;
    this.layout = this.setup.layout;
    this.design = this.layout.design;
    this.style = this.design.style;
    this.key = this.layout.key;
    this.chartId = this.key;
    this.class = this.layout.class;
    this.height = this.design.height;
    this.margin = this.design.margin;
    this.legend = this.layout.legend;

    this.default_time = this.defaultVarsService.default_time;
    this.colors = this.colorsService.purpleToYellow;



    if (changeObj.length < 2) {
      clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        this.reshapedata();
      }, this.resize_delay / 4);

    }
    return "";
  }


  ngOnInit(): void {
  }

}
