import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { HttpErrorResponse } from '@angular/common/http';
import { MarkerOptionsApiService } from '@compass/utils/navigation';
import * as d3 from 'd3';

// restaurants, banks, pharma, schools
export interface Poi {
  key: string;
  name: string;
  selected: boolean;
  category: string;
}
export interface Pois extends Array<Poi> { }

export interface dbPoi {
  type: string;
  properties: {
    categoria: string;
    class_: string;
    direccion: string;
    ensenya_desc: string;
    icono: string;
    id: number;
    id_alimarket: number;
    id_categoria: number;
    id_ensenya: number;
    key_categoria: string;
    key_ensenya_desc: string;
    key_sub_categoria: string;
    nombre: string;
    selected: boolean;
    source: string;
    sub_categoria: string;
    sup_m2: number;
  };
  geometry: {
    type: string;
    coordinates: Array<number>;
  };
}

export interface dbPois extends Array<dbPoi> { }

@Injectable({ providedIn: 'root' })
export class SidenavPoisApiService {
  // TRANSPORT
  public transportList$: BehaviorSubject<any> = new BehaviorSubject<any>(
    undefined
  );

  constructor(
    private http: HttpClient,
    private markerOptionsApiService: MarkerOptionsApiService
  ) { }

  handleError(error: HttpErrorResponse) {
    const kk = null;
    return of(kk);
  }

  // NEST NEW CATEGORIES
  nestCategories(data, key: string) {
    return d3
      .nest()
      .key((d) => {
        return d.properties[key];
      })
      .sortKeys(d3.ascending)
      .key((d) => {
        return d.properties.key_sub_categoria;
      })
      .sortKeys(d3.ascending)
      .key((d) => {
        return d.properties.key_ensenya_desc;
      })
      .sortKeys(d3.ascending)
      .entries(data);
  }

  // MAP NEW CATEGORIES

  reduceNodes(leafNodes, level, key) {
    return leafNodes.filter(
      (leafNode) =>
        leafNode.properties[level] === key && leafNode.properties.selected
    );
  }

  setFourLevelsProps(data, leafNodes) {
    return data.map((poi) => {
      let categoriesNodes = this.reduceNodes(
        leafNodes,
        'key_categoria',
        poi.key
      );
      categoriesNodes.length
        ? (poi['selected'] = true)
        : (poi['selected'] = false);
      poi['key'] = poi.key;
      poi['level'] = 'categories';
      poi['name'] = poi.values[0].values[0].values[0].properties.categoria;
      poi['category'] = poi.key;
      // poi['markerOptions'] = this.getMarkerOptions(poi, 'key');
      poi['values'] &&
        poi['values'].map((subpoi) => {
          let subcategoriesNodes = this.reduceNodes(
            leafNodes,
            'key_sub_categoria',
            subpoi.key
          );
          subcategoriesNodes.length
            ? (subpoi['selected'] = true)
            : (subpoi['selected'] = false);
          subpoi['level'] = 'sub_categories';
          subpoi['key'] = subpoi.key;
          subpoi['category'] = poi.key;
          //v['color'] = d.color;
          //subpoi['markerOptions'] = poi.markerOptions;
          subpoi['name'] = subpoi.values[0].values[0].properties.sub_categoria;
          subpoi['values'] &&
            subpoi['values'].map((company) => {
              let companiesNodes = this.reduceNodes(
                leafNodes,
                'key_ensenya_desc',
                company.key
              );
              companiesNodes.length
                ? (company['selected'] = true)
                : (company['selected'] = false);
              company['level'] = 'companies';
              company['key'] = company.values[0].properties.key_ensenya_desc;
              company['category'] = poi.key;
              company['subcategory'] = subpoi.key;
              //company['markerOptions'] = poi.markerOptions;
              company['name'] = company.values[0].properties.nombre;
              //z['color'] = d.color;
              company['values'] &&
                company['values'].map((place) => {
                  let placesNodes = this.reduceNodes(
                    leafNodes,
                    'id',
                    place.properties.id
                  );
                  placesNodes.length
                    ? (place['selected'] = true)
                    : (place['selected'] = false);
                  place['level'] = 'places';
                  place['key'] = place.properties.id;
                  place['category'] = poi.key;
                  place['subcategory'] = subpoi.key;
                  place['company'] = company.key;
                  place[
                    'markerOptions'
                  ] = this.markerOptionsApiService.getMarkerOptions(place);
                  place['name'] = place.properties.nombre;
                  //z['color'] = d.color;
                  return place;
                });
              return company;
            });
          return subpoi;
        });
      return poi;
    });
  }

  /**** TRANSPORT WITH TWO LEVELS */
  setTwoLevelsProps(data, leafNodes) {
    return data.map((poi) => {
      let categoriesNodes = this.reduceNodes(
        leafNodes,
        'key_sub_categoria',
        poi.key
      );
      categoriesNodes.length
        ? (poi['selected'] = true)
        : (poi['selected'] = false);
      poi['key'] = poi.key;
      poi['level'] = 'categories';
      poi['name'] = poi.values[0].properties.sub_categoria;
      poi['category'] = poi.key;
      //poi['markerOptions'] = this.getMarkerOptions(poi, 'key');
      poi['values'] &&
        poi['values'].map((subpoi) => {
          let subcategoriesNodes = this.reduceNodes(
            leafNodes,
            'id',
            subpoi.properties.id
          );
          subcategoriesNodes.length
            ? (subpoi['selected'] = true)
            : (subpoi['selected'] = false);
          subpoi['level'] = 'subcategories';
          subpoi['key'] = subpoi.properties.id;
          subpoi['category'] = poi.key;
          //v['color'] = d.color;
          subpoi[
            'markerOptions'
          ] = this.markerOptionsApiService.getMarkerOptions(subpoi);
          subpoi['name'] = subpoi.properties.nombre;
          return subpoi;
        });
      return poi;
    });
  }

  // NEST LOCAL POIS AND TRANSPORT: TWO LEVELS
  nestTwoLevelsPois(data, key: string) {
    return d3
      .nest()
      .key((d) => {
        return d.properties[key];
      })
      .sortKeys(d3.ascending)
      .entries(data);
  }
}
