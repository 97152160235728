<compass-footer>
  <div class="footer">
    <div fxLayout="row" fxLayout.xs="column">
      <div fxFlex="50%" class="text__align--left brand-container">
        <div class="image-wrapper">
          <a href="http://abacus-consulting.net/" target="_blank" name="Compass Lab" title="Compass Lab"><img
              src="../../../assets/img/brand/svg/lab-h-opacity.svg" class="image" alt="Compass Lab" /></a>
          <a href="http://abacus-consulting.net/" target="_blank" name="Compass Lab" title="Compass Lab"><img
              src="../../../assets/img/brand/svg/lab-h-white.svg" class="image-hover" alt="Compass Lab" /></a>
        </div>
        <p>
          <small>©2021 Compass. The Abacus Consulting Lab.</small>
        </p>
      </div>

      <!--<div fxFlex="33%" class="text__align&#45;&#45;center brand-container">
        <div class="image-wrapper"></div>

        <p><small></small></p>
      </div>-->
      <div fxFlex="50%" class="text__align--right brand-container">
        <div class="image-wrapper">
          <a href="http://abacus-consulting.net/" target="_blank" name="Abacus Consulting"
            title="Abacus Consulting"><img src="../../../assets/img/brand/svg/logo-abacus-h-opacity.svg" class="image"
              alt="Abacus Consulting" /></a>
          <a href="http://abacus-consulting.net/" target="_blank" name="Abacus Consulting"
            title="Abacus Consulting"><img src="../../../assets/img/brand/svg/logo-abacus-h-white.svg"
              class="image-hover right" alt="Abacus Consulting" /></a>
        </div>

        <p><small>Powered by Abacus Consulting</small></p>
      </div>
    </div>
  </div>
</compass-footer>
