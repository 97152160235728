import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { SelectionModel } from '@angular/cdk/collections';
import { MatTableDataSource } from "@angular/material/table";

@Injectable({ providedIn: "root" })
export class MatTableService {

  dataSource: MatTableDataSource<any>;
  selection: SelectionModel<any>;


  constructor(private http: HttpClient) { }


  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    if (this.selection && this.dataSource) {
      const numSelected = this.selection.selected.length;
      const numRows = this.dataSource.data.length;
      return numSelected === numRows;
    }
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.dataSource.data.forEach(row => this.selection.select(row));
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: any): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.level}`;
  }







}