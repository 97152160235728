import { Component, Input, OnInit, Output, EventEmitter, HostListener } from '@angular/core';

import * as d3 from 'd3';
import * as dc from 'dc';

// @ts-ignore
import crossfilter from 'crossfilter2';

import { CrossfilterService } from '../crossfilter.service';

@Component({
  selector: 'compass-row-chart',
  templateUrl: './row-chart.component.html',
  styleUrls: ['./row-chart.component.scss']
})
export class RowChartComponent implements OnInit {
  @Output() brushEnd = new EventEmitter<any>();
  @Input('corssfilterDimensionFunc') corssfilterDimensionFunc = null;
  @Input('chartId') inputChartId = '';

  chart: any; // generated barchart element
  chartId: string = 'barChart'; // component id

  filtered: any; // brush values [min, max]

  crossFilter: any;

  dimension: any;
  group: any;

  constructor(
    private crossfilterService: CrossfilterService
  ) {
  }

  ngOnInit(): void {
    this.chartId = this.inputChartId + this.chartId;

    this.crossfilterService.crossfilter$.subscribe((crossfilter) => {
      this.crossFilter = crossfilter;
      this.drawBrush();

      this.crossFilter.onChange(changed => {
        this.chart.redraw();
      });
    });
  }

  private drawBrush() {
    // let allGroup = this.crossfilterService.all();
    this.dimension = this.crossfilterService.createDimension(this.corssfilterDimensionFunc);
    this.group = this.dimension.group();
    this.chart = new dc.RowChart(`#${this.chartId}`);

    this.chart
      .gap(1)
      .dimension(this.dimension)
      .elasticX(true)
      .group(this.group);


    // this.barChart.x().domain(this.crossfilterService.mapping.range())
    // bar.on('preRedraw', chart => chart.rescale());
    dc.renderAll();

    // if filtered saved, use it to redraw barchart
    if (this.filtered) {
      // this.barChart.filter(dc.filters.RangedFilter(this.filtered[0], this.filtered[1]));
    }

    this.chart.addFilterHandler((filters, filter) => {
      if (filter) {
        const min = Math.ceil(filter[0]);
        const max = Math.floor(filter[1]);

        this.filtered = [min, max];
        this.brushEnd.emit([min, max]);
      }

      filters.push(filter);

      return filters;
    });
  }


  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.chart.width(document.getElementById(this.chartId).offsetWidth);
    dc.renderAll();
  }


}
