import { Component } from '@angular/core';
import { Input } from '@angular/core';

@Component({
  selector: 'compass-crud-icons',
  templateUrl: './crud-icons.component.html',
  styleUrls: ['./crud-icons.component.scss'],
})
export class CrudIconsComponent {
  @Input() icon_class: string;

  constructor() {}
}
