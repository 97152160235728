import { Component, OnInit, Input } from '@angular/core';
import { BusinessReshapeService } from '../business.reshape.service';
import { BusinessApiService } from '../business-api.service';

@Component({
  selector: 'compass-map-loader',
  templateUrl: './map-loader.component.html',
  styleUrls: ['./map-loader.component.scss']
})
export class MapLoaderComponent implements OnInit {
  @Input() setup: any[];
  formGroup$ = this.businessApiService.formGroup$;

  constructor(
    private businessApiService: BusinessApiService,
    private businessReshapeService: BusinessReshapeService) {
  }

  ngOnInit(): void {
    this.businessReshapeService.filterPois(this.formGroup$.value, this.setup);
  }

}

