<h3 style="margin-top:0;" mat-dialog-title>{{ this.data.title || 'Confirmar acción' }}</h3>

<div mat-dialog-content [innerHTML]="this.data.content"></div>

<div mat-dialog-actions fxLayout="row" fxLayoutAlign="space-between center">
  <div class="w-50">
    <button class="w-100" mat-flat-button (click)="actionClick(false)">
      {{ this.data.cancelText || 'Cancelar' }}
    </button>
  </div>
  <div class="w-50">
    <button class="w-100" mat-flat-button color="primary" (click)="actionClick(true)" cdkFocusInitial>
      {{ this.data.confirmText || 'Aceptar' }}
    </button>
  </div>
</div>

