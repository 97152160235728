import { Component, OnInit } from '@angular/core';
import { BricksApiService } from '../../../../../../../libs/brick-api/src';
import { LineTransitService } from '../../../../../../../libs/line-transit/data-access/src';
import { MapService } from '../../../../../../../libs/map/src';

@Component({
  selector: 'lab-transit',
  templateUrl: './transit.component.html',
  styleUrls: ['./transit.component.scss']
})
export class TransitComponent {
  transitLines$ = this.lineTransitService.transitLines$;

  constructor(
    private bricksApiService: BricksApiService,
    private lineTransitService: LineTransitService,
    private mapService: MapService
  ) {
    this.lineTransitService.setMap(this.mapService.map);

    this.lineTransitService.getAreaTransit(
      this.bricksApiService.fullStages$.value
    );
  }

}
