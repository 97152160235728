<mat-sidenav-container autosize class="estudio-sidenav left-sidenav-container z__index-top">
    <mat-sidenav #mainSidenav mode="side" position="start" fixedInViewport="true" [opened]="sidenavIsOpen"
        [ngClass]="setup.class">
        <div>
            <compass-start-left-button-close (click)="mainSidenav.toggle()">>
            </compass-start-left-button-close>
        </div>
        <div class="sidebar" style="overflow: hidden;">
            <div class="sidebar--inner">
                <div class="sidebar--direction">
                    <ng-content></ng-content>
                </div>
            </div>
        </div>
    </mat-sidenav>

    <mat-sidenav-content></mat-sidenav-content>

</mat-sidenav-container>


<compass-start-left-button-open *ngIf="compassIsOn" (click)="mainSidenav.toggle()"></compass-start-left-button-open>