import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { Observable } from 'rxjs/Observable';
import { of } from 'rxjs';
import { catchError, map, filter } from 'rxjs/operators';
import { PoiBusiness } from './business-poi.type';
import { ColorsService } from '@compass/utils/misc';
import { LineTransit } from '@compass/line-transit/data-access';
import { ApiService } from '@compass/shared/api-service';

@Injectable({ providedIn: 'root' })
export class BusinessApiService extends ApiService {

  public formGroup: any = {
    descripcion_cnae: '',
    selected_cnae: ['Todas'],
    rating_morosidad: [1, 9],
    cliente: undefined
  };

  public business$: BehaviorSubject<PoiBusiness[]> = new BehaviorSubject<PoiBusiness[]>(undefined);
  public formGroup$: BehaviorSubject<any> = new BehaviorSubject<any>(this.formGroup);

  url = `/abacusgis/core/${this.NAMESPACE}/collection/point_companies_pois/`;

  constructor(
    @Inject('environment') environment,
    public http: HttpClient,
    private router: Router,
    private colorsService: ColorsService
  ) {
    super(environment);
  }


  // PRO API
  handleError(error: HttpErrorResponse) {
    const kk = null;
    return of(kk);
  }


  getRatingMorosidad(rating) {
    // in some case there's a textual instead of number
    if(typeof rating === 'string') {
      rating = 0;
    }
    return rating;
  }

  public getBusinessInStudy(study) {
    this.business$.next([]);

    const areaValue = Object.entries(study.properties.areas)[0][1] as LineTransit;
    const geometry = JSON.stringify(areaValue.geometry);

    const request = this.http.get<PoiBusiness[]>(`${this.url}?study_id=${study.properties.id}`);
    request.subscribe(business => {
      business = this.formatData(business);
      this.business$.next(business);
    });

    return request;
  }

  // DEV API fn
  public getBusinessData(): Observable<PoiBusiness[]> {
    return this.http.get<PoiBusiness[]>(`${this.url}`).pipe(
      map((data: PoiBusiness[]) => {
        data = this.formatData(data);

        this.business$.next(data);

        return data;
      }),
      catchError(this.handleError)
    );
  };


  public setBusiness(business: PoiBusiness[]) {
    this.business$.next(business);
  }

  public getBusiness(): Observable<PoiBusiness[]> {
    return this.business$.asObservable();
  }


  public setFormGroup(f) {
    this.formGroup$.next(f);
  }

  public getFormGroup(): Observable<any> {
    return this.formGroup$.asObservable();
  }


  public formatData(data) {
    // map data here if needed
    return data.map((business: any) => {
      // TODO: pintar icono de distinto color si es cliente o no
      business['properties']['cliente'] = business['properties']['cliente'] ? business['properties']['cliente'] : Math.random() < 0.5; // now is a random value, change for production purposes
      business['properties']['fillColor'] = business['properties']['cliente'] ? this.colorsService.colors.primary_color : this.colorsService.colors.secondary_color;
      business['properties']['titulo_comercial'] = business['properties']['titulo_comercial'] || '-sin definir-';
      business['properties']['cif'] = business['properties']['cif'] ? business['properties']['cif'] : '00000000';
      business['properties']['class_'] = business['properties']['class_'] ? business['properties']['class_'] : 'point_companies';
      business['properties']['cnae'] = business['properties']['cnae'] ? business['properties']['cnae'] : 0;
      business['properties']['codigo_postal'] = business['properties']['codigo_postal'] ? business['properties']['codigo_postal'] : null;
      business['properties']['denominacion'] = business['properties']['denominacion'] ? business['properties']['denominacion'] : null;
      business['properties']['descripcion_cnae'] = business['properties']['descripcion_cnae'] ? business['properties']['descripcion_cnae'] : null;
      business['properties']['geo'] = business['properties']['geo'] ? business['properties']['geo'] : null;
      business['properties']['geo_level'] = business['properties']['geo_level'] ? business['properties']['geo_level'] : null;
      business['properties']['geo_level_id'] = business['properties']['geo_level_id'] ? business['properties']['geo_level_id'] : null;
      business['properties']['id'] = business['properties']['id'] ? business['properties']['id'] : null;
      business['properties']['id_firma'] = business['properties']['id_firma'] ? business['properties']['id_firma'] : null;
      business['properties']['key'] = business['properties']['key'] ? business['properties']['key'] : null;
      business['properties']['municipio'] = business['properties']['municipio'] ? business['properties']['municipio'] : null;
      business['properties']['nombre_via'] = business['properties']['nombre_via'] ? business['properties']['nombre_via'] : null;
      business['properties']['numero_via'] = business['properties']['numero_via'] ? business['properties']['numero_via'] : null;
      business['properties']['opinion_credito'] = business['properties']['opinion_credito'] ? business['properties']['opinion_credito'] : null;
      business['properties']['provincia'] = business['properties']['provincia'] ? business['properties']['provincia'] : null;
      business['properties']['rating_morosidad'] = this.getRatingMorosidad(business['properties']['rating_morosidad']);

      business['properties']['sigla'] = business['properties']['sigla'] ? business['properties']['sigla'] : null;
      business['properties']['source'] = business['properties']['source'] ? business['properties']['source'] : null;
      business['properties']['tipo_via'] = business['properties']['tipo_via'] ? business['properties']['tipo_via'] : null;
      business['properties']['ano_inicio_actividad'] = business['properties']['ano_inicio_actividad'] ? business['properties']['ano_inicio_actividad'] : 2020;
      business['properties']['estado_confirmado'] = business['properties']['estado_confirmado'] ? business['properties']['estado_confirmado'] : 'Desconocido';
      business['properties']['fecha_estado_confirmado'] = business['properties']['fecha_estado_confirmado'] ? business['properties']['fecha_estado_confirmado'] : null;
      business['properties']['resto_via'] = business['properties']['resto_via'] ? business['properties']['resto_via'] : null;
      business['properties']['apellido1'] = business['properties']['apellido1'] ? business['properties']['apellido1'] : null;
      business['properties']['cargo'] = business['properties']['cargo'] ? business['properties']['cargo'] : null;
      business['properties']['nombre'] = business['properties']['nombre'] ? business['properties']['nombre'] : null;
      business['properties']['apellido2'] = business['properties']['apellido2'] ? business['properties']['apellido2'] : null;
      business['properties']['cnae2'] = business['properties']['cnae2'] ? business['properties']['cnae2'] : null;
      business['properties']['descripcion_cnae2'] = business['properties']['descripcion_cnae2'] ? business['properties']['descripcion_cnae2'] : null;
      business['properties']['num_instalaciones'] = business['properties']['num_instalaciones'] ? business['properties']['num_instalaciones'] : Math.floor(Math.random() * 10);
      business['properties']['ejercicio_empleados'] = business['properties']['ejercicio_empleados'] ? business['properties']['ejercicio_empleados'] : null;
      business['properties']['total_empleados'] = business['properties']['total_empleados'] ? business['properties']['total_empleados'] : Math.floor(Math.random() * 100);
      business['properties']['telefono'] = business['properties']['telefono'] ? business['properties']['telefono'] : '6000000';
      business['properties']['exporta'] = business['properties']['exporta'] ? business['properties']['exporta'] : null;
      business['properties']['cnae3'] = business['properties']['cnae3'] ? business['properties']['cnae3'] : null;
      business['properties']['descripcion_cnae3'] = business['properties']['descripcion_cnae3'] ? business['properties']['descripcion_cnae3'] : null;
      business['properties']['temporales'] = business['properties']['temporales'] ? business['properties']['temporales'] : Math.floor(Math.random() * 10);
      business['properties']['ultimo_ejercicio_depositado'] = business['properties']['ultimo_ejercicio_depositado'] ? business['properties']['ultimo_ejercicio_depositado'] : null;
      business['properties']['importa'] = business['properties']['importa'] ? business['properties']['importa'] : null;
      business['properties']['titulo_comercial'] = business['properties']['titulo_comercial'] ? business['properties']['titulo_comercial'] : null;
      business['properties']['web'] = business['properties']['web'] ? business['properties']['web'] : null;
      business['properties']['fijos'] = business['properties']['fijos'] ? business['properties']['fijos'] : Math.floor(Math.random() * 10);
      business['properties']['fax'] = business['properties']['fax'] ? business['properties']['fax'] : null;
      business['properties']['tamano_empresa'] = business['properties']['tamano_empresa'] ? business['properties']['tamano_empresa'] : 'Pequeña Empresa';
      business['properties']['ejercicio_partidas'] = business['properties']['ejercicio_partidas'] ? business['properties']['ejercicio_partidas'] : null;
      business['properties']['fondo_maniobra'] = business['properties']['fondo_maniobra'] ? business['properties']['fondo_maniobra'] : Math.floor(Math.random() * 1000000);
      business['properties']['fondos_propios'] = business['properties']['fondos_propios'] ? business['properties']['fondos_propios'] : Math.floor(Math.random() * 1000000);
      business['properties']['resultado_ejercicio'] = business['properties']['resultado_ejercicio'] ? business['properties']['resultado_ejercicio'] : Math.floor(Math.random() * 1000000);
      business['properties']['rtdo_antes_impuestos'] = business['properties']['rtdo_antes_impuestos'] ? business['properties']['rtdo_antes_impuestos'] : Math.floor(Math.random() * 100000);
      business['properties']['total_activo'] = business['properties']['total_activo'] ? business['properties']['total_activo'] : Math.floor(Math.random() * 1000000);
      business['properties']['ventas'] = business['properties']['ventas'] ? business['properties']['ventas'] : Math.floor(Math.random() * 1000000);
      business['properties']['exportacion'] = business['properties']['%_exportacion'] ? business['properties']['%_exportacion'] : Math.floor(Math.random() * 100);
      business['properties']['importacion'] = business['properties']['%_importacion'] ? business['properties']['%_importacion'] : Math.floor(Math.random() * 100);
      business['properties']['total_hombres'] = business['properties']['total_hombres'] ? business['properties']['total_hombres'] : Math.floor(Math.random() * 10);
      business['properties']['total_mujeres'] = business['properties']['total_mujeres'] ? business['properties']['total_mujeres'] : Math.floor(Math.random() * 10);

      return business;
    });
  }

}
