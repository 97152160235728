import { Component, OnInit, Input } from '@angular/core';


@Component({
  selector: 'compass-cancel-button',
  templateUrl: './cancel-button.component.html',
  styleUrls: ['./cancel-button.component.scss']
})
export class CancelButtonComponent implements OnInit {
  @Input() router_link: string;

  constructor() { }

  ngOnInit(): void { }

}
