import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { HttpErrorResponse } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';



export interface Place {
  key: string,
  name: string,
  position: number,
  selected: boolean
}
export interface Places extends Array<Place> { }


@Injectable({ providedIn: "root" })
export class SideNavPlacesService {

  // complete list of POIS as observable to save selected state.
  public placesList$: BehaviorSubject<Places> = new BehaviorSubject<Places>(undefined);
  public stationsList$: BehaviorSubject<Places> = new BehaviorSubject<Places>(undefined);



  constructor(private http: HttpClient) { }

  handleError(error: HttpErrorResponse) {
    const kk = null;
    return of(kk);
  }


  setPlacesList(places: Places, activeTableRoot: string): void {
    if (activeTableRoot === 'pois') {
      this.placesList$.next(places);

    }
    if (activeTableRoot === 'transport') {
      this.stationsList$.next(places);

    }

  }

}