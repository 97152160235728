<mat-drawer-container autosize [hasBackdrop]="setup.hasBackdrop">
  <mat-drawer #sidenavDrawer
              class="sidenav-wrapper  {{ setup.class }}"
              [mode]="setup.mode"
              [disableClose]="setup.disableClose"
              [opened]="opened"
              [autoFocus]="setup.autoFocus"
  >
    <div class="header-wrapper">
      <div class="history">
        <button *ngIf="historic.length" (click)="goBack()" class="back-close-btn" mat-icon-button
                [title]="'Volver a ' + (historic.length > 1 ? historic[historic.length-2].simpleTitle : 'menú principal')">
          <mat-icon>chevron_left</mat-icon>
        </button>

        <h3 class="breadcrums" *ngIf="!historic.length">Menú</h3>

        <!-- BREADCRUMB -->
        <ng-container *ngFor="let comp of historic; let i = index">
          <span class="breadcrums"
                title="{{ comp.simpleTitle || comp.title }}"
                [matMenuTriggerFor]="subMenu"
                [style.pointer-events]="!comp.options && !comp.options?.length ? 'none' : 'inherit'"
          >
              <mat-icon color="primary" *ngIf="comp.icon">{{ comp.icon }}</mat-icon>
              <span *ngIf="i != historic.length - 1"
                    [innerText]="comp.title.length > 5 ? '...' : comp.title"></span>
              <span *ngIf="i == historic.length - 1" [innerHTML]="comp.title"></span>
          </span>

          <!-- SUBMENU -->
          <mat-menu #subMenu="matMenu">
            <button mat-menu-item *ngFor="let subMenu of comp.options"
                    (click)="subMenu.action ? subMenu.action() : null">
              <mat-icon *ngIf="subMenu.icon">{{ subMenu.icon }}</mat-icon>
              {{ subMenu.title }}
            </button>
          </mat-menu>
          <!--/ SUBMENU -->

        </ng-container>
        <!--/ BREADCRUMB -->

        <span *ngIf="historic.length > 1"></span>
      </div>
      <button (click)="close()" mat-icon-button title="Cerrar menú">
        <mat-icon>menu_open</mat-icon>
      </button>
      <mat-progress-bar mode="indeterminate" *ngIf="loading && !fullLoading"></mat-progress-bar>
    </div>

    <!-- SIMPLE MENU -->
    <div id="simple-menu" *ngIf="!actualComponent && menuOptions">
      <mat-action-list>
        <!-- ACTUAL STUDY -->
        <ng-container *ngIf="actualStudy">
          <button mat-list-item  (click)="openActualStudy()">
            <mat-icon mat-list-icon color="primary">folder</mat-icon>
            <div mat-line><strong>{{ actualStudy.properties.study.name }}</strong></div>
            <small mat-line>Estudio activo</small>
          </button>

          <mat-divider></mat-divider>
        </ng-container>
        <!--/ ACTUAL STUDY -->

        <button *ngFor="let option of menuOptions"
                (click)="optionClicked(option)"
                mat-list-item>
          {{ option.title }}
        </button>
      </mat-action-list>
    </div>
    <!--/ SIMPLE MENU -->

    <!-- COMPONENT MENU -->
    <div id="component-wrapper">
      <ng-container #sidenavContainer></ng-container>
    </div>
    <!-- COMPONENT MENU -->


    <div class="full-loader-wrapper" *ngIf="loading && fullLoading">
      <mat-progress-spinner mode="indeterminate"></mat-progress-spinner>
    </div>

  </mat-drawer>


  <ng-content select="[body]"></ng-content>


</mat-drawer-container>

