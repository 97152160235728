import { Component, OnInit } from '@angular/core';
import { SidenavService } from '@compass/page-navigation/sidenav';
import { ClientsService } from '@compass/clients';
import { StudyService } from '@compass/studies';

@Component({
  selector: 'lab-clients-menu',
  templateUrl: './clients-menu.component.html',
  styleUrls: ['./clients-menu.component.scss']
})
export class ClientsMenuComponent implements OnInit {
  clients: [];
  loading: true;

  constructor(
    public sidenavService: SidenavService,
    private clientsService: ClientsService,
    private studyService: StudyService
  ) {
  }

  ngOnInit(): void {
    const study = this.studyService.study$.value;

    setTimeout(() => {
      this.sidenavService.loading(true, true);

      this.clientsService.all(study).subscribe(res => {
        this.clients = res;
        this.sidenavService.loading(false);
      });

    });
  }


}
