import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ClientsFilterComponent } from './clients-filter/clients-filter.component';
import { MaterialModule } from '@compass/material';
import { MapModule } from '@compass/map';
import { CrossfilterModule } from '@compass/crossfilter';
import { ReactiveFormsModule } from '@angular/forms';
import { FormModule } from '@compass/form';

@NgModule({
  imports: [
    CommonModule,
    MaterialModule,
    MapModule,
    CrossfilterModule,
    ReactiveFormsModule,
    FormModule
  ],
  declarations: [
    ClientsFilterComponent
  ],
  exports: [
    ClientsFilterComponent
  ]
})
export class ClientsModule {
}
