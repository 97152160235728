import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';

@Component({
  selector: 'lab-category-nested-list',
  templateUrl: './category-nested-list.component.html',
  styleUrls: ['./category-nested-list.component.scss']
})
export class CategoryNestedListComponent implements OnInit {
  @Input() listData: any;
  @Input() levels: any = null; // array of each level callback function to extract the value of it
  @Input() elementHtml: any = null;

  @Output() selectedElements = new EventEmitter<any>();

  allSelected: boolean = false;
  fullNestedList: any = {};
  actualList: any = {};

  navigation: any = []; // levels navigation
  someSelected: boolean = false;

  ngOnInit(): void {
    if (!this.levels) {
      throw new Error('levels attribute required in nested list component');
    }

    if (!this.elementHtml) {
      throw new Error('elementHtml attribute required in nested list component');
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    this.generateList();
  }

  private generateList() {
    this.listData.map(data => {
      let parentLevel = this.fullNestedList;

      this.levels.forEach((level, i) => {
        let levelName = level(data);

        if (i == 0) {
          parentLevel = parentLevel || {};
          parentLevel[levelName] = parentLevel[levelName] || {
            selected: false,
            children: {},
            parent: parentLevel
          };
        } else {
          parentLevel.children = parentLevel.children || {};
          parentLevel.children[levelName] = parentLevel.children[levelName] || {
            selected: false,
            parent: parentLevel
          };
        }

        // check if last iteration
        if (i + 1 == this.levels.length) {
          parentLevel.children[levelName].data = data;
        }

        // set the parent node for next elements
        parentLevel = parentLevel[levelName] || parentLevel.children[levelName];
      });
    });

    this.actualList = this.fullNestedList;
  }

  filter(node = null) {
    this.checkIfAnySelected();

    // emit the selected
    let flatNodes = this.getFlatList().filter(el => el.selected);
    this.selectedElements.emit(flatNodes);
  }

  nextLevel(item) {
    this.navigation.push({
      key: item.key,
      parent: item.value.parent
    });

    this.actualList = item.value.children;

    this.checkIfAnySelected();
  }

  backLevel() {
    let item = this.navigation.pop();
    this.actualList = item.parent.children || this.fullNestedList;
    this.checkIfAnySelected();
  }

  /**
   * Check if any element in tree is selected
   */
  checkIfAnySelected(node = null) {
    this.someSelected = false;

    node = node || this.actualList;

    if (node) {
      Object.values(node).forEach((value: any) => {
        if (value.selected) {
          this.someSelected = true;
          return;
        }
      });
    }

    return false;
  }

  toggleChildren(node = null, parentValue = null) {
    node = node ? node.children : this.fullNestedList;

    if(node) {
      Object.values(node).forEach((val: any) => {
        if(parentValue !== null) {
          val.selected = parentValue;
        } else {
          val.selected = this.allSelected || parentValue || val.selected;
        }

        if (val.children) {
          this.toggleChildren(val, parentValue);
        }
      });
    }
  }

  toggleAllInList() {
    console.log('togle all in list', this.actualList);
    Object.values(this.actualList).forEach(node => {
      this.toggleChildren(node, this.allSelected);
    })
    this.filter();
  }

  toggleOne(node) {
    this.toggleChildren(node, node.selected);
    this.filter();
  }


  /**
   * Returns the flat list.
   *
   * @param nodes
   */
  getFlatList(nodes = null) {
    let list = [];

    nodes = nodes || this.fullNestedList;

    Object.values(nodes).forEach((node: any) => {
      // if has data is original data
      if (node.data) {
        list.push({
          ...node.data, ...{
            selected: node.selected // add selected
          }
        });
      } else {
        // if has children, should flat those
        if (node.children) {
          let childList = this.getFlatList(node.children);
          if (childList.length) {
            list = list.concat(childList);
          }
        }
      }
    });

    return list;
  }

}
