import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';
import { Observable } from 'rxjs/Observable';

@Injectable({ providedIn: 'root' })
export class EgmAimcStatusService {
  public egmOn$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public aimcOn$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );
  public surveyType$: BehaviorSubject<any> = new BehaviorSubject<any>(
    undefined
  );
  public surveyTarget$: BehaviorSubject<any> = new BehaviorSubject<any>(
    undefined
  );

  constructor() {}

  setEgmOn(status) {
    if (this.egmOn$.value !== status) {
      this.egmOn$.next(status);
    }
    return false;
  }
  getEgmOn(): Observable<boolean> {
    return this.egmOn$.asObservable();
  }
  setAimcOn(status) {
    if (this.aimcOn$.value !== status) {
      this.aimcOn$.next(status);
    }
    return false;
  }
  getAimcOn(): Observable<boolean> {
    return this.aimcOn$.asObservable();
  }

  setSurveyType(type) {
    this.surveyType$.next(type);
  }

  setSurveyTarget(type) {
    this.surveyTarget$.next(type);
  }
}
