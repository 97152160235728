import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CompassMapDrawerService } from './compass-map-drawer.service';
import { CompassMapWrapperService } from './compass-map-wrapper.service';
import { CompassMapMarkerService } from './compass-map-marker.service';
import { CompassMapPoisService } from './compass-map-pois.service';
import { CompassMapIdealistaService } from './compass-map-idealista.service';
import { CompassMapHeritageService } from './compass-map-heritage.service';
import { IndicatorService } from '../../../../utils/navigation/src/lib/indicator.service';
import { CompassMapIndicatorsService } from './compass-map-indicators.service';
import { MapColorScaleComponent } from './map-color-scale/map-color-scale.component';
import { CompassCatastroLayersService } from './compass-catastro-layers.service';
import { CompassTopoLayersService } from './compass-topo-layers.service';
import { FeatureInfoWMSService } from './featureInfoWMS.service';

@NgModule({
  imports: [CommonModule],
  providers: [
    CompassMapDrawerService,
    CompassMapWrapperService,
    CompassMapMarkerService,
    CompassMapPoisService,
    CompassMapIdealistaService,
    CompassMapHeritageService,
    CompassMapIndicatorsService,
    CompassCatastroLayersService,
    CompassTopoLayersService,
    FeatureInfoWMSService,
    IndicatorService
  ],
  declarations: [MapColorScaleComponent],
  exports: [MapColorScaleComponent],
})
export class UtilsLeafletModule { }
