import { Injectable } from '@angular/core';
import * as L from 'leaflet';
import 'leaflet.markercluster';
import 'leaflet.heat';
import { Map } from './compass-map-wrapper.service';
import { Poi, SidenavPoisLevelsService } from '@compass/pois/data-access-poi';
import { HasEvents } from '../../../../map/src/lib/classes/has-events';

// http://osiris-indoor.github.io/tutorial/2016/06/16/Adding-and-Showing-Points-of-Interest.html

@Injectable({ providedIn: 'root' })
export class CompassMapPoisService extends HasEvents {
  _events: any = ['mouseout', 'click', 'mouseover'];

  studyFormNavOn: boolean;
  stagesNavOn: boolean;
  map: Map;
  activeTableRoot: string;

  constructor(private sidenavPoisLevelsService: SidenavPoisLevelsService) {
    super();

    this.sidenavPoisLevelsService
      .getActiveTableRoot()
      .subscribe((d: string) => {
        this.activeTableRoot = d;
      });
  }

  public setMap(map: Map) {
    this.map = map;

    return this;
  }

  public removeLayer(layer): void {
    if (this.map && this.map.pois[this.activeTableRoot]) {
      this.map.box.removeLayer(this.map.pois[this.activeTableRoot]);
    } else if (this.map && this.map.pois[layer]) {
      this.map.box.removeLayer(this.map.pois[layer]);
    }
  }

  public fitBoundsCategoryGroup(): void {
    if (this.map['pois'][this.activeTableRoot]) {
      let bounds = this.map['pois'][this.activeTableRoot].getBounds();
      if (bounds && bounds._northEast && bounds._northEast.lng && bounds._southWest && bounds._southWest.lat) {
        this.map.box.fitBounds(bounds);
      }
    }
  }

  public setViewToMapCenter(): void {
    this.map.box.setView(
      new L.LatLng(
        this.map.properties.center[0],
        this.map.properties.center[1]
      ),
      this.map.properties.zoom
    );
  }

  /**
   * setFlatPoisFromNestedList: Converts the tree list of categories to an array of regular POIS.
   *
   * @param list
   */
  public setFlatPoisFromNestedList(list: any): Array<Poi> {
    const validTableRoot = ['local_pois', 'transport', 'heritage'];
    let points = [];

    if (validTableRoot.includes(this.activeTableRoot)) {
      list.forEach((poi) => {
        poi['values'] &&
        poi.values.forEach((place) => {
          if (place.selected) {
            points.push(place);
          }
        });
      });
    } else {
      list.forEach((poi) => {
        poi['values'] &&
        poi.values.forEach((subpois) => {
          subpois['values'] &&
          subpois.values.forEach((companies) => {
            companies['values'] &&
            companies.values.forEach((place) => {
              if (place.selected) {
                points.push(place);
              }
            });
          });
        });
      });
    }

    return points;
  }

  public buildIconMarkers(pois: any) {
    return pois.map((poi) => {
      const lat: number = poi.geometry.coordinates[1];
      const long: number = poi.geometry.coordinates[0];


      let marker;

      if (poi.properties && poi.properties.fillColor) {
        marker = {
          circleMarker: L.circleMarker([lat, long], {
            color: '#333333', // border color
            weight: 1, // border weight in px
            fillColor: poi.properties.fillColor || '#0055FF', // circle color
            fillOpacity: 0.8,
            radius: 8, // Radius of the circle marker, in pixels
            className: 'poi-circle'
          }),
          popUp: poi.popUpText ? poi.popUpText(poi) : this.popUpText(poi)
        };

        let timeout;

        marker.circleMarker.on('mouseover', (e) => {
          this.fireEvent('mouseover', poi);
          clearTimeout(timeout);
          timeout = setTimeout(() => {
            e.sourceTarget.openPopup();
          }, 600)
        });

        marker.circleMarker.on('mouseout', (e) => {
          this.fireEvent('mouseout', poi);
          clearTimeout(timeout);

          if (poi.dontCloseOnMouseOut) {
            poi.dontCloseOnMouseOut = false;
          } else {
            e.sourceTarget.closePopup();
          }
        });

        marker.circleMarker.on('click', (e) => {
          this.fireEvent('click', poi);
          e.sourceTarget.openPopup();
          poi.dontCloseOnMouseOut = true;
        });

      } else {
        poi.markerOptions.iconUrl = poi.markerOptions?.iconUrl || 'assets/marker/blue_dot';

        marker = {
          actualMarker: L.marker([lat, long], {
            draggable: false,
            icon: L.divIcon({
              iconSize: poi.markerOptions?.iconSize || [24, 24],
              iconAnchor: poi.markerOptions?.iconAnchor || [24, 24],
              popupAnchor: poi.markerOptions?.popupAnchor || [-4, -24],
              html: `<img width="24" src="${poi.markerOptions?.iconUrl}.svg" onerror="this.onerror=null;this.src='${poi.markerOptions?.iconUrl}.png'">`,
              className: 'abacus-poi-marker'
            })
          }),
          popUp: poi.popUpText ? poi.popUpText(poi) : this.popUpText(poi)
        };
      }


      return marker;
    });
  }

  public addMarkersToMap(markers: any, layer: string) {
    markers.map((m) => {
      if (m.actualMarker) {
        m.actualMarker.addTo(this.map.pois[layer]).bindPopup(m.popUp);
      } else {
        m.circleMarker.addTo(this.map.pois[layer]).bindPopup(m.popUp);
      }
    });
  }

  /**
   * drawFlatPoisLayer: main function drawer: It draws the pois by layer
   *
   * Layer is the stage we are (global pois, local pois, transport, heritage)
   * Markerclusters only on Global pois
   * @param pois: flat array of pois.
   * @param layer: Layer
   * @return void
   */
  getHeatMapData(pois) {
    let locations = [];
    let data = pois.forEach((poi) => {
      let coords = poi.geometry.coordinates;
      let location = coords.slice().reverse();
      let intensity = 1;
      location.push(intensity);
      locations.push(location);
    });
    return locations;
  }

  public drawFlatPoisLayer(pois: any, layer: string, layerGroup: string) {
    switch (layerGroup) {
      case 'featureGroup': {
        this.map.pois[layer] = L.featureGroup([]);
        const markers = this.buildIconMarkers(pois);
        this.addMarkersToMap(markers, layer);
        this.map.pois[layer].addTo(this.map.box);
      }
        break;

      case 'markerClusterGroup': {
        this.map.pois[layer] = L.markerClusterGroup();
        const markers = this.buildIconMarkers(pois);
        this.addMarkersToMap(markers, layer);
        this.map.box.addLayer(this.map['pois'][layer]);
      }
        break;
      case 'heatMapGroup': {
        this.map['locations'] = this.getHeatMapData(pois);
        this.map['heatLayer'] = {
          radius: 24,
          blur: 16,
          maxZoom: 8,
          gradient: {
            0.0: '#3e4a89',
            0.5: '#1f9e89',
            1.0: '#fde725'
          }
        };

        this.map['pois'][layer] = L.heatLayer(
          this.map['locations'],
          this.map['heatLayer']
        ).addTo(this.map.box);
      }
        break;
      default: {
        return null;
      }
    }
  }

  public drawMarkers(pois: any, layer: string = 'poisLayer', map: Map = undefined) {
    this.map = map || this.map;
    this.removeLayer(layer);

    // if the map isset (delay on init componente)
    if (this.map) {
      this.map.pois[layer] = L.featureGroup([]);
      const markers = this.buildIconMarkers(pois);

      this.addMarkersToMap(markers, layer);
      this.map.pois[layer].addTo(this.map.box);
    }
  }

  /**
   * drawMarkersFromNestedList: set flat pois from nested lists and pass the array to the draw function.
   *
   * @param map
   * @param list
   * @param layerGroup
   */
  public drawMarkersFromNestedList(map: any, list: any, layerGroup: string) {
    this.map = map;

    this.removeLayer(this.activeTableRoot);

    let pois = this.setFlatPoisFromNestedList(list);
    this.drawFlatPoisLayer(pois, this.activeTableRoot, layerGroup);
  }

  public popUpText(poi) {
    let text = '<p data-id="popUpText"><strong>' + poi.name + '</strong></p>';
    if (poi.properties && poi.properties.direccion) {
      text += '<p>' + poi.properties.direccion + '</p>';
    }

    if (poi && poi.properties && poi.properties.descripcion) {
      text += '<p>' + poi.properties.descripcion + '</p>';
    }

    if (poi && poi.properties && poi.properties.valoracion) {
      text += '<p>Valoración: ' + poi.properties.valoracion + '</p>';
    }

    if (poi && poi.properties && poi.properties.puntuacion) {
      text += '<p>Puntuación: ' + poi.properties.puntuacion + '</p>';
    }

    if (poi && poi.properties && poi.properties.impactos) {
      text += '<p>Impactos: ' + poi.properties.impactos.toLocaleString();
      +'</p>';
    }

    if (poi && poi.properties && poi.properties.individuos) {
      text += '<p>Individuos: ' + poi.properties.individuos.toLocaleString();
      +'</p>';
    }

    return text;
  };
}
