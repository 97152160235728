import { Component, OnInit, Input } from '@angular/core';
import { StartTightSidenavService } from './start-tight-sidenav.service';
@Component({
  selector: 'compass-start-tight-sidenav',
  templateUrl: './start-tight-sidenav.component.html',
  styleUrls: ['./start-tight-sidenav.component.scss']
})
export class StartTightSidenavComponent implements OnInit {
  @Input() setUp;
  sidenavOpen: boolean = false;

  constructor(private StartTightSidenavService: StartTightSidenavService) {

    this.StartTightSidenavService.getIsOpen().subscribe((d) => {
      this.sidenavOpen = d;
    })

  }

  closeNav(event) {
    this.StartTightSidenavService.setIsOpen(false);

    return false;
  }
  ngOnInit(): void {

  }


}
