import { Component, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UserService } from '../user.service';

@Component({
  selector: 'user-detail',
  templateUrl: './user-detail.component.html',
  styleUrls: ['./user-detail.component.scss'],
})
export class UserDetailComponent {
  @Input('closeUrl') closeUrl: string = '/usuarios';

  user$ = this.userService.user$;
  icon_class: string = 'icon-usuarios';

  constructor(private route: ActivatedRoute, private userService: UserService) {
    this.userService.clearUser();
  }

  ngAfterViewInit() {
    this.route.paramMap.subscribe((params) => {
      const username = params.get('username') || params.get('id');
      if (username) {
        this.userService.getByUsername(username);
      }
    });
  }
}
