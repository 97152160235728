import { Component, OnInit } from '@angular/core';
import { PoisService } from '@compass/pois/data-access';
import { MapService } from '@compass/map';
import { FormControl } from '@angular/forms';
import { StudyService } from '@compass/studies';
import { SidenavService } from '@compass/page-navigation/sidenav';

@Component({
  selector: 'lab-global-pois-menu',
  templateUrl: './global-pois-menu.component.html',
  styleUrls: ['./global-pois-menu.component.scss']
})
export class GlobalPoisMenuComponent implements OnInit {
  accordionSetup = {
    title: "Grupos/POI's",
    opened: true,
    closed: false,
    expanded: true,
  };

  study: any;

  selectedLayer = new FormControl('featureGroup');
  layerOptions = [
    { key: 'featureGroup', value: 'Puntos de interés' },
    { key: 'markerClusterGroup', value: 'Clústers de puntos' },
    { key: 'heatMapGroup', value: 'Mapa de calor' },
  ];

  pois: any = [];
  filteredPois: any[] = [];

  nestedListLevelsName = [
    (data) => data.properties.categoria,
    (data) => data.properties.sub_categoria,
    (data) => data.properties.nombre,
  ]

  constructor(
    private poisService: PoisService,
    private studyService: StudyService,
    private mapService: MapService,
    public sidenavService: SidenavService
  ) {
    this.studyService.study$.subscribe(study => {
      this.study = study;
      this.loadPois();
    });
  }


  ngOnInit(): void {

  }

  loadPois() {
    if(this.study) {
      this.sidenavService.loading(true, true);

      this.poisService.allInStudy(this.study.properties.id)
        .subscribe((pois: any) => {
          this.pois = pois;
          this.sidenavService.loading(false);

        });
    }
  }

  nestedListElementHtml = (data) => {
    return `
      <p><strong>${data.properties.nombre}</strong></p>
      <p>${data.properties.direccion}</p>
    `;
  }

  onPoisSelected(selectedPois) {
    this.filteredPois = selectedPois;
    this.drawPois();
  }

  drawPois() {
    this.filteredPois.map(poi => {
      // draw popupText
      poi.popUpText = (() => {
        let popUpHtml = `
          <p><strong>${poi.properties.nombre}</strong></p>
          <p>${poi.properties.direccion}</p>
        `;

        return popUpHtml;
      });
    });

    this.mapService.drawPois(this.filteredPois, 'global_pois', this.selectedLayer.value);
  }

}
