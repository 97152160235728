import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpClient } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';
import { Observable, of } from 'rxjs';
import { Router } from '@angular/router';
import { catchError, map } from 'rxjs/operators';

export interface GeometryInterface {
  type: string;
  properties: any;
  geometry: any;
}

@Injectable({ providedIn: 'root' })
export class BricksApiService {
  // dev urls
  //private fullStagesUrl = 'assets/data/indicators/query_bricks_by_area_821.json';
  private fullStagesUrl = '/abacusgis/core/lab/collection/area_brick_hexagon_medium';

  // PRO SERVICES
  public bricks$: BehaviorSubject<any> = new BehaviorSubject(undefined);
  public areaPortals$: BehaviorSubject<any> = new BehaviorSubject(undefined);
  public areaTransit$: BehaviorSubject<any> = new BehaviorSubject(undefined);

  public localMedia$: BehaviorSubject<any> = new BehaviorSubject(undefined); // to be done

  public fullStages$: BehaviorSubject<any> = new BehaviorSubject<any>(
    undefined
  );
  public dataSB: any;
  public study: any;

  constructor(public http: HttpClient, private router: Router) {
  }

  // PRO API
  handleError(error: HttpErrorResponse) {
    const kk = null;
    return of(kk);
  }

  // MAIN API:
  public getDataSB() {
    return this.dataSB;
  }

  public async setDataSB(data: any) {
    this.dataSB = data;
  }

  getById(id: number) {
    return new Observable((observer) => {
      let watchId: number;

      if (!this.bricks$.value) {
        this.getData();
      } else {
        if (this.bricks$.value.length) {
          this.bricks$.value.forEach(brick => {
            if (brick.properties.id === id) {
              observer.next(brick);
            }
          });
        }
      }

      return {
        unsubscribe() {
          navigator.geolocation.clearWatch(watchId);
        }
      };
    });
  }

  async getAreaName(data, route) {
    if (data && data.properties) {
      const areasData = data.properties[route];
      if (!areasData) {
        if (data.properties.areas) {
          // initialize bricks_areas
          if (!this.dataSB.properties?.bricks_areas?.length) {
            this.dataSB.properties.bricks_areas = [];
          }

          // can be multiple areas in case of MultiPolygon
          await this.getFullStagesData(data).subscribe((data) => {
            this.dataSB.properties.bricks_areas = this.dataSB.properties.bricks_areas.concat(data);
          });
        }
      }

      return areasData ? Object.keys(areasData)[0] : '';
    } else {
      throw 'Glocally Error => data or data.properties don´t exists';
    }
  }

  filterBricksAreas(data, bricksAreasName) {
    if (bricksAreasName) {
      if (data?.properties?.bricks_areas?.[bricksAreasName]?.length) {
        let bricks = data['properties'].bricks_areas[bricksAreasName]?.filter((brick) => {
          return brick.properties?.data_portals2019 && brick.geometry?.type === 'Polygon';
        }) ?? [];

        return bricks;
      } else {
        throw (
          'Glocally Error => bricks_areas don´t exists or area ' +
          bricksAreasName +
          'is not accesible'
        );
      }
    }
  }

  getAreaProperties(data, areasName) {
    if (data.properties.areas && data.properties.areas[areasName]) {
      let area_properties = data.properties.areas[areasName]?.properties;
      return area_properties;
    } else {
      throw (`Glocally Error => data.properties don´t exists or area ${areasName} is not accesible`);
    }
  }

  public async getData() {
    let data = this.getDataSB();
    let bricksAreasName: string = await this.getAreaName(data, 'bricks_areas');
    let areasName: string = await this.getAreaName(data, 'areas');
    // properties --> BRICK_AREAS
    let bricks: any = this.filterBricksAreas(data, bricksAreasName);
    // properties --> AREAS
    let area_properties: any = this.getAreaProperties(data, areasName);

    // SET ALL THE DATA
    this.bricks$.next(bricks);
    try {
      if (area_properties?.data_portals2019) {
        this.areaPortals$.next(area_properties.data_portals2019);
      }
    } catch (e) {
      console.error('Glocally Error => It has been imposible to process area_properties on the try catch block', e);

      // SET fullSTAGES data
    } finally {
      this.fullStages$.next(data);
    }
  };

  public getFullStagesData(study) {
    return this.http.get(`${this.fullStagesUrl}?study_id=${study.properties.id}`).pipe(
      catchError(this.handleError),
      map((data: any) => {
        let bricks = data;
        this.bricks$.next(bricks);

        return data;
      })
    );
  };

  // PRO API

  public resetAllBrickLists() {
    this.fullStages$.next(undefined);
    this.bricks$.next(undefined);
    this.areaPortals$.next(undefined);
    this.areaTransit$.next(undefined);
    this.localMedia$.next(undefined);
  }

  public getFullStages(): Observable<any> {
    return this.fullStages$.asObservable();
  }

  public getFullBricks(): Observable<any> {
    return this.bricks$.asObservable();
  }

  public getAreaTransit(): Observable<any> {
    return this.areaTransit$.asObservable();
  }

  public getAreaPortals(): Observable<any> {
    return this.areaPortals$.asObservable();
  }

  //  // to be done --> localmediaApiService
  public getLocalMedia() {
    return this.localMedia$.asObservable();
  }

  public setLocalMedia(media) {
    this.localMedia$.next(media);
  }

  public getCurrentStudyGeometry() {
    const currentStudy  = this.fullStages$.value
    const areaValue = Object.entries(currentStudy.properties.areas)[0][1] as GeometryInterface;
    const geometry = JSON.stringify(areaValue.geometry);

    return geometry
  }
}
