import { Component, OnInit } from '@angular/core';
import { SidenavService } from '../../../../../../libs/page-navigation/sidenav/src/lib/sidenav.service';
import { SidenavConfig } from '../../../../../../libs/page-navigation/sidenav/src/lib/interfaces/sidenav-config';
import { MainMenuComponent } from '../components/main-menu/main-menu.component';
import { SidenavOption } from '../../../../../../libs/page-navigation/sidenav/src/lib/interfaces/sidenav-option';
import { StudiesListComponent } from '../../studies/studies-list/studies-list.component';

@Component({
  selector: 'lab-sidenav',
  templateUrl: './lab-sidenav.component.html',
  styleUrls: ['./lab-sidenav.component.scss']
})
export class LabSidenavComponent implements OnInit {

  // nav configuration
  navSetup: SidenavConfig = {
    class: 'stores',
    mode: 'over',  // 'over', 'push' or 'side'
    opened: true,
    autoFocus: false,
    disableClose: true,
    hasBackdrop: false,
  };

  mainMenu = MainMenuComponent;

  menuOptions: Array<SidenavOption> = [
    /*{
      title: 'Un componente dummy',
      route: '',
      component: MainMenuComponent
    },*/
    {
      title: 'Todos los estudios',
      activeClose: false,
      component: StudiesListComponent
    },
    /*{
      title: 'Datos movilidad',
      route: '/mobility',
      activeClose: true,
    },
    {
      title: 'Ecuacion',
      route: '/ecuacion',
      activeClose: true,
    },
    {
      title: 'Business',
      route: '/business',
      activeClose: true,
    }*/
  ];

  constructor(
    private sidenavService: SidenavService,
  ) {
    this.sidenavService.setMenu(this.menuOptions)
  }

  ngOnInit(): void {
    this.sidenavService.showNavbarButton();
  }

}
