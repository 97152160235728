import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { StartTightSidenavService } from '../start-tight-sidenav/start-tight-sidenav.service';
import { BusinessViewerService } from '../../../../../business/src/lib/business-viewer.service';
@Component({
  selector: 'compass-start-tight-menu',
  templateUrl: './start-tight-menu.component.html',
  styleUrls: ['./start-tight-menu.component.scss']
})
export class StartTightMenuComponent implements OnInit {
  @Input() setup;


  constructor(
    private businessViewerService: BusinessViewerService,
    private StartTightSidenavService: StartTightSidenavService) { }

  openLateralSidenav(e) {
    this.StartTightSidenavService.setIsOpen(true);
    return false;
  }

  goTo(event, action) {
    event.stopPropagation();
    event.preventDefault();

    switch (action) {
      case 'filter': {
        this.openLateralSidenav(event);
      }
        break;
      case 'map': {
        this.businessViewerService.setMapIsOn(true);

      }
        break;
      case 'table': {
        this.businessViewerService.setMapIsOn(false);

      }
        break;

      case 'indicators': {
        this.businessViewerService.setMapIsOn(true);
        this.openLateralSidenav(event);

      }
        break;



      default: {
        return false;
      }
    }
    return false;
  }
  ngOnInit(): void {
  }

}
