import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AutocompleteComponent } from './form/autocomplete/autocomplete.component';
import { ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from '@compass/material';

@NgModule({
  imports: [
    CommonModule,
    MaterialModule,
    ReactiveFormsModule
  ],
  declarations: [
    AutocompleteComponent
  ],
  exports: [
    AutocompleteComponent
  ]
})
export class FormModule {}
