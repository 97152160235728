import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MapComponent } from './components/map/map.component';
import { StudyService } from '@compass/studies/data-access-study';
import {
  ColorsService,
  MapBoxService,
  Mathematics,
  ScrollIntoViewService,
  ThematicKeySymbol,
  ZIndexService
} from '@compass/utils/misc';
import {
  CompassMapDrawerService,
  CompassMapIndicatorsService,
  CompassMapMarkerService,
  CompassMapWrapperService
} from '@compass/utils/leaflet';
import { IndicatorService, SideNavStatusService } from '@compass/utils/navigation';
import { FeatureSearchboxModule, SearchboxService } from '@compass/feature-searchbox';
import { BricksApiService } from '@compass/brick-api';

@NgModule({
  imports: [CommonModule, FeatureSearchboxModule],
  declarations: [MapComponent],
  exports: [MapComponent],
  providers: [
    MapBoxService,
    ColorsService,
    ZIndexService,
    CompassMapWrapperService,
    CompassMapDrawerService,
    SideNavStatusService,
    CompassMapMarkerService,
    Mathematics,
    ScrollIntoViewService,
    ThematicKeySymbol,
    SearchboxService,
    BricksApiService,
    IndicatorService,
    CompassMapIndicatorsService,
    StudyService
  ]
})
export class MapModule {
}
