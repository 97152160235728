import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { BehaviorSubject, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { Role } from '@compass/shared/feature-auth';

export class User {
  username: string;
  email?: string;
  role?: Role;
  password?: string;
  access_token?: string;
}

@Injectable()
export class UserService {
  private usersUrl = '/api/users';
  public users$ = new BehaviorSubject(undefined);
  public user$ = new BehaviorSubject(undefined);

  constructor(private http: HttpClient) {}

  getAll() {
    return this.http
      .get(this.usersUrl)
      .pipe(
        map((users: any[]) =>
          users.map((user) => {
            // needed in table-management
            user.id = user.username;
            return user;
          })
        )
      )
      .subscribe((users: User[]) => {
        return this.users$.next(users);
      });
  }

  // real get by username method
  getByUsername(username: string) {
    return this.http
      .get(`${this.usersUrl}/${username}`)
      .pipe(map((user: User) => user))
      .subscribe((user: User) => {
        return this.user$.next(user);
      });
  }

  // getByUsername(username: string) {
  //   const user = this.users$.value.find((u) => u.username === username);
  //   return this.user$.next(user);
  // }

  clearUser() {
    this.user$.next(undefined);
  }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` + `body was: ${error.error}`
      );
    }
    // return an observable with a user-facing error message
    return throwError('Something bad happened; please try again later.');
  }

  deleteUser(username: string) {
    this.users$.next(undefined);
    return this.http
      .delete<User>(`${this.usersUrl}/${username}`)
      .subscribe((d: any) => {
        this.getAll();
      });
  }

  addUser(user: User) {
    this.users$.next(undefined);
    return this.http
      .post<User>(`${this.usersUrl}`, user)
      .pipe(catchError(this.handleError));
  }

  updateUser(username: string, user: User) {
    this.users$.next(undefined);
    return this.http
      .put<User>(`${this.usersUrl}/${username}`, user)
      .pipe(catchError(this.handleError));
  }
}
