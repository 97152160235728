<form
  [formGroup]="newUserForm"
  class="full-width clearfix newUserForm"
  (ngSubmit)="onSubmit()"
>
  <mat-form-field class="margin__top--l">
    <mat-label>Rol de usuario</mat-label>
    <mat-select formControlName="role" required>
      <mat-option value="reader">Lector</mat-option>
      <mat-option value="writer">Creador</mat-option>
      <mat-option value="superuser">Superusuario</mat-option>
      <mat-option value="admin">Administrador</mat-option>
    </mat-select>
    <mat-error *ngIf="newUserForm.get('role').hasError('required')">
      El rol es <strong>obligatorio</strong>
    </mat-error>
  </mat-form-field>

  <mat-form-field data-cy="user-name" class="margin__top--l">
    <input
      matInput
      type="text"
      placeholder="Nombre del usuario"
      formControlName="username"
      required
    />
    <mat-error *ngIf="newUserForm.get('username').hasError('required')">
      El nombre de usuario es <strong>obligatorio</strong>
    </mat-error>
  </mat-form-field>

  <mat-form-field data-cy="user-email" class="margin__top--l">
    <input
      matInput
      type="email"
      placeholder="Correo electrónico"
      formControlName="email"
      required
    />
    <mat-error
      *ngIf="
        newUserForm.get('email').hasError('email') &&
        !newUserForm.get('email').hasError('required')
      "
    >
      Por favor, introduce un email válido
    </mat-error>
    <mat-error *ngIf="newUserForm.get('email').hasError('required')">
      El email es <strong>obligatorio</strong>
    </mat-error>
  </mat-form-field>

  <mat-form-field class="margin__top--l" *ngIf="!user">
    <input
      matInput
      type="password"
      placeholder="Password"
      formControlName="password"
      required
    />
    <mat-error
      *ngIf="
        newUserForm.get('password').hasError('minlength') &&
        !newUserForm.get('password').hasError('required')
      "
    >
      Por favor, introduce una contraseña de al menos 8 dígitos
    </mat-error>
    <mat-error *ngIf="newUserForm.get('password').hasError('required')">
      La contraseña es <strong>obligatoria</strong>
    </mat-error>
  </mat-form-field>

  <div fxLayout="row" fxLayoutAlign="center center" class="margin__top--xl">
    <div class="margin__right--s">
      <button
        mat-flat-button
        class="button--xl lightgray"
        type="reset"
        (click)="resetForm($event)"
      >
        <i class="material-icons sub-icons small"> refresh </i>
        Reiniciar
      </button>
    </div>
    <div>
      <button mat-flat-button color="primary" class="button--xl" type="submit">
        <i class="material-icons sub-icons small"> beenhere </i>
        Guardar
      </button>
    </div>
  </div>
</form>
