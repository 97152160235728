import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Observable } from 'rxjs/Observable';
import * as L from 'leaflet';
import classyBrew from 'classybrew';
import { CompassMapWrapperService, Map } from './compass-map-wrapper.service';
import { BricksApiService } from '@compass/brick-api';
import { IndicatorService } from '@compass/utils/navigation';
import { EndRightSidenavService } from '../../../../page-navigation/end-right-sidenav/src/lib/end-right-sidenav.service';
import { SideNavIndicatorsService } from '@compass/utils/navigation';
import { Indicator } from '@compass/utils/navigation';
import { CompassMapDrawerService } from './compass-map-drawer.service';
import { ColorsService } from '@compass/utils/misc';
import { Mathematics } from '@compass/utils/misc';
import { ThematicKeySymbol } from '@compass/utils/misc';
import { OnclickStageShapeService } from '@compass/utils/navigation';

@Injectable({
  providedIn: 'root'
})
export class CompassMapIndicatorsService {
  private activeIndicator: Indicator;
  public map: Map;
  public map$: BehaviorSubject<Map> = new BehaviorSubject<Map>(null);

  public bricks;
  public propertiesDB: string = 'data_portals2019';

  public activeIndicator$: BehaviorSubject<Indicator> = new BehaviorSubject<Indicator>(undefined);
  public brickEventToBounce$: BehaviorSubject<Event> = new BehaviorSubject<Event>(undefined);

  public activeShape: string;

  setMapCenter = this.compassMapWrapperService.setMapCenter;
  setView = this.compassMapDrawerService.setView;
  fitGeojsonBounds = this.compassMapDrawerService.fitGeojsonBounds;
  removeGeojsonLayer = this.compassMapDrawerService.removeGeojsonLayer;
  addSeparatorsNF = this.mathematics.addSeparatorsNF;
  getKeySymbol = this.thematicKeySymbol.getKeySymbol;
  setAnalyticsIndicators = this.indicatorService.setAnalyticsIndicators;
  mathCeil10 = this.mathematics.MathCeil10;
  mathFloor10 = this.mathematics.MathFloor10;
  dic = this.sideNavIndicatorsService.indicatorsDictionary$.value;

  formIndicators: any;
  brewClasses: number = 6;


  constructor(
    private compassMapWrapperService: CompassMapWrapperService,
    private compassMapDrawerService: CompassMapDrawerService,
    private endRightSidenavService: EndRightSidenavService,
    private bricksApiService: BricksApiService,
    private indicatorService: IndicatorService,
    private colorsService: ColorsService,
    private mathematics: Mathematics,
    private thematicKeySymbol: ThematicKeySymbol,
    private sideNavIndicatorsService: SideNavIndicatorsService,
    private onclickStageShapeService: OnclickStageShapeService
  ) {
    /*this.compassMapWrapperService.map$.subscribe((map: Map) => {
      this.map = map;
    });
    */
    this.bricksApiService.bricks$.subscribe(bricks => {
      this.bricks = bricks;
    });

    this.indicatorService.activeIndicator$.subscribe(active => this.activeIndicator = active);
  }

  public setMap(map) {
    this.map = map;
  }

  /***********************/
  // SCALES
  /***********************/

  // data
  public minMaxIndicatorsScaleExist(): boolean {
    return this.map.minMaxScales.find((x) => x.key === this.activeIndicator['key']);

  }

  public resetMinMaxIndicatorScale(): void {
    if (this.map.minMaxScales && this.map.minMaxScales.length) {
      this.map.minMaxScales = [];
      this.map$.next(this.map);
    }
  }

  public setBrew(series) {
    let seriesLength = series.length === 6 ? 5 : 6;
    let brew = new classyBrew();

    if (series) {
      brew.setSeries(series);
      brew.setNumClasses(seriesLength);
      brew.setColorCode('YlOrRd');
      brew.classify();
    }

    return brew;
  }

  normalizeSeries(series: Array<number>, steps: number, floor: number): Array<number> {
    let l = series.length;
    let p = steps - l;
    let i = 0;
    if (l < this.brewClasses) {
      for (i; i <= p; i++) {
        series.unshift(floor);
      }
    }
    return series;
  }

  public setMinMaxIndicatorScale() {
    let series: Array<number> = [];
    this.bricks = this.bricks.filter((d) => {
      if (Object.keys(d.properties[this.propertiesDB]).length > 0) {
        return d;
      }
    });

    this.bricks.forEach((brick) => {
      series.push(
        brick.properties[this.propertiesDB][this.activeIndicator['grupo']][this.activeIndicator['key']]
      );
    });

    let ceil = Math.max(...series);
    let floor = Math.min(...series);
    let normalizedSeries = this.normalizeSeries(series, this.brewClasses, floor);

    let brew = this.setBrew(normalizedSeries);

    this.map.minMaxScales.push({
      key: this.activeIndicator['key'],
      series: normalizedSeries,
      brew: brew,
      values: {
        ceil: Math.ceil(ceil),
        floor: Math.floor(floor)
      },
      steps: []
    });

    this.map$.next(this.map);
  }

  getMinMaxIndicatorScale() {
    return this.map.minMaxScales.filter((x) => {
      return x.key === this.activeIndicator['key'];
    })[0];
  }

  /***********************/
  // STYLE AND COLORS
  /***********************/

  getColor(d: number): string {
    let brew: any = this.getMinMaxIndicatorScale().brew;
    return brew.getColorInRange(d);
  }

  styling(feature: any, category?: string, indicator?: string): any {
    if (category && indicator) {
      const props = feature.properties[this.propertiesDB];
      if (props[category] && props[category][indicator]) {
        return {
          fillColor: this.getColor(props[category][indicator]),
          weight: 0,
          opacity: 0.7,
          color: this.colorsService.colors.orange,
          dashArray: '0',
          fillOpacity: 0.7
        };
      } else {
        return {
          fillColor: 'transparent',
          weight: 0,
          opacity: 0.7,
          color: this.colorsService.colors.orange,
          dashArray: '0',
          fillOpacity: 0.7
        }
      }
    } else {
      return this.map.area.style.default;
    }
  }

  /***********************/
  // COLOR KEYS
  /***********************/

  drawColorKeys(): void {
    this.map['color_key'] = new L.Control({ position: 'bottomright' });
    this.map['color_key'].onAdd = () => {
      let div: HTMLElement = L.DomUtil.create('div', 'info legend');

      let grades: Array<number> = this.getMinMaxIndicatorScale().brew.breaks;
      let i: number = 0;

      div.innerHTML +=
        '<p class=\'margin--0\'> ' + this.activeIndicator.screen_name + '</p>';
      div.innerHTML +=
        '<p class=\'margin--0\'><small> ' +
        this.activeIndicator.formato +
        '</small></p>';

      for (i; i < grades.length; i++) {
        div.innerHTML +=
          '<i style="background:' +
          this.getColor(grades[i]) +
          '"></i> ' +
          this.addSeparatorsNF(Math.round(grades[i]), '.', ',', '.') +
          this.getKeySymbol(this.activeIndicator['formato']) +
          (grades[i + 1] >= 0
            ? '&nbsp;&ndash;&nbsp;' +
            this.addSeparatorsNF(Math.round(grades[i + 1]), '.', ',', '.') +
            this.getKeySymbol(this.activeIndicator['formato']) +
            '<br>'
            : '+');
      }
      return div;
    };

    this.map['color_key'].addTo(this.map.box);
    this.map$.next(this.map);
  }

  async drawGeojsonLayer() {
    this.map.geojsonLayer = L.geoJSON(this.bricks, {
      style: (feature): any => {
        return this.styling(
          feature,
          this.activeIndicator['grupo'],
          this.activeIndicator['key']
        );
      },
      onEachFeature: this.onEachFeature
    }).addTo(this.map.box);

    this.map.geojsonLayer.bringToBack();
    this.map$.next(this.map);

    return true;
  }

  public filterJson = (feature: any): boolean => {
    if (feature && this.formIndicators) {
      let conditions: Array<boolean> = this.formIndicators.map((f) => {
        const indicatorValue =
          feature.properties[this.propertiesDB][f.grupo]?.[f.indKey];
        if (indicatorValue <= f.maxValue && indicatorValue >= f.minValue) {
          return true;
        }
        return false;
      });
      const finalCondition = conditions.reduce((sum, next) => sum && next, true);

      return finalCondition;
    }

    return false;
  };

  filterGeojsonLayer(indicator: Indicator, form: any): void {
    const dic = this.sideNavIndicatorsService.indicatorsDictionary$.value;
    this.formIndicators = form.value.indicators.map((i) => {
      i.grupo = dic.find((d: any) => d.key === i.indKey)?.grupo;
      return i;
    });
    this.activeIndicator = indicator;
    this.removeGeojsonLayer('geojsonLayer');
    this.removeGeojsonLayer('color_key');
    this.setMapCenter([40.434176, -3.735995]);

    if (!this.minMaxIndicatorsScaleExist()) {
      this.setMinMaxIndicatorScale();
    }

    this.map.geojsonLayer = L.geoJSON(this.bricks, {
      filter: this.filterJson,
      style: (feature): any => {
        return this.styling(feature, this.activeIndicator['grupo'], this.activeIndicator['key']);
      },
      onEachFeature: this.onEachFeature
    }).addTo(this.map.box);
    this.drawColorKeys();
    this.fitGeojsonBounds();
  }

  /***********************/
  // events
  /***********************/

  // string is the property

  setBrickEventToBounce(any) {
    this.brickEventToBounce$.next(any);
  }

  getBrickEventToBounce(): Observable<any> {
    return this.brickEventToBounce$.asObservable();
  }

  onEachFeature(feature: any, layer: any): void {
    if (feature.properties) {
      layer
        .on('mouseover', (e) => {
          if(typeof this.onMouseoverArea === 'function') {
            this.onMouseoverArea(e);
          }
        })
        .on('mouseout', (e) => {
          if(typeof this.onMouseoutArea === 'function') {
            this.onMouseoutArea(e);
          }
        })
        .on('click', (e) => {
          if(typeof this.onClickArea === 'function') {
            this.onClickArea(e);
          }
        });
    }
  };

  // AREAS and EVENTS
  onMouseoverArea(event: Event): void {
    //this.setFeatureStyle(event);
    //this.bringFeatureToFront(event);
    //this.updateInfoControl(event);
  }

  onMouseoutArea(event: Event): void {
    //this.setFeatureStyle(event);
    //this.drawInfoControl();
    //this.updateInfoControl(event);
  }

  onClickArea(event: Event): void {
    this.setBrickEventToBounce({
      brick: event,
      list: this.indicatorService.activeIndicator$.value,
      areaPortals: this.bricksApiService.areaPortals$.value
    });
    this.onclickStageShapeService.setShape('indicators');
    this.endRightSidenavService.setSidenavStatus(true);
  }

  setBricks(bricks) {
    this.bricks = bricks;
  }


}
