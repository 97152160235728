import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { AuthService } from '@compass/shared/feature-auth';
import { catchError, map, tap } from 'rxjs/operators';
import { StudyModel } from './study.model';
import { ApiService } from '../../../shared/api-service/src/lib/api-service';


@Injectable({
  providedIn: 'root'
})
export class StudyService extends ApiService {
  private apiUrl = `/abacusgis/study/${this.NAMESPACE}`;

  public studies$: BehaviorSubject<StudyModel[]> = new BehaviorSubject<StudyModel[]>([]);
  public study$: BehaviorSubject<StudyModel> = new BehaviorSubject(undefined);

  constructor(
    @Inject('environment') environment,
    private http: HttpClient,
    private authService: AuthService
  ) {
    super(environment);
  }

  /**
   * Returns an array of studies
   */
  public getAll() {
    this.studies$.next([]);
    const currentUser = this.authService.currentUser$.value;
    let queryUser = '';

    if (currentUser.role !== 'admin') {
      queryUser = `query={ "properties.study.username" : "${currentUser.username}" }&`;
    }

    const fields = 'properties.id,properties.study.name,properties.study.created,properties.study.username,properties.study.study_id';

    return this.http.get(`${this.apiUrl}/point_study/?${queryUser}fields=${fields}`)
      .pipe(
        map((data: StudyModel[]) => {
          this.studies$.next(data);
          return data;
        }), catchError(error => {
          return throwError('Something went wrong!');
        })
      );
  }

  update(id: any, property_name: any, data: any): Observable<any> {
    const body = `{
      "$set": { "${property_name}" : ${JSON.stringify(data)} }
    }`;
    return this.http
      .put(`${this.apiUrl}/point_study/${id}`, body)
      .pipe(catchError(this.handleError));
  }

  delete(id: number): Observable<any> {
    return this.http.delete(`${this.apiUrl}/point_study/${id}`).pipe(
      tap(x => {
        // if deleted actual study
        if(this.study$.value?.properties?.id === id) {
          this.study$.next(null);
        }
      })
    );
  }

  /**
   * Retrieve
   * @param id
   */
  show(id: any): Observable<any> {
    const req = this.http.get<any>(`${this.apiUrl}/point_study/${id}/`)
      .pipe(map(study => this.study$.next(study)));

    return req;
  }

  public save(model: StudyModel) {
    const body = model.getBody(this.authService.currentUser$.value);
    return this.http.post(`${this.apiUrl}/create/`, body);
  }

  public isActualStudy(study: any) {
    let response = false;
    /*if(study && this.study$.value) {
      response = this.study$.value?.properties.id == study?.properties?.id;
    }*/


    return response;
  }

  handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(`Code returned: ${error.status}, ` + `body was: ${error.error}`);
    }

    // return an observable with a user-facing error message
    return throwError('Something bad happened; please try again later.');
  }
}
