import { AfterViewInit, Component, ElementRef, Input, OnChanges, OnInit, SimpleChange, ViewChild } from '@angular/core';
import { MapSetUp } from '../../interfaces/map-set-up';
import { MapService } from '../../map.service';
import { Map } from '@compass/utils/leaflet';
import { SearchboxService } from '@compass/feature-searchbox';

@Component({
  selector: 'compass-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss']
})
export class MapComponent implements AfterViewInit, OnChanges {
  @ViewChild('map_elementRef', { static: false }) map_elementRef: ElementRef;

  @Input() mapSetUp: MapSetUp;
  @Input() searchboxSetup = {
    class: 'estudio-searchbox',
    id: 'estudio_searchbox',
    width: 380
  };

  map: Map;

  mapVisible: boolean = false
  searchboxVisible: boolean = false;

  constructor(
    private mapService: MapService,
    private searchBoxService: SearchboxService
  ) {

    this.mapService.clickMapChanged$.subscribe((params) => {
      // this.clickOnMap(params);
    });
  }

  /**
   * Main function to initialize basic map
   */
  initMap(): void {
    this.mapService.setMapRef(this.map_elementRef);
    this.mapService.initMap(this.mapSetUp);

    // timeout to let the view compile
    setTimeout(() => {
      // subscribe to service status
      this.mapService.getVisible().subscribe(status => this.mapVisible = status);
      this.searchBoxService.getVisible().subscribe(status => this.searchboxVisible = status);
    }, 100);
  }

  ngOnInit(): void {

  }

  ngOnChanges(changes: { [propName: string]: SimpleChange }) {
    let changeObj: string[] = Object.keys(changes);
  }

  ngAfterViewInit(): void {
    this.initMap();
  }

  ngOnDestroy() {
    this.mapService.destroy();
  }
}
