import { Injectable } from '@angular/core';
import * as L from 'leaflet';


@Injectable({ providedIn: 'root' })
export class TomtomMapService {

  //ttToken:string = 'GleTooveS7f1YX5BYruoqin1Gqtp4dkn'; // juantxo
  ttToken: string = 'dlFoTTdPosACGKoJyFCxS0wyeWnNFf6T'; // alvaro
  ttStyle: string = 'relative0';
  trafficEndpoint: string = 'https://{cyclingHostname}.api.tomtom.com/traffic/map/4/tile/flow/{cyclingHostname}/{z}/{x}/{y}.png?tileSize=512&key=' + this.ttToken;
  maxZoom: number = 20;
  tileSize: number = 512 // 256
  zoomOffset: number = -1

  constructor() { }

  removeGeojsonLayer(map, layer) {
    if (map[layer]) {
      map[layer].remove();
      map[layer] = null;
    }
  }

  getTilesForEndpoint = (endpoint, style) => {
    return ['a', 'b', 'c', 'd'].map((hostname) => {
      return endpoint.replace('{cyclingHostname}', hostname).replace('{cyclingHostname}', style);
    });
  };

  getRandom(val) {
    return Math.floor(Math.random() * val);
  }

  setTomtomTileLayer(map, style) {
    map['ttTileLayer'] = L.tileLayer(this.getTilesForEndpoint(this.trafficEndpoint, style)[this.getRandom(4)], {
      attribution: ' © <a href="https://www.tomtom.com/">Tomtom</a>',
      maxZoom: this.maxZoom,
      id: '',
      tileSize: this.tileSize,
      zoomOffset: this.zoomOffset,
      accessToken: this.ttToken
    }).addTo(map.box);
  }

}
