import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

// ANGULAR UTILS
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

// MATERIAL AND flex
import { MaterialModule } from '@compass/material';

// page wrapper
import { PageWrapperModule } from '@compass/page-structure/page-wrapper';
import { PageWrapperComponent } from '../page-structure/page-wrapper/page-wrapper.component';

// Top toolbar
import { TopToolbarWrapperModule } from '@compass/page-structure/top-toolbar-wrapper';
import { TopToolbarComponent } from '../page-structure/top-toolbar/top-toolbar.component';

// footer
import { PageStructureFooterModule } from '@compass/page-structure/footer';
import { PageFooterComponent } from '../page-structure/page-footer/page-footer.component';


// start left Stores sidenav
import { StartLeftSidenavModule } from '@compass/page-navigation/start-left-sidenav';
import { StartLeftMenuModule } from '@compass/page-navigation/start-left-menu';

// start left business tight menu
import { StartTightMenuModule } from '@compass/page-navigation/start-tight-menu';

// end-rigth menu
import { EndRightSidenavModule } from '@compass/page-navigation/end-right-sidenav';

// business module
import { BusinessModule } from '@compass/business';

// indicators selection module
import { IndicatorsSelectModule } from '@compass/indicators-select';

// indicators sidenav table module
import { IndicatorsModule } from '@compass/indicators-table';
import { PageNavigationSidenavModule } from '../../../../../libs/page-navigation/sidenav/src';
import { LabSidenavComponent } from '../page-navigation/sidenav/lab-sidenav.component';
import { MapModule } from '../../../../../libs/map/src';
import { ClientsModule } from '@compass/clients';

@NgModule({
  declarations: [
    PageWrapperComponent,
    TopToolbarComponent,
    PageFooterComponent,
    LabSidenavComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    RouterModule,
    PageWrapperModule,
    TopToolbarWrapperModule,
    PageStructureFooterModule,
    StartLeftSidenavModule,
    StartLeftMenuModule,
    StartTightMenuModule,
    BusinessModule,
    IndicatorsSelectModule,
    IndicatorsModule,
    EndRightSidenavModule,
    PageNavigationSidenavModule,
    MapModule,
    ClientsModule
  ],
  exports: [
    CommonModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    PageWrapperComponent,
    PageWrapperModule,
    TopToolbarWrapperModule,
    PageStructureFooterModule,
    StartLeftSidenavModule,
    StartLeftMenuModule,
    StartTightMenuModule,
    BusinessModule,
    IndicatorsSelectModule,
    IndicatorsModule,
    EndRightSidenavModule,
    MapModule,
  ],
  providers: [],
  entryComponents: []
})
export class SharedModule {
}
