<div aria-label="Menú principal" style="min-width: 245px;">
    <nav role="navigation">
        <div role="header" class="navigation-header">
            <div class="navigation-header--logo">
                <p class="hidden">
                    {{setup.title}}}
                </p>
            </div>
        </div>
        <div class="sidebar">
            <ul class="nav-ul">
                <li *ngFor="let li of menuItems">
                    <a routerLinkActive="active" (click)="goToPage(li.key)">
                        <span class="section-title--icon {{ li.key }}"></span>
                        <span>{{ li.value }}</span>
                    </a>



                </li>
            </ul>
        </div>
    </nav>
</div>