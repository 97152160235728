import { Injectable } from "@angular/core";

@Injectable()
export class Mathematics {
  constructor() { }
  decimalAdjust(type, value, exp) {
    // Si el exp no está definido o es cero...
    if (typeof exp === "undefined" || +exp === 0) {
      return Math[type](value);
    }
    value = +value;
    exp = +exp;
    // Si el valor no es un número o el exp no es un entero...
    if (isNaN(value) || !(typeof exp === "number" && exp % 1 === 0)) {
      return NaN;
    }
    // Shift
    value = value.toString().split("e");
    value = Math[type](+(value[0] + "e" + (value[1] ? +value[1] - exp : -exp)));
    // Shift back
    value = value.toString().split("e");
    return +(value[0] + "e" + (value[1] ? +value[1] + exp : exp));
  }

  MathRound10 = (value, exp) => {
    return this.decimalAdjust("round", value, exp);
  };
  MathFloor10 = (value, exp) => {
    return this.decimalAdjust("floor", value, exp);
  };
  MathCeil10 = (value, exp) => {
    return this.decimalAdjust("ceil", value, exp);
  };

  currencyFormatES(num: number) {
    return (
      num
        .toFixed(2) // always two decimal digits
        .replace(".", ",") // replace decimal point character with ,
        .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.") + " €"
    ); // use . as a separator
  }

  // @nStr: This is the number to be formatted.This might be a number or a string.No validation is done on this input.
  // @inD : This is the decimal character for the string.This is usually a dot but might be something else.
  // @outD: This is what to change the decimal character into.
  // @sep : This is the separator, which is usually a comma.

  addSeparatorsNF(nStr, inD, outD, sep) {
    nStr += "";
    var dpos = nStr.indexOf(inD);
    var nStrEnd = "";
    if (dpos != -1) {
      nStrEnd = outD + nStr.substring(dpos + 1, nStr.length);
      nStr = nStr.substring(0, dpos);
    }
    var rgx = /(\d+)(\d{3})/;
    while (rgx.test(nStr)) {
      nStr = nStr.replace(rgx, "$1" + sep + "$2");
    }
    return nStr + nStrEnd;
  }
}
