import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';

@Injectable()
export class CryptService {
  constructor() {}

  encryptPassword(password: string) {
    const secret = 'yeKterces';
    const encodedPassword = CryptoJS.AES.encrypt(password, secret).toString();
    const b64encPassword = encodeURIComponent(encodedPassword);
    return b64encPassword;
  }
}
