<section class="margin__top--m">
  <mat-checkbox
    class="example-margin"
    (click)="onClickCheckbox($event)"
    (change)="onChangeCheckbox($event, $event.checked)"
    [checked]="true"
    color="primary"
  >
    {{ checkboxText }}
  </mat-checkbox>
</section>

<section class="margin__top--m">
  <p>Escala de color</p>
  <p><small>Percentiles de 0 a 100</small></p>
  <div></div>
  <div class="percentile" *ngFor="let percentile of percentileScale">
    <div class="color" [style.backgroundColor]="percentile.color"></div>
    <div class="text">{{ percentile.value }}</div>
  </div>
</section>
