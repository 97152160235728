import { Inject, Injectable } from '@angular/core';
import { ApiService } from '@compass/shared/api-service';
import { HttpClient } from '@angular/common/http';
import { PoiBusiness } from '@compass/business';
import { map } from 'rxjs/operators';
import { ClientsInterface } from './interfaces/clients-interface';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ClientsService extends ApiService {
  protected url = `/abacusgis/core/${this.NAMESPACE}/collection/point_ogilvy_companies/`;
  // protected devUrl = `/assets/data/data_ogilvy_companies.json`;

  public categories: BehaviorSubject<any> = new BehaviorSubject<any>([]); // categories extracted from properties.Categoria_[CATEG_NAME]
  public clientTypes = []; // extracted from properties.TipoCliente

  constructor(
    @Inject('environment') environment,
    public http: HttpClient
  ) {
    super(environment);
  }

  public all(study): Observable<any> {
    const url = `${this.url}?study_id=${study.properties.id}`;

    return this.http.get<ClientsInterface[]>(url).pipe(
      map((data: PoiBusiness[]) => {
        return this.formatData(data);
      })
    );
  }

  private formatData(data) {
    return data.map((d: any) => {
      const p = d.properties;

      d.properties.direction = `${p.ViaTipo} ${p.ViaNombre} ${p.ViaNumero}, ${p.PoblacionDesc} ${p.CodigoPostal}, ${p.ProvinciaDesc}`;
      d.properties.ageRange = this.getRange(p.EdadTramo);
      d.properties.token = p.Token || null;
      d.properties.categories = this.getCategories(d.properties);
      d.properties.gender = p.Sexo || null;
      d.properties.fillColor = p.Sexo == 'H'? '#3f50eb' : '#EB3F43';

      return d;
    });
  }

  /**
   * Extract the age range from string.
   *
   * @param string
   * @private
   */
  private getRange(string) {
    // extract numbers from string, example of string: "Entre 40 y 60 años" converted to ["40", "60"]
    let extracted = string.match(/(\d){1,2}/gi);
    // parse to int
    if(extracted) {
      extracted = extracted.map(age => parseInt(age));
    }

    return extracted;
  }


  private getCategories(clientData) {
    let clientCategories = [];

    // foreach attribute
    Object.entries(clientData).forEach(entry => {
      const [key, value] = entry;
      let actualCategories = this.categories.value;
      // check if key has prefix Categoria_
      if (key.includes('Categoria_')) {
        if (value) {
          const category = key.replace('Categoria_', '');
          clientCategories.push(category);
          // if actual category
          if (!actualCategories.includes(category)) {
            actualCategories.push(category);
            this.categories.next(actualCategories);
          }
        }
      }
    });

    return clientCategories;
  }
}
