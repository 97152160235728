import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from '../../../material/src/lib/material.module';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { BusinessFormComponent } from './business-form/business-form.component';
import { BusinessTableComponent } from './business-table/business-table.component';
import { MapModule } from '@compass/map';
import { BusinessReshapeService } from './business.reshape.service';
import { BusinessViewerService } from './business-viewer.service';
import { BusinessApiService } from './business-api.service';
import { BusinessFormOptionsService } from './business-form/business-form-options.service';
import { MapService } from './map/map.service';
import { MapLoaderComponent } from './map-loader/map-loader.component';
import { BusinessMenuComponent } from './business-menu/business-menu.component';
import { BusinessSidenavComponent } from './business-sidenav/business-sidenav.component';
import { BusinessIndicatorsComponent } from './business-indicators/business-indicators.component';
import { IndicatorsSelectModule } from '@compass/indicators-select';
import { IndicatorService } from '@compass/utils/navigation';
import { CompassMapIndicatorsService } from '@compass/utils/leaflet';
import { Mathematics, ThematicKeySymbol } from '@compass/utils/misc';
import { UtilsD3Module } from '@compass/utils/d3';
import { DataModule } from '@compass/data';
import { CrossfilterModule } from '@compass/crossfilter';
import { BusinessFilterComponent } from './business-filter/business-filter.component';
import { FormModule } from '@compass/form';

@NgModule({
  imports: [
    CommonModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    IndicatorsSelectModule,
    UtilsD3Module,
    DataModule,
    MapModule,
    CrossfilterModule,
    FormModule
  ],
  declarations: [
    BusinessFormComponent,
    BusinessTableComponent,
    MapLoaderComponent,
    BusinessMenuComponent,
    BusinessSidenavComponent,
    BusinessIndicatorsComponent,
    BusinessFilterComponent
  ],
  exports: [
    BusinessFormComponent,
    BusinessTableComponent,
    MapLoaderComponent,
    BusinessMenuComponent,
    BusinessSidenavComponent,
    BusinessIndicatorsComponent,
    BusinessFilterComponent
  ],
  providers: [
    BusinessReshapeService,
    BusinessViewerService,
    BusinessApiService,
    BusinessFormOptionsService,
    MapService,
    IndicatorService,
    CompassMapIndicatorsService,
    Mathematics,
    ThematicKeySymbol
  ]
})
export class BusinessModule {
}
