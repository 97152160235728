<div class="text-center" *ngIf="!studies?.length">
  <h3>No has creado ningún estudio</h3>
  <small>Para crear uno, utilizar el buscador para encontrar una ubicación y haz click en el marcador.</small>
</div>

<mat-action-list *ngIf="studies?.length">
  <ng-container *ngFor="let study of studies">
    <button mat-list-item (click)="openStudy( study )"  title="Abrir estudio">
      <mat-icon mat-list-icon color="primary">folder</mat-icon>
      <div mat-line><strong>{{ study.properties.study.name }}</strong></div>
      <small mat-line>  #{{ study.properties.id }}</small>
    </button>
    <mat-divider></mat-divider>
  </ng-container>
</mat-action-list>

