import { Injectable } from '@angular/core';
import * as L from 'leaflet';

@Injectable({
  providedIn: 'root',
})
export class CompassCatastroLayersService {
  constructor() { }

  // https://github.com/heigeo/leaflet.wms
  // http://ovc.catastro.meh.es/Cartografia/WMS/ServidorWMS.aspx?
  // https://www1.sedecatastro.gob.es/Cartografia/GeneraMapa.aspx?
  // L.WMS.layer('http://ovc.catastro.meh.es/Cartografia/WMS/ServidorWMS.aspx?', "catastro",
  // OVC: oficina virtual del catastro
  // http://xeoinquedos.eu/curso-webgis/leaflet/5_interactuando_mapa/

  // OVC (oficina virtual del catastro) SEde Catastro

  // main vars
  zoom: number = 10;
  minZoom: number = 14;
  maxZoom: number = 20;
  maxNativeZoom: number = 19;
  public opacity: number = 0.8;
  ovcAttribution: string =
    'Cartografía Catastral de la Dirección General del Catastro.';
  zIndex: number = 10;

  // URLS
  // public ovcUrl: string =
  //   'http://ovc.catastro.meh.es/Cartografia/WMS/ServidorWMS.aspx?';
  public ovcUrl: string = '/ovc?';
  public sedeCatastroUrl: string =
    'https://www1.sedecatastro.gob.es/Cartografia/GeneraMapa.aspx?';
  // IGN
  public ignUrl = 'https://www.ign.es/wms-inspire/unidades-administrativas?';
  public ignAttribution: string = '© IGN';
  // Ponencias
  public ponenciasUrl =
    'https://ovc.catastro.meh.es/Cartografia/WMS/PonenciasWMS.aspx?';

  // sublayers
  private catastroSubLayers = 'catastro';
  public ponenciasSubLayers = 'Todas';

  // navarra Iruña/Pamplona
  //public navarraUrl = 'https://idena.navarra.es/ogc/wms?';
  public navarraUrl = '/idena?';

  public navarraSubLayers = 'catastro';
  //let lat = 42.8286;
  //let lng = -1.6612;

  // araba Gasteiz/Vitoria
  // public arabaUrl = 'https://geo.araba.eus/WMS_INSPIRE_CP?';
  public arabaUrl = '/geo?';

  public arabaSubLayers = 'CP.CadastralParcel';
  //let lat = 42.8567;
  //let lng = -2.6753;

  // gipuzkoa/Guipúzcoa Donosti/San Sebastián
  //public gipuzkoaUrl = 'https://b5m.gipuzkoa.eus/ogc/wms/katastro_wms?';
  public gipuzkoaUrl = '/b5m?';

  //public gipuzkoaSubLayers = 'hirilur_urbano';
  public gipuzkoaSubLayers = 'hirilur_urbano,landalur_rustica';
  //let lat = 43.3177;
  //let lng = -1.9624;

  // Vizkaia / Vizcaya Bilbo/Bilbao
  //public bizkaiaUrl = 'https://arcgis.bizkaia.net/arcgis/services/CATASTRO/WMFS/MapServer/WMSServer?';
  public bizkaiaUrl = '/arcgis?';

  public bizkaiaSubLayers = '2,3,4,6,7,8,9';
  //let lat = 43.2630;
  //let lng = -2.9189;

  // coords (setView function)
  // used for testing and development
  // on Init, on cadastre radio buttons change - onClickNoOvcRadios -
  // In the case Study coords are disabled
  // On production, use study coords, coordsCenter @input
  public navarraCoords = [42.8286, -1.6612];
  public arabaCoords = [42.8567, -2.6753];
  public gipuzkoaCoords = [43.3177, -1.9624];
  public bizkaiaCoords = [43.263, -2.9189];
  // madrid Sol
  public ovcCoords = [40.4137, -3.7078];

  public coords = {
    ovcCatastroSolidLayer: this.ovcCoords,
    ovcCatastroTransparentLayer: this.ovcCoords,
    hidden: this.ovcCoords,
    navarraCatastroLayer: this.navarraCoords,
    arabaCadastralParcelLayer: this.arabaCoords,
    gipuzkoaKatastroLayer: this.gipuzkoaCoords,
    bizkaiaKatrastroLayer: this.bizkaiaCoords,
  };

  // Main layers
  // https://leafletjs.com/reference-1.7.1.html#tilelayer-wms
  public catastroLayers = {
    ovcCatastroSolidLayer: L.tileLayer.wms(this.ovcUrl, {
      layers: this.catastroSubLayers,
      format: 'image/png',
      styles: '',
      uppercase: true,
      transparent: false,
      opacity: this.opacity,
      maxZoom: this.maxZoom,
      minZoom: this.minZoom,
      maxNativeZoom: this.maxNativeZoom,
      attribution: this.ovcAttribution,
      version: '1.1.1',
      className: 'catastroLayer',
      zIndex: this.zIndex
    }),
    ovcCatastroTransparentLayer: L.tileLayer.wms(this.ovcUrl, {
      layers: this.catastroSubLayers,
      format: 'image/png',
      uppercase: true,
      transparent: true,
      opacity: this.opacity,
      maxZoom: this.maxZoom,
      minZoom: this.minZoom,
      maxNativeZoom: this.maxNativeZoom,
      attribution: this.ovcAttribution,
      version: '1.1.1',
      className: 'catastroLayer',
      zIndex: this.zIndex
    }),
    // https://www.wrld3d.com/wrld.js/latest/docs/leaflet/L.TileLayer.WMS/
    // yellow line
    ignAdministrativeUnitLayer: L.tileLayer.wms(this.ignUrl, {
      layers: 'AU.AdministrativeUnit',
      format: 'image/png',
      uppercase: true,
      transparent: true,
      opacity: this.opacity,
      maxZoom: this.maxZoom,
      maxNativeZoom: 18,
      version: '1.1.1',
      attribution: this.ignAttribution,
      className: 'ignAdministrativeUnitLayer',
      zIndex: this.zIndex
    }),
    // https://github.com/ptv-logistics/Leaflet.NonTiledLayer
    // Parcela catastral
    sedeCatastroParcelaLayer: L.tileLayer.wms(this.ovcUrl, {
      layers: 'PARCELA',
      format: 'image/png',
      transparent: true,
      maxZoom: this.maxZoom,
      minZoom: this.minZoom,
      maxNativeZoom: this.maxNativeZoom,
      attribution: this.ovcAttribution,
      className: 'sedeCatastroParcelaLayer catastroLayer',
      zIndex: this.zIndex
    }),
    // Subparcelas de construcción
    sedeCatastroConstruLayer: L.tileLayer.wms(this.ovcUrl, {
      layers: 'CONSTRU',
      format: 'image/png',
      transparent: true,
      maxZoom: this.maxZoom,
      minZoom: this.minZoom,
      maxNativeZoom: this.maxNativeZoom,
      attribution: this.ovcAttribution,
      className: 'sedeCatastroConstruLayer catastroLayer',
      zIndex: this.zIndex
    }),
    //  Subparcelas de cultivo
    sedeCatastroSubparceLayer: L.tileLayer.wms(this.ovcUrl, {
      layers: 'SUBPARCE',
      format: 'image/png',
      transparent: true,
      maxZoom: this.maxZoom,
      minZoom: this.minZoom,
      maxNativeZoom: this.maxNativeZoom,
      attribution: this.ovcAttribution,
      className: 'sedeCatastroSubparceLayer catastroLayer',
      zIndex: this.zIndex
    }),
    // Polígonos y manzanas
    sedeCatastroMasaLayer: L.tileLayer.wms(this.ovcUrl, {
      layers: 'MASA',
      format: 'image/png',
      transparent: true,
      maxZoom: this.maxZoom,
      minZoom: this.minZoom,
      attribution: this.ovcAttribution,
      className: 'sedeCatastroMasaLayer catastroLayer',
      zIndex: this.zIndex
    }),

    // Vías de comunicación
    sedeCatastroEjesLayer: L.tileLayer.wms(this.ovcUrl, {
      layers: 'EJES',
      format: 'image/png',
      transparent: true,
      maxZoom: this.maxZoom,
      minZoom: this.minZoom,
      attribution: this.ovcAttribution,
      className: 'sedeCatastroEjesLayer catastroLayer',
      zIndex: this.zIndex
    }),
    // Límites administrativos, costa y suelo urbano
    sedeCatastroLimitesLayer: L.tileLayer.wms(this.ovcUrl, {
      layers: 'LIMITES',
      format: 'image/png',
      transparent: true,
      maxZoom: this.maxZoom,
      minZoom: this.minZoom,
      attribution: this.ovcAttribution,
      className: 'sedeCatastroLimitesLayer catastroLayer',
      zIndex: this.zIndex
    }),

    // toponimia
    sedeCatastroTextosLayer: L.tileLayer.wms(this.ovcUrl, {
      layers: 'TEXTOS',
      format: 'image/png',
      transparent: true,
      maxZoom: this.maxZoom,
      minZoom: this.minZoom,
      attribution: this.ovcAttribution,
      className: 'sedeCatastroTextosLayer catastroLayer',
      zIndex: this.zIndex
    }),

    // Caminos, barrancos, masas de agua -elementos lindantes -
    sedeCatastroElemlinLayer: L.tileLayer.wms(this.ovcUrl, {
      layers: 'ELEMLIN',
      format: 'image/png',
      transparent: true,
      maxZoom: this.maxZoom,
      minZoom: this.minZoom,
      attribution: this.ovcAttribution,
      className: 'sedeCatastroElemlinLayer catastroLayer',
      zIndex: this.zIndex
    }),
    // ponencias
    ovcPonenciasLayer: L.tileLayer.wms(this.ponenciasUrl, {
      layers: this.ponenciasSubLayers,
      format: 'image/png',
      uppercase: true,
      transparent: false,
      opacity: this.opacity,
      maxZoom: this.maxZoom,
      minZoom: 1,
      maxNativeZoom: this.maxNativeZoom,
      attribution: this.ovcAttribution,
      version: '1.1.1',
      className: 'ovcPonenciasLayer catastroLayer',
      zIndex: this.zIndex
    }),
    navarraCatastroLayer: L.tileLayer.wms(this.navarraUrl, {
      layers: this.navarraSubLayers,
      format: 'image/png',
      uppercase: true,
      transparent: false,
      opacity: this.opacity,
      maxZoom: this.maxZoom,
      minZoom: this.minZoom,
      maxNativeZoom: this.maxNativeZoom,
      attribution: 'Infraestructura de Datos Espaciales de Navarra.',
      className: 'navarraCatastroLayer catastroLayer',
      zIndex: this.zIndex
    }),
    // ARABA / VITORIA
    arabaCadastralParcelLayer: L.tileLayer.wms(this.arabaUrl, {
      layers: this.arabaSubLayers,
      format: 'image/png',
      uppercase: true,
      transparent: false,
      opacity: this.opacity,
      maxZoom: this.maxZoom,
      minZoom: this.minZoom,
      maxNativeZoom: this.maxNativeZoom,
      attribution: 'Diputación Foral de Ãlava.',
      className: 'arabaCadastralParcelLayer catastroLayer',
      zIndex: this.zIndex
    }),
    // gipuzkoa / GUIPÚZCOA
    gipuzkoaKatastroLayer: L.tileLayer.wms(this.gipuzkoaUrl, {
      layers: this.gipuzkoaSubLayers,
      format: 'image/png',
      uppercase: true,
      transparent: false,
      opacity: this.opacity,
      maxZoom: this.maxZoom,
      minZoom: this.minZoom,
      maxNativeZoom: this.maxNativeZoom,
      attribution: 'Diputación Foral de Gipuzkoa.',
      className: 'gipuzkoaKatastroLayer catastroLayer',
      zIndex: this.zIndex
    }),
    // BIZKAIA / VIZCAYA
    // http://arcgis.bizkaia.eus/inspire/rest/services/Catastro/Annex1/MapServer
    bizkaiaKatrastroLayer: L.tileLayer.wms(this.bizkaiaUrl, {
      layers: this.bizkaiaSubLayers,
      format: 'image/png',
      uppercase: true,
      transparent: false,
      opacity: this.opacity,
      maxZoom: this.maxZoom,
      minZoom: this.minZoom,
      maxNativeZoom: this.maxNativeZoom,
      attribution: 'Diputación Foral de Bizkaia.',
      className: 'bizkaiaKatrastroLayer catastroLayer',
      zIndex: this.zIndex
    }),
  };

  public get(layers: string) {
    if (layers === 'catastroLayers') {
      return this.catastroLayers;
    }
    return {};
  }
}
