<nav *ngIf="sidenavOpen">
    <div class="sidenav start-tight" [class.sidenavOpen]="sidenavOpen">
        <a href="javascript:void(0)" class="closebtn" (click)="closeNav($event)">&times;</a>

        <div class="inner-content">
            <ng-content></ng-content>

        </div>
    </div>

</nav>