import { Injectable } from "@angular/core";

@Injectable({ providedIn: "root" })
export class ZIndexService {
    constructor() { }

    public $z__index_deeper = -99;
    public $z__index_deep = -1;
    public $z__index_zero = 0;
    public $z__index_bottom = 1;
    public $z__index_middle = 99;
    public $z__index_top = 999;
    public $z__index_super = 9999;

}



