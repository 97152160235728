import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';
import { Router } from '@angular/router';
import { map } from 'rxjs/operators';
import { slugify } from '@compass/utils/slugify';
import { MatSnackBar } from '@angular/material/snack-bar';
import { PatrimonioService } from '@compass/patrimonio/data-access';
import { PoiService } from '@compass/pois/data-access-poi';

export interface Category {
  key: string;
  class: string;
  categoria: string;
  sub_categoria: string;
  key_sub_categoria?: string;
  descripcion: string;
  fecha_creacion: string;
  id: string;
  icono: string;
}

class category implements Category {
  key = 'data_category';
  class = 'data_category';
  categoria: string;
  sub_categoria: string;
  descripcion: string;
  fecha_creacion = new Date().toLocaleString();
  key_sub_categoria: string;
  id = undefined;
  icono = '';

  constructor(form: any, id?: string) {
    this.categoria = form.categoria;
    this.sub_categoria = form.sub_categoria;
    this.descripcion = form.descripcion;
    this.key_sub_categoria = slugify(form.sub_categoria);
    this.id = id ?? undefined;
    this.icono = form.icono;
  }
}

export interface CategoryIcon {
  key: string;
  name: string;
}

@Injectable({
  providedIn: 'root'
})
export class CategoryService {
  private iconsUrl = '/assets/data/categories/icons.json';
  private categoriesUrl = '/abacusgis/core/glocally/collection/data_category/';
  public categories$ = new BehaviorSubject<Category[]>(undefined);
  public category$ = new BehaviorSubject<Category>(undefined);
  public categoryIcons$ = new BehaviorSubject<CategoryIcon[]>(undefined);

  constructor(
    private http: HttpClient,
    private router: Router,
    private _snackBar: MatSnackBar
  ) {
  }

  getAll(type?: string) {
    let query = '';
    if (type) {
      query = `?query={"categoria" : "${type}"}`;
    }
    return this.http
      .get(`${this.categoriesUrl}${query}`)
      .subscribe((categories: Category[]) => {
        this.categories$.next(categories);
      });
  }

  getById(id: string) {
    return this.http
      .get(`${this.categoriesUrl}?query={"id" : "${id}"}`)
      .pipe(map((categories: Category[]) => categories?.shift()))
      .subscribe((category: Category) => {
        this.category$.next(category);
      });
  }

  getBySubCategoryKey(keySubCategory: string, categoryType: string) {
    return this.http
      .get(`${this.categoriesUrl}?query={"key_sub_categoria" : "${keySubCategory}", "categoria": "${categoryType}"}`)
      .toPromise()
      .then((res: any) => {
        return res;
      });
  }

  addCategory(form: Category) {
    const categoryModel = new category(form);

    this.checkDuplicatedCategory(categoryModel)
      .then((isDuplicated) => {
        if (isDuplicated) {
          this._snackBar.open(`Ya existe una categoría "${categoryModel.sub_categoria}" de tipo ${categoryModel.categoria}`,
            'Aceptar', {
              duration: 3400,
              panelClass: ['danger', 'wide']
            });
        } else {
          this.http
            .post<Category>(`${this.categoriesUrl}`, categoryModel)
            .subscribe((d: any) => {
              this.categories$.next(undefined);
              this.router.navigate(['/categorias']);
            });
        }
      });
  }

  updateCategory(id: string, form: Category) {
    const categoryModel = new category(form, id);

    this.checkDuplicatedCategory(categoryModel)
      .then((isDuplicated) => {
        if (isDuplicated) {
          this._snackBar.open(`Ya existe una categoría "${categoryModel.sub_categoria}" de tipo ${categoryModel.categoria}`,
            'Aceptar', {
              duration: 3400,
              panelClass: ['danger', 'wide']
            });
        } else {
          this.http
            .put<Category>(`${this.categoriesUrl}`, categoryModel)
            .subscribe((d: any) => {
              this.categories$.next(undefined);
              this.router.navigate(['/categorias']);
            });
        }
      });
  }

  deleteCategory(id: string) {
    return this.http
      .delete<Category>(`${this.categoriesUrl}${id}`)
      .subscribe((d: any) => {
        this.categories$.next(undefined);
        this.getAll();
      });
  }

  getAllIcons() {
    this.categoryIcons$.next(undefined);
    return this.http.get(this.iconsUrl).subscribe((icons: CategoryIcon[]) => {
      this.categoryIcons$.next(icons);
    });
  }

  clearCategory() {
    this.category$.next(undefined);
  }

  /**
   * Checks if the category is duplicated.
   *
   * @param category
   * @private
   */
  private checkDuplicatedCategory(category) {
    return this.getBySubCategoryKey(category.key_sub_categoria, category.categoria)
      .then((response) => {
        const duplciatedKeyIds = response.map(attr => attr.id);

        // if we have duplicated keys && is not the same id, means that there already exists one with same key
        return !!duplciatedKeyIds.length && !duplciatedKeyIds.includes(category.id);
      });
  }
}
