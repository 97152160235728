import { Component, OnInit } from '@angular/core';
import { EndRightSidenavService } from '../end-right-sidenav.service';
@Component({
  selector: 'compass-end-right-sidenav-base',
  templateUrl: './end-right-sidenav-base.component.html',
  styleUrls: ['./end-right-sidenav-base.component.scss']
})
export class EndRightSidenavBaseComponent implements OnInit {
  endRightSidenavOn: boolean;
  constructor(private endRightSidenavService: EndRightSidenavService) {

    this.endRightSidenavService.getSidenavStatus().subscribe((value) => {
      this.endRightSidenavOn = value;
    });
  }

  closeSideNav() {
    this.endRightSidenavService.setSidenavStatus(false);
  }

  ngOnInit(): void {
  }

}
