import { Injectable } from "@angular/core";
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject } from "rxjs";
import { Observable } from 'rxjs/Observable';

@Injectable({ providedIn: "root" })
export class EndRightSidenavService {
  public endRightSidenavOn$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor() { }

  setSidenavStatus(status) {
    this.endRightSidenavOn$.next(status);
  }
  getSidenavStatus(): Observable<boolean> {
    return this.endRightSidenavOn$.asObservable();
  }
}