<mat-form-field>
    <mat-label>Búsqueda</mat-label>
    <input matInput (keyup)="applyFilter($event)" placeholder="Búsqueda" #input>
</mat-form-field>


<div class="mat-elevation-z0">
    <table mat-table [dataSource]="dataSource" matSort>

        <!--- Note that these columns can be defined in any order.
        The actual rendered columns are set as a property on the row definition" -->

        <!-- Position Column -->
        <ng-container matColumnDef="denominacion">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Nombre </th>
            <td mat-cell *matCellDef="let element"> {{element.denominacion}} </td>
        </ng-container>

        <!-- Name Column -->
        <ng-container matColumnDef="descripcion_cnae">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Descripción </th>
            <td mat-cell *matCellDef="let element"> {{element.descripcion_cnae}} </td>
        </ng-container>

        <!-- Weight Column -->
        <ng-container matColumnDef="sigla">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Tipo </th>
            <td mat-cell *matCellDef="let element"> {{element.sigla}} </td>
        </ng-container>

        <!-- Symbol Column -->
        <ng-container matColumnDef="rating_morosidad">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Morosidad </th>
            <td mat-cell *matCellDef="let element"> {{element.rating_morosidad}} </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

        <!-- Row shown when there is no matching data. -->
        <tr class="mat-row" *matNoDataRow>
            <td class="mat-cell" colspan="4">No existen datos para el campo "{{input.value}}"</td>
        </tr>


    </table>
    <mat-paginator [pageSizeOptions]="[10, 25, 100]"></mat-paginator>


</div>