import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { HttpErrorResponse } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import * as L from 'leaflet';
import { MapBoxService } from '@compass/utils/misc';
import { ColorsService } from '@compass/utils/misc';
import { ZIndexService } from '@compass/utils/misc';
import 'leaflet-draw';


export interface MapSetUp {
  id: string;
  theme: string;
  center: Array<any>;
  iconUrl: string;
  iconSize?: Array<number>;
  iconAnchor?: Array<number>;
  popupAnchor?: Array<number>;
  popUp?: PopUp;
  zoom?: number;
}

interface Properties {
  center: any;
  minZoom: number;
  zoom: any;
  maxZoom: number;
  maxNativeZoom: number;
  maxBounds: any;
  dragging: boolean;
  touchZoom: boolean;
  scrollWheelZoom: boolean;
  boxZoom: boolean;
  zoomControl: boolean;
  address: any;
  locality: any;
  province: any;
  crs?: L.CRS;
}

interface Attribution {
  attribution: string;
}

interface StyleProperties {
  color: string;
  weight: number;
  opacity: number;
  fillOpacity: number;
  dashArray: string;
}

interface Mousemove {
  fillOpacity: number;
}

interface Style {
  default: StyleProperties;
  mouseover: Mousemove;
  mouseout: Mousemove;
  click: StyleProperties;
}

interface Area {
  style: Style;
  prevLayerClicked: null | any;
  layerClicked: null | any;
}

interface MarkerIcon {
  iconUrl: string;
  iconSize: Array<number>;
  iconAnchor: Array<number>;
  popupAnchor: Array<number>;
}

interface MarkerOptions {
  name: string;
  zIndex: number;
  width: number;
  draggable?: boolean;
}

interface Marker {
  icon: any;
  options: MarkerOptions;
  l: null | L.Marker;
}

interface PopUp {
  visible: boolean;
  options: {
    direction: string;
    permanent: boolean;
    offset: any;
    opacity: number;
    className: string;
    maxWidth: number;
  };
  info: {
    off: string;
    on: string;
  };
}

export interface Map {
  id: string;
  change: string;
  access_token: string;
  box: any;
  geojsonLayer: null | any;
  transitLayer: null | any;
  studyLayer: null | any;
  cpsLayer: null | any;
  bounds: null | L.Bounds;
  properties: Properties;
  zoom: any;
  zoomCtrl: any;
  tileLayer: any;
  attribution: Attribution;
  marker: Marker;
  popUp: PopUp;
  area: Area;
  data: null | Array<any>;
  info_control: null | any;
  minMaxScales: Array<any>;
  color_key: null | any;
  pois: null | any;
}

@Injectable({ providedIn: 'root' })
export class CompassMapWrapperService {
  map: Map;
  public map$: BehaviorSubject<Map> = new BehaviorSubject<Map>(undefined);

  private clickMap$ = new Subject<any>();
  clickMapChanged$ = this.clickMap$.asObservable();

  constructor(
    private mapBoxService: MapBoxService,
    private colorsService: ColorsService,
    private zIndexService: ZIndexService
  ) {
    this.map = {
      id: 'map_elementRef',
      change: '',
      access_token: this.mapBoxService.getMapBoxUrl('transport_light')[0].value,
      box: null,
      geojsonLayer: null,
      transitLayer: null,
      studyLayer: null,
      cpsLayer: null,
      bounds: null,
      properties: {
        center: [42.1357507222081, -0.41074235303953105], // huesca center
        minZoom: 6,
        zoom: 17,
        maxZoom: 20,
        maxNativeZoom: 19,
        maxBounds: L.latLngBounds([L.latLng(45, 27), L.latLng(4.333333, -25)]),
        dragging: true,
        touchZoom: true,
        scrollWheelZoom: true,
        boxZoom: true,
        zoomControl: false,
        address: 'any',
        locality: 'any',
        province: 'any'
      },
      zoom: {
        position: 'topright',
        zoomInText: '+',
        zoomOutText: '-'
      },
      zoomCtrl: null,
      tileLayer: null,
      attribution: {
        attribution:
          'Map data &copy; <a href="http://openstreetmap.org">OpenStreetMap</a> contributors, ' +
          '<a href="http://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>, ' +
          'Imagery © <a href="http://mapbox.com">Mapbox</a>'
      },
      marker: {
        icon: this.markerIcon,
        options: this.markerOptions,
        l: null
      },
      popUp: {
        visible: false,
        options: {
          direction: 'right',
          permanent: false,
          offset: [0, 0],
          opacity: 0,
          className: 'customPopUp',
          maxWidth: 0
        },
        info: {
          on: ``,
          off: ``
        }
      },
      area: {
        style: {
          default: {
            color: this.colorsService.colors.orange,
            weight: 2,
            fillOpacity: 0.32,
            opacity: 0.65,
            dashArray: '0'
          },
          mouseover: {
            fillOpacity: 0.4
          },
          mouseout: {
            fillOpacity: 0.32
          },
          click: {
            color: this.colorsService.colors.red,
            weight: 2,
            opacity: 0.32,
            fillOpacity: 0.24,
            dashArray: '0'
          }
        },
        prevLayerClicked: null,
        layerClicked: null
      },
      data: null,
      info_control: null,
      minMaxScales: [],
      color_key: null,
      pois: []
    };

    L.drawLocal.draw = {
      handlers: {
        circle: {
          tooltip: {
            start: 'Click y arrastrar para dibujar un círculo.'
          },
          radius: 'Radio'
        },
        circlemarker: {
          tooltip: {
            start: 'Click en el mapa para situar un punto circular.'
          }
        },
        marker: {
          tooltip: {
            start: 'Click en el mapa para situar el punto.'
          }
        },
        polygon: {
          tooltip: {
            start: 'Click para empezar a dibujar una forma.',
            cont: 'Click para continuar dibujando una forma.',
            end: 'Click en primer punto para cerrar esta forma.'
          }
        },
        polyline: {
          error:
            '<strong>Error:</strong> ¡los bordes de la forma no pueden cruzarse!',
          tooltip: {
            start: 'Click para empezar a dibujar una línea.',
            cont: 'Click  para continuar dibujando una línea.',
            end: 'Click último punto para terminar la línea.'
          }
        },
        rectangle: {
          tooltip: {
            start: 'Click y arrastrar para dibujar un rectángulo.'
          }
        },
        simpleshape: {
          tooltip: {
            end: 'Suelte el puntero para terminar de dibujar.'
          }
        }
      },
      toolbar: {
        actions: {
          text: 'Cancelar',
          title: 'Cancelar dibujo'
        },
        buttons: {
          circle: 'Dibujar un círculo',
          circlemarker: 'Dibujar un punto circular',
          marker: 'Dibujar un punto',
          polygon: 'Dibujar un polígono',
          polyline: 'Dibujar una polilínea',
          rectangle: 'Dibujar un rectángulo'
        },
        finish: {
          text: 'Terminar',
          title: 'Terminar de dibujar'
        },
        undo: {
          text: 'Borrar último punto',
          title: 'Borrar último punto dibujado'
        }
      }
    };

    L.drawLocal.edit = {
      handlers: {
        edit: {
          tooltip: {
            subtext: 'Click cancelar para deshacer cambios.',
            text: 'Arrastre vértices o puntos para editar forma.'
          }
        },
        remove: {
          tooltip: {
            text: 'Click en una forma para eliminar.'
          }
        }
      },
      toolbar: {
        actions: {
          cancel: {
            text: 'Cancelar',
            title: 'Cancelar editar, descartar todos los cambios.'
          },
          clearAll: {
            text: 'Limpiar todo',
            title: 'Limpiar todas las capas.'
          },
          save: {
            text: 'Guardar',
            title: 'Guardar cambios.'
          }
        },
        buttons: {
          edit: 'Editar capas',
          editDisabled: 'Sin capas para editar',
          remove: 'Borrar capas',
          removeDisabled: 'Sin capas para borrar'
        }
      }
    };
    //this.setMap(this.map);
  }

  private markerIcon: MarkerIcon = {
    iconUrl: '../../../../assets/img/markers/glocally-brick-purple.svg',
    iconSize: [40, 80],
    iconAnchor: [25, 40],
    popupAnchor: [7, 100]
  };

  private markerOptions: MarkerOptions = {
    name: 'marker_pane',
    zIndex: this.zIndexService.$z__index_top,
    width: 80
  };

  public initDefaultMap(): Map {
    return this.map;
  }

  public initMap(properties: MapSetUp): Map {
    this.map.id = properties.id ? properties.id : this.map.id;

    this.map.access_token = this.mapBoxService.getMapBoxUrl(
      properties.theme
    )[0].value;
    this.map.properties.center = properties.center
      ? properties.center
      : this.map.properties.center;
    // reset marker
    this.map.marker = {
      icon: this.markerIcon,
      options: this.markerOptions,
      l: null
    },
      this.map['marker']['icon'].iconUrl = properties.iconUrl
        ? properties.iconUrl
        : this.map['marker']['icon'].iconUrl;

    this.map['marker']['icon'].iconSize = properties.iconSize
      ? properties.iconSize
      : this.map['marker']['icon'].iconSize;

    this.map['marker']['icon'].iconAnchor = properties.iconAnchor
      ? properties.iconAnchor
      : this.map['marker']['icon'].iconAnchor;

    this.map['marker']['icon'].popupAnchor = properties.popupAnchor
      ? properties.popupAnchor
      : this.map['marker']['icon'].popupAnchor;

    this.map.properties.zoom = properties?.zoom ?? this.map.properties.zoom;

    this.map.popUp = properties.popUp ? properties.popUp : this.map.popUp;
    return this.map;
  }

  public setId(id): void {
    this.map.id = id;
  }

  public setMapCenter(center: Array<any>): void {
    this.map.properties.center[0] = center[0];
    this.map.properties.center[1] = center[1];
  }


  createMakerMap(object: any): void {
    this.clickMap$.next(object);
  }


  public setZoomMap(value: number): void {
    this.map.zoom = value;
    this.map.box.setZoom(value);
  }

  public getMap() {
    return this.map$.asObservable();
  }

  public setMap(map: Map): void {
    this.map$.next(map);
  }

}
