<article class="med--width">

  <section class="container section">
    <div class="margin__top--xl">
      <div class="container" fxLayout="row" fxLayout.xs="column" fxLayoutWrap fxLayoutGap="0.5%" fxLayoutAlign="left">
        <div fxFlex="100">
          <div class="text__align--right">
            <compass-cancel-button [router_link]='closeUrl'></compass-cancel-button>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section class="container section">
    <div class="margin__top--s">
      <div class="container" fxLayout="row" fxLayout.xs="column" fxLayoutWrap fxLayoutGap="0.5%" fxLayoutAlign="left">
        <div fxFlex="100">
          <ng-template #adduser>
            <div class="container text__align--center">
              <compass-crud-icons [icon_class]='icon_class'></compass-crud-icons>
            </div>

            <div class="container text__align--center">
              <h1 class="h1-page--title">
                <span class="icon usuarios-add animate-blue"></span>
                Añadir | Nuevo usuario
              </h1>
              <p>Crea un nuevo usuario y añádelo a la aplicación.</p>
            </div>

          </ng-template>

          <div *ngIf="user$ | async as user; else adduser">
            <div class="container text__align--center">
              <h1 class="h1-page--title">
                <span class="icon usuarios-edit animate-blue"></span> Editar |
                {{ user.username }}
              </h1>
              <p>Edita un usuario ya existente en la aplicación.</p>
            </div>
          </div>
        </div>

      </div>
    </div>
  </section>


  <user-form [user]="user$ | async" [redirectUrl]="closeUrl"></user-form>
</article>
