import { Component, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { StudyService } from '@compass/studies';
import { StudyModel } from '../../../../../../../libs/studies/src/lib/study.model';
import { SidenavService } from '@compass/page-navigation/sidenav';
import { IndicatorsComponent } from './indicators/indicators.component';
import { BricksApiService } from '@compass/brick-api';
import { Router } from '@angular/router';
import { BusinessMenuComponent } from '../business-menu/business-menu.component';
import { TrafficComponent } from '../traffic/traffic.component';
import { TransitComponent } from '../transit/transit.component';
import { ClientsMenuComponent } from '../clients-menu/clients-menu.component';
import { AuthService } from '@compass/shared/feature-auth';
import { GlobalPoisMenuComponent } from '../global-pois-menu/global-pois-menu.component';

@Component({
  selector: 'lab-study-detail',
  templateUrl: './study-detail.component.html',
  styleUrls: ['./study-detail.component.scss']
})
export class StudyDetailComponent implements OnInit {
  @ViewChild('subComponent', { read: ViewContainerRef }) subComponent;

  private study: StudyModel;

  options = [
    {
      id: 'indicators',
      title: 'Indicadores/Entorno',
      icon: 'bar_chart',
      component: IndicatorsComponent,
      active: false,
      canSee: this.userCanSee('indicators')
    },
    {
      id: 'global_pois',
      title: 'Global Pois',
      icon: 'location_on',
      component: GlobalPoisMenuComponent,
      active: false,
      canSee: this.userCanSee('business')
    },
    {
      id: 'business',
      title: 'Negocios',
      icon: 'business',
      component: BusinessMenuComponent,
      active: false,
      canSee: this.userCanSee('business')
    },
    {
      id: 'clients',
      title: 'Clientes',
      icon: 'people_alt',
      component: ClientsMenuComponent,
      active: false,
      canSee: this.userCanSee('clients')
    },
    {
      id: 'viajeros',
      title: 'Viajeros',
      icon: 'directions',
      canSee: this.userCanSee('viajeros'),
      options: [
        {
          id: 'transit',
          title: 'Tránsito',
          icon: 'directions_run',
          component: TransitComponent,
          active: false,
          canSee: this.userCanSee('transit')
        },
        {
          id: 'traffic',
          title: 'Tráfico',
          icon: 'traffic',
          component: TrafficComponent,
          active: false,
          canSee: this.userCanSee('traffic')
        }
      ]
    }
  ];

  constructor(
    private studyService: StudyService,
    private sidenavService: SidenavService,
    private bricksApiService: BricksApiService,
    private router: Router,
    private authService: AuthService
  ) {
  }

  ngOnInit(): void {
    this.studyService.study$.subscribe(study => {
      this.study = study;
      this.bricksApiService.setDataSB(this.study);
    });
  }

  loadComponent(e, option) {
    e.preventDefault();
    if (option.component) {
      this.sidenavService.loadComponent(option);
    }

    if (option.route) {
      const route = option.route.replace(':studyId', this.study.properties.id);
      this.router.navigate([route], { replaceUrl: true });
    }

    option.active = option.active ? !option.active : true;

  }

  userCanSee(module) {
    const user = this.authService.currentUser$.value;
    let canSee = true;

    if (module === 'clients') {
      // if not ogilvy user and not admin
      if (!user.username.includes('ogilvy') && !user.username.includes('iber') && !user.role.includes('admin')) {
        canSee = false;
      }
    }

    return canSee;
  }

}

