import { Component, OnDestroy } from '@angular/core';
import { StudyService } from '@compass/studies';
import { BusinessApiService, PoiBusiness } from '@compass/business';
import { Subscription } from 'rxjs';

@Component({
  selector: 'lab-business-menu',
  templateUrl: './business-menu.component.html',
  styleUrls: ['./business-menu.component.scss']
})
export class BusinessMenuComponent implements OnDestroy {
  business: PoiBusiness[] = [];
  loading: boolean = true;

  businessLoadedSub: Subscription;

  constructor(
    private studyService: StudyService,
    private businessApiService: BusinessApiService
  ) {
    let study = this.studyService.study$.value;
    this.businessApiService.getBusinessInStudy(study);
    this.businessLoadedSub = this.businessApiService.business$.subscribe(business => {
      if (business?.length) {
        this.business = business;
      }

      this.loading = false;
    });
  }

  ngOnDestroy() {
    this.businessLoadedSub?.unsubscribe();
  }

}
