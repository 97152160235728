import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SelectIndicatorsComponent } from './select-indicators/select-indicators.component';
import { ListIndicatorsComponent } from './list-indicators/list-indicators.component';
import { MaterialModule } from '@compass/material';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
@NgModule({
  declarations: [SelectIndicatorsComponent, ListIndicatorsComponent],
  imports: [CommonModule, MaterialModule, FormsModule, ReactiveFormsModule],
  exports: [SelectIndicatorsComponent, ListIndicatorsComponent],
  providers: []
})
export class IndicatorsSelectModule { }

