import { Component, OnInit } from '@angular/core';
import { Toolbar } from '@compass/page-structure/top-toolbar-wrapper'



@Component({
  selector: 'lab-top-toolbar',
  templateUrl: './top-toolbar.component.html',
  styleUrls: ['./top-toolbar.component.scss'],
})
export class TopToolbarComponent implements OnInit {

  toolbarSetUp: Toolbar = {
    title: 'Compass Lab App',
    style:
      { height: '40px' },
    class: 'mat-toolbar primary-deep--bkg deep-color',
    img: {
      class: 'trademark',
      src: 'assets/img/brand/compass-lab-logo-white.svg',
      alt: 'Compass Lab App',
      width: '144'
    }
  }
  constructor() { }

  ngOnInit(): void { }
}

