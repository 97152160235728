<aside [attr.id]="setup.id" [ngClass]="setup.class" *ngIf="visible">
  <div class="searchbox-container" role="search">
    <div class="searchbox mat-elevation-z1" [style.width]="setup.width + 'px'">
      <form class="searchbox-form-wrapper">
        <mat-form-field data-cy="searchbox">
          <!--<mat-label>Buscar en mapa</mat-label>-->
          <input
            matInput
            #message
            maxlength="256"
            autofocus="autofocus"
            value=""
            aria-label="Buscar en Google Maps"
            autocomplete="off"
            id="searchboxinput"
            name="searchboxinputq"
            class="tactile-searchbox-input"
            aria-haspopup="false"
            role="combobox"
            aria-autocomplete="list"
            dir="ltr"
            spellcheck="false"
            [(ngModel)]="searchQuery"
            (keydown.enter)="search()"
          />
        </mat-form-field>
      </form>
      <div class="searchbox-searchbutton-container">
        <button class="searchbox-searchbutton" (click)="search()">
          <mat-icon aria-label="Buscar">search</mat-icon>
        </button>
      </div>
    </div>
  </div>
</aside>
