import { Component, OnInit } from '@angular/core';
import { CreateStudyService } from '../../../../../../../libs/feature-create-study/src';
import { MapService } from '../../../../../../../libs/map/src';
import { Map } from '@compass/utils/leaflet';

@Component({
  selector: 'lab-traffic',
  templateUrl: './traffic.component.html',
  styleUrls: ['./traffic.component.scss']
})
export class TrafficComponent implements OnInit {
  coordsCenter: Array<number>;

  constructor(
    private mapService: MapService,
    private createStudyService: CreateStudyService
  ) {

  }

  ngOnInit(): void {
    this.coordsCenter = this.createStudyService.getCoordsMarker();

  }

}
