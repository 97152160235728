import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Pois } from './sidenav-pois-api.service';

@Injectable({ providedIn: 'root' })
export class SidenavPoisLevelsService {
  // CATEGORIES LIST
  // TOGGLE, loaders
  // GLOBAL POIS
  public categoriesList$: BehaviorSubject<any> = new BehaviorSubject<any>(undefined);
  public subcategoriesList$: BehaviorSubject<any> = new BehaviorSubject<any>(undefined);
  public companiesList$: BehaviorSubject<any> = new BehaviorSubject<any>(undefined);
  public placesList$: BehaviorSubject<any> = new BehaviorSubject<any>(undefined);

  // LOCAL POIS
  public localCategoriesList$: BehaviorSubject<any> = new BehaviorSubject<any>(undefined);
  public localSubcategoriesList$: BehaviorSubject<any> = new BehaviorSubject<any>(undefined);
  public localCompaniesList$: BehaviorSubject<any> = new BehaviorSubject<any>(undefined);
  public localPlacesList$: BehaviorSubject<any> = new BehaviorSubject<any>(undefined);

  // TRANSPORT LIST
  // TOGGLE, loaders
  public transportList$: BehaviorSubject<any> = new BehaviorSubject<any>(undefined);
  public subtransportList$: BehaviorSubject<any> = new BehaviorSubject<any>(undefined);
  public linesList$: BehaviorSubject<any> = new BehaviorSubject<any>(undefined);
  public lineList$: BehaviorSubject<any> = new BehaviorSubject<any>(undefined);


  // for toogle all values on empty ckeckboxes
  public parentCategory$: BehaviorSubject<any> = new BehaviorSubject<any>(undefined);
  public parentSubcategory$: BehaviorSubject<any> = new BehaviorSubject<any>(undefined);
  public parentCompany$: BehaviorSubject<any> = new BehaviorSubject<any>(undefined);

  // ACTIVE stage: global_pois, local_pois or transport
  public activeTableRoot$: BehaviorSubject<string> = new BehaviorSubject<string>('global_pois');
  // ACTIVE table level: 1, 2, 3, 4
  public activeTableLevel$: BehaviorSubject<number> = new BehaviorSubject<number>(1);
  // first Load Studio
  public isPoisFirstLoad$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  public isLocalFirstLoad$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  public isTransportFirstLoad$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

  constructor() { }

  // Independent Getters so save main list to ddbb
  // save-pois-to-db
  getCategoriesList() {
    return this.categoriesList$.asObservable();
  }

  getLocalCategoriesList() {
    return this.localCategoriesList$.asObservable();
  }
  getTransportList() {
    return this.transportList$.asObservable();
  }


  // NEW GETTERS
  // list loaders by table root value
  // first table level
  getCategoryList() {
    if (this.activeTableRoot$.value === 'global_pois') {
      return this.categoriesList$.asObservable();
    }
    if (this.activeTableRoot$.value === 'local_pois') {
      return this.localCategoriesList$.asObservable();
    }
    if (this.activeTableRoot$.value === 'transport') {
      return this.transportList$.asObservable();
    }

  }
  // second table level
  getSubcategoryList() {
    if (this.activeTableRoot$.value === 'global_pois') {
      return this.subcategoriesList$.asObservable();
    }
    if (this.activeTableRoot$.value === 'local_pois') {
      return this.localSubcategoriesList$.asObservable();
    }
    if (this.activeTableRoot$.value === 'transport') {
      return this.subtransportList$.asObservable();
    }

  }
  // third table level
  getCompanyList() {
    if (this.activeTableRoot$.value === 'global_pois') {
      return this.companiesList$.asObservable();
    }
    if (this.activeTableRoot$.value === 'local_pois') {
      return this.localCompaniesList$.asObservable();
    }
    if (this.activeTableRoot$.value === 'transport') {
      return this.linesList$.asObservable();
    }

  }
  // fourth table level
  getPlaceList() {
    if (this.activeTableRoot$.value === 'global_pois') {
      return this.placesList$.asObservable();
    }
    if (this.activeTableRoot$.value === 'local_pois') {
      return this.localPlacesList$.asObservable();
    }
    if (this.activeTableRoot$.value === 'transport') {
      return this.lineList$.asObservable();
    }

  }

  // parentCategory for breadcrumbs
  setParentRowByLevel(row): void {
    if (row.level === 'categories') {
      this.parentCategory$.next(row);
      return;
    }
    if (row.level === 'sub_categories') {
      this.parentSubcategory$.next(row);
      return;
    }
    if (row.level === 'companies') {
      this.parentCompany$.next(row);
      return;
    }
  }

  resetAllCategoriesList() {
    this.categoriesList$.next(undefined);
    this.subcategoriesList$.next(undefined);
    this.companiesList$.next(undefined);
    this.placesList$.next(undefined);
    this.localCategoriesList$.next(undefined);
    this.localSubcategoriesList$.next(undefined);
    this.localCompaniesList$.next(undefined);
    this.localPlacesList$.next(undefined);
    this.transportList$.next(undefined);
    this.subtransportList$.next(undefined);
    this.linesList$.next(undefined);
    this.lineList$.next(undefined);

  }

  // reset all parent nodes when stage starts (POIS or Transport)
  // On click the stage on the main menu
  // StagesMenuComponent
  resetParentCategories() {
    this.parentCategory$.next(undefined);
    this.parentSubcategory$.next(undefined);
    this.parentCompany$.next(undefined);
    return;
  }

  // for breadcrumbs
  getParentCategory() {
    return this.parentCategory$.asObservable();
  }
  getParentSubcategory() {
    return this.parentSubcategory$.asObservable();
  }
  getParentCompany() {
    return this.parentCompany$.asObservable();
  }

  // setCategory list by level
  // activeTableRoot is set when user clicks main left sidenav menu list.
  setActiveListByLevel(row): void {
    // GLOBAL POIS by row level
    if (this.activeTableRoot$.value === 'global_pois') {
      if (row.level === 'categories') {
        let subcategories = this.categoriesList$.value.filter(
          (d) => d.key === row.key
        )[0].values;
        this.subcategoriesList$.next(subcategories);
        return;
      }
      if (row.level === 'sub_categories') {
        let companies = this.subcategoriesList$.value.filter(
          (d) => d.key === row.key
        )[0].values;
        this.companiesList$.next(companies);
        return;
      }
      if (row.level === 'companies') {
        let places = this.companiesList$.value.filter(
          (d) => d.key === row.key
        )[0].values;
        this.placesList$.next(places);
        return;
      }
    }

    // LOCAL POIS by row level
    if (this.activeTableRoot$.value === 'local_pois') {
      if (row.level === 'categories') {
        let subcategories = this.localCategoriesList$.value.filter(
          (d) => d.key === row.key
        )[0].values;
        this.localSubcategoriesList$.next(subcategories);
        return;
      }
      if (row.level === 'sub_categories') {
        let companies = this.localSubcategoriesList$.value.filter(
          (d) => d.key === row.key
        )[0].values;
        this.localCompaniesList$.next(companies);
        return;
      }
      if (row.level === 'companies') {
        let places = this.localCompaniesList$.value.filter(
          (d) => d.key === row.key
        )[0].values;
        this.localPlacesList$.next(places);
        return;
      }
    }
    // TRANSPORT POIS by row level

    if (this.activeTableRoot$.value === 'transport') {
      if (row.level === 'categories') {
        let subcategories = this.transportList$.value.filter(
          (d) => d.key === row.key
        )[0].values;
        this.subtransportList$.next(subcategories);
        return;
      }
      if (row.level === 'sub_categories') {
        let companies = this.subtransportList$.value.filter(
          (d) => d.key === row.key
        )[0].values;
        this.linesList$.next(companies);
        return;
      }
      if (row.level === 'companies') {
        let places = this.linesList$.value.filter((d) => d.key === row.key)[0]
          .values;
        this.placesList$.next(places);
        return;
      }
    }

  }

  // to set Stage TABLE = global POIS, Local Pois, TRANSPORT...
  // activeTableRoot tells us in which section (stage) we are
  // (Global Pois, Transport, Local Pois)
  setActiveTableRoot(activeTableRoot: string): void {
    this.activeTableRoot$.next(activeTableRoot);
    return;
  }

  getActiveTableRoot() {
    return this.activeTableRoot$.asObservable();
  }

  // activeTableLevel tell us in which poi step (table) we are.
  // Table steps (1: categories, 2: subcategories, 3: companies, 4: places)
  setActiveTableLevel(activeTableLevel: number) {
    if (activeTableLevel < 1) activeTableLevel = 1;
    if (activeTableLevel > 4) activeTableLevel = 4;
    this.activeTableLevel$.next(activeTableLevel);
  }
  getActiveTableLevel() {
    return this.activeTableLevel$.asObservable();
  }

  // getIsFirstLoad()
  // Used in  POIS TABLE To filter POIS on firstLoad
  // this.activeTableLevel === 1 && this.isFirstLoad
  // isFirstLoad

  getIsFirstLoad() {
    if (this.activeTableRoot$.value === 'global_pois') {
      return this.isPoisFirstLoad$.asObservable();
    }
    if (this.activeTableRoot$.value === 'local_pois') {
      return this.isLocalFirstLoad$.asObservable();
    }
    if (this.activeTableRoot$.value === 'transport') {
      return this.isTransportFirstLoad$.asObservable();
    }

  }

  setIsFirstLoad(state: boolean): void {
    if (this.activeTableRoot$.value === 'global_pois') {
      this.isPoisFirstLoad$.next(state);
      return;
    }
    if (this.activeTableRoot$.value === 'local_pois') {
      this.isLocalFirstLoad$.next(state);
      return;
    }
    if (this.activeTableRoot$.value === 'transport') {
      this.isTransportFirstLoad$.next(state);
      return;
    }

  }

  // CATEGORIES LIST MAPPING HELPERS
  public getLeafNodes(nodes, result = []) {
    for (let i = 0, length = nodes.length; i < length; i++) {
      if (!nodes[i].values) {
        result.push(nodes[i]);
      } else {
        result = this.getLeafNodes(nodes[i].values, result);
      }
    }
    return result;
  }

  isRowToUncheck(rowNodes, selectedNodes) {
    let i,
      j,
      result = [];
    for (i = 0; i < rowNodes.length; i++) {
      for (j = 0; j < selectedNodes.length; j++) {
        if (rowNodes[i].key === selectedNodes[j].key) {
          result.push(rowNodes[i]);
        }
      }
    }
    return result.length < 1;
  }
  unCheckRow(row, nodes) {
    return nodes.map((node) => {
      row.forEach((sel) => {
        if (node.key === sel.key) {
          node.selected = false;
        }
      });
      return node;
    });
  }
  setNodesState(nodes, selectedNodes) {
    return nodes.map((node) => {
      selectedNodes.forEach((sel) => {
        if (node.key === sel.key) {
          node.selected = true;
        }
      });
      return node;
    });
  }
  getRowLevelKey(row) {
    return row.level === 'categories' ? 'key' : 'category';
  }
  reduceNodes(nodes, level, key) {
    return nodes.filter((node) => node.selected && node[level] === key);
  }
  mapCategoriesNodes(icategoriesList, nodes, row) {
    let k = this.getRowLevelKey(row);
    let trueNodes = nodes.filter((node) => node.selected);

    return icategoriesList.map((category) => {
      if (category.key === row[k]) {
        if (trueNodes.length === 0) {
          category.selected = false;
          category['values'] &&
            category['values'].forEach((sucategory) => {
              sucategory.selected = false;
              sucategory['values'] &&
                sucategory['values'].forEach((company) => {
                  company.selected = false;
                  company['values'] &&
                    company['values'].forEach((place) => {
                      place.selected = false;
                    });
                });
            });
        } // trueNodes.length === 0

        if (trueNodes && trueNodes.length > 0) {
          category.selected = true;
          category['values'] &&
            category['values'].forEach((subcategory) => {
              let subcategoriesNodes;

              // two levels categories
              if (
                this.activeTableRoot$.value === 'transport' ||
                this.activeTableRoot$.value === 'local_pois'
              ) {
                subcategoriesNodes = this.reduceNodes(
                  nodes,
                  'key',
                  subcategory.key
                );
              } else {
                // four levels categories
                subcategoriesNodes = this.reduceNodes(
                  nodes,
                  'subcategory',
                  subcategory.key
                );
              }
              subcategoriesNodes.length > 0
                ? (subcategory.selected = true)
                : (subcategory.selected = false);
              subcategory['values'] &&
                subcategory['values'].forEach((company) => {
                  let companiesNodes = this.reduceNodes(
                    nodes,
                    'company',
                    company.key
                  );
                  companiesNodes.length > 0
                    ? (company.selected = true)
                    : (company.selected = false);
                  company['values'] &&
                    company['values'].forEach((place) => {
                      let placesNodes = this.reduceNodes(
                        nodes,
                        'key',
                        place.key
                      );
                      placesNodes.length > 0
                        ? (place.selected = true)
                        : (place.selected = false);
                      return place;
                    });
                  return company;
                });
              return subcategory;
            }); // each subcategory
        } // trueNodes.length > 0
      } // if category.key === row[k]
      return category;
    }); // global map
  }

  updateRoot(row, selection, icategoriesList) {
    let k = this.getRowLevelKey(row);
    let icategory = icategoriesList.filter((i) => {
      return i.key === row[k];
    });
    let nodes = this.getLeafNodes(icategory);
    let rowNodes = !row.values ? [row] : this.getLeafNodes(row.values);

    if (selection.length > 0) {
      let selectedNodes = this.getLeafNodes(selection);
      let isRowToUncheck = this.isRowToUncheck(rowNodes, selectedNodes);
      if (isRowToUncheck) {
        nodes = this.unCheckRow(rowNodes, nodes);
      }
      nodes = this.setNodesState(nodes, selectedNodes);
    }
    if (selection.length === 0) {
      let iselectedNodes = !row.values ? [row] : this.getLeafNodes(row.values);
      nodes = this.unCheckRow(iselectedNodes, nodes);
    }

    let mappedCategoriesNodes = this.mapCategoriesNodes(
      icategoriesList,
      nodes,
      row
    );
    if (this.activeTableRoot$.value === 'global_pois') {
      this.categoriesList$.next(mappedCategoriesNodes);
    }
    if (this.activeTableRoot$.value === 'local_pois') {
      this.localCategoriesList$.next(mappedCategoriesNodes);
    }
    if (this.activeTableRoot$.value === 'transport') {
      this.transportList$.next(mappedCategoriesNodes);
    }

  } // updateRoot

  // toogle all data on click masterToggle
  toggleRoot(selection: Pois, icategoriesList, level) {
    let nodeList, subnodeList, parentList, childList;

    if (this.activeTableRoot$.value === 'global_pois') {
      nodeList = this.categoriesList$.value;
      subnodeList = this.subcategoriesList$.value;
      parentList = this.companiesList$.value;
      childList = this.placesList$;
    }

    if (this.activeTableRoot$.value === 'local_pois') {
      nodeList = this.localCategoriesList$.value;
      subnodeList = this.localSubcategoriesList$.value;
      parentList = this.localCompaniesList$.value;
      childList = this.localPlacesList$;
    }

    if (this.activeTableRoot$.value === 'transport') {
      nodeList = this.transportList$.value;
      subnodeList = this.subtransportList$.value;
      parentList = this.linesList$.value;
      childList = this.lineList$;
    }


    if (level === 1) {
      nodeList.forEach((row) => {
        this.updateRoot(row, selection, icategoriesList);
      });
      return;
    }

    if (level === 2) {
      subnodeList.forEach((row) => {
        this.updateRoot(row, selection, icategoriesList);
      });
      return;
    }

    if (level === 3) {
      parentList.forEach((row) => {
        this.updateRoot(row, selection, icategoriesList);
      });
      return;
    }
    if (level === 4) {
      childList.forEach((row) => {
        this.updateRoot(row, selection, icategoriesList);
      });
      return;
    }
  }
} // component CLASS :-)
