import { Injectable } from "@angular/core";
import { Map, CompassMapWrapperService } from './compass-map-wrapper.service';
import { HttpClient } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import * as L from 'leaflet';


// https://angular.io/guide/http
// https://www.techiediaries.com/angular-11-tutorial-example-rest-crud-http-get-httpclient/
// https://gist.github.com/rclark/6908938

@Injectable()
export class FeatureInfoWMSService {

    map$ = this.compassMapWrapperService.map$;
    box;
    url: string;
    loc: Array<number>;
    showResults: any;

    constructor(
        private http: HttpClient,
        private compassMapWrapperService: CompassMapWrapperService
    ) { }

    private getLoc(e) {
        return e.latlng;
    }
    // https://www.euskadi.eus/contenidos/noticia/n_presentaciones_curso_upv_15/es_def/adjuntos/Sesion3_CursoVeranoUPV_EHU_geoEuskadi_DatosyGeoservicios.pdf
    private buildURL(event, wmsLayer) {
        this.loc = this.getLoc(event);
        let xy = event.containerPoint; // xy = map.latLngToContainerPoint(loc,map.getZoom())
        let size = this.box.getSize();
        let bounds = this.box.getBounds();
        let zoom = this.box.getZoom();
        let url = wmsLayer._url;
        let crs = this.box.options.crs || this.box.options.crs; // me._crs
        let sw = crs.project(bounds.getSouthWest());
        let ne = crs.project(bounds.getNorthEast());
        let params = wmsLayer.wmsParams;
        let obj = {
            SERVICE: "WMS", // WMS (default)
            REQUEST: "GetFeatureInfo",
            VERSION: params.version, // 1.1.1
            LAYERS: params.layers,
            STYLES: params.styles ? params.styles : "",
            FORMAT: params.format,
            TRANSPARENT: params.transparent,
            TILED: false,
            INFO_FORMAT: "text/html", // text/plain (default), application/json for JSON (CORS enabled servers), text/javascript for JSONP (JSONP enabled servers)
            MAXNATIVEZOOM: params.maxNativeZoom ? params.maxNativeZoom : 19,
            WIDTH: size.x,
            HEIGHT: size.y,
            // bbox: bounds.toBBoxString(), // works only with EPSG4326, but not with EPSG3857
            BBOX: sw.x + "," + sw.y + "," + ne.x + "," + ne.y, // works with both EPSG4326, EPSG3857
            QUERY_LAYERS: params.layers,
            FEATURE_COUNT: 5 // 1 (default)
            // exceptions: 'application/vnd.ogc.se_xml', // application/vnd.ogc.se_xml (default)
            // format_options: 'callback: parseResponse' // callback: parseResponse (default), use only with JSONP enabled servers, when you want to change the callback name
        };
        if (parseFloat(params.version) >= 1.3) {
            obj['CRS'] = crs.code; // params.crs
            obj['I'] = xy.x;
            obj['J'] = xy.y;
        } else {
            obj['SRS'] = crs.code; // params.srs
            obj['X'] = Math.round(xy.x);
            obj['Y'] = Math.round(xy.y);
        }
        url = url + L.Util.getParamString(obj, url, true);
        return url;
    }


    showGetFeatureInfo(err, latlng, content: string, map) {
        if (err) { console.log(err); return; } // do nothing if there's an error

        // Otherwise show the content in a popup, or something.
        L.popup({ maxWidth: 320, className: "catastro-popup" })
            .setLatLng(latlng)
            .setContent(content)
            .openOn(map);
    }


    public get = (event, wmsLayer) => {
        this.box = this.map$.value.box;
        this.url = this.buildURL(event, wmsLayer);
        this.loc = this.loc ? this.loc : this.getLoc(event);
        this.showResults = L.Util.bind(this.showGetFeatureInfo, wmsLayer);

        this.http.get(this.url, { responseType: 'text' }).subscribe((data) => {
            let info = data.replace(/<script\b[^<]*(?:(?!<\/script>)<[^<]*)*<\/script>/gi, "");
            info = info.replace(/<a\s+href=/gi, "<a target='_blank' href=");
            info = "<div class='catastro-popup-content'>" + info + "</div>";
            let err = typeof info === 'string' ? null : info;
            this.showResults(err, event.latlng, info, this.box);
        });
    }
}
