import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'compass-page-wrapper',
  templateUrl: './page-wrapper.component.html',
  styleUrls: ['./page-wrapper.component.scss']
})
export class PageWrapperComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
