import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from '@compass/material';
import { ReactiveFormsModule } from '@angular/forms';
import { UsersComponent } from './users.component';
import { UserService } from './user.service';
import { RouterModule } from '@angular/router';
import { UserDetailComponent } from './user-detail/user-detail.component';
import { UserFormComponent } from './user-form/user-form.component';
import { SharedCrudElementsModule } from '@compass/shared/crud-elements';
import { SharedFeatureManagementTitleModule } from '@compass/shared/feature-management-title';
import { UiManagementTableModule } from '@compass/ui-management-table';

@NgModule({
  imports: [
    CommonModule,
    MaterialModule,
    RouterModule,
    RouterModule.forChild([
      { path: 'detalle/:username', component: UserDetailComponent },
      { path: 'agregar', component: UserDetailComponent },
    ]),
    ReactiveFormsModule,
    SharedCrudElementsModule,
    SharedFeatureManagementTitleModule,
    UiManagementTableModule,
  ],
  declarations: [UsersComponent, UserFormComponent, UserDetailComponent],
  providers: [UserService],
  exports: [UsersComponent, UserDetailComponent],
})
export class SharedFeatureUserManagementModule {}
