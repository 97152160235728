<div class="container padding__basic">
  <form [formGroup]="businessForm" target="_blank" class="business-form" id="businessForm" name="businessForm"
        (ngSubmit)="onSubmit()">

    <p class="margin__top--s"><strong>Filtrar por clientes</strong></p>
    <mat-button-toggle-group name="fontStyle" aria-label="Font Style" formControlName="cliente">
      <mat-button-toggle [value]="null">Todos</mat-button-toggle>
      <mat-button-toggle [value]="true">Clientes</mat-button-toggle>
      <mat-button-toggle [value]="false">No clientes</mat-button-toggle>
    </mat-button-toggle-group>

    <div class="container margin__top--s">
      <div fxFlex="100" fxLayout="row">
        <div class="container">
          <strong>Profesiones</strong>
          <input #cnaeInput class="descripcion_cnae" type="text" placeholder="Buscar actividad"
                 formControlName="descripcion_cnae" [matAutocomplete]="auto">

          <mat-autocomplete #auto="matAutocomplete" (optionSelected)="addSelectedCnaeOption()">
            <mat-option *ngFor="let cnae_option of filtered_cnae_options" [value]="cnae_option">
              {{cnae_option}}
            </mat-option>
          </mat-autocomplete>

          <div class="margin__top--xs  margin__bottom--s">
            <button mat-flat-button color="warn" class="button--xs" (click)="addSelectedCnaeOption()">
              Añadir
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="container">
      <div fxFlex="100" fxLayout="row">
        <div class="margin__top--xs container">
          <div class="checkbox-container">

            <div *ngFor="let option of selected_cnae_options; let i=index"
                 [title]="option !== 'Todas' ? 'Quitar del filtrado la opción ' + option : ''">
              <mat-checkbox *ngIf=" option !== cnae_all" formArrayName="selected_cnae" [checked]=true
                            [value]=option (change)="changeCnaeCheckBox($event)">{{option}}
              </mat-checkbox>
              <mat-checkbox *ngIf="option === cnae_all" formArrayName="selected_cnae" [checked]=true
                            [value]=option [disabled]="true" (change)="changeCnaeCheckBox($event)">{{option}}
              </mat-checkbox>
            </div>

          </div>

        </div>
      </div>
    </div>


    <div class="container margin__top--xs  margin__bottom--s">
      <div fxFlex="100" fxLayout="row">
        <div class="margin__top--xs container">
          <p><strong>Ratio de morosidad</strong></p>
          <!--<compass-range-slider [setup]="rangeSliderMorosidad"></compass-range-slider>-->
          <compass-bar-chart (brushEnd)="this.morosidadChanged($event)"></compass-bar-chart>
        </div>
      </div>
    </div>


    <div class="container">
      <div fxLayout="row" fxLayoutAlign="center center" class="margin__top--s">
        <button mat-flat-button class="button--xl lightgray" type="reset" (click)="resetForm($event)">
          <i class="material-icons sub-icons small"> refresh </i>
          Reiniciar
        </button>
      </div>
    </div>
  </form>
</div>
