import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { Observable } from 'rxjs/Observable';
import { StartTightStage } from '@compass/page-navigation/start-tight-menu';


@Injectable({ providedIn: 'root' })
export class BusinessViewerService {

  private stage: StartTightStage = {
    'key': 'filter',
    'name': 'Fitros',
    'icon': 'filter_alt',
    'active': true,
    'path': '/business'
  };
  public mapIsOn$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  public activeStage$: BehaviorSubject<StartTightStage> = new BehaviorSubject<StartTightStage>(this.stage);

  constructor(public http: HttpClient, private router: Router) {
  }

  // map observables
  setMapIsOn(bool) {
    if (bool !== this.mapIsOn$.value) {
      this.mapIsOn$.next(bool);
    }
  }

  getMapIsOn(): Observable<boolean> {
    return this.mapIsOn$.asObservable();
  }

  setActiveStage$(stage: StartTightStage) {
    if (stage.key !== this.activeStage$.value.key) {
      this.activeStage$.next(stage);
    }
  }

  getActiveStage$(): Observable<StartTightStage> {
    return this.activeStage$.asObservable();
  }

}
