import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Route } from '@angular/router';

import { MaterialModule } from '@compass/material';
import { FormsModule } from '@angular/forms';


import { SearchboxService } from './searchbox.service';
import {MapSearchboxComponent} from './map-searchbox.component';

export const featureSearchboxRoutes: Route[] = [];

@NgModule({
  imports: [CommonModule, MaterialModule,FormsModule,
  
    RouterModule,],
  declarations: [MapSearchboxComponent],
  exports: [MapSearchboxComponent,FormsModule],
  providers: [SearchboxService]
})
export class FeatureSearchboxModule {}
