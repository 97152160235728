import { Component, Output } from '@angular/core';
import { OnChanges } from '@angular/core';
import { Input } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { FormBuilder } from '@angular/forms';
import { Validators } from '@angular/forms';
import { UserService } from '../user.service';
import { Router } from '@angular/router';

@Component({
  selector: 'user-form',
  templateUrl: './user-form.component.html',
  styleUrls: ['./user-form.component.scss']
})
export class UserFormComponent implements OnChanges {
  @Input() user: any;
  @Input() redirectUrl: string = 'usuarios';

  newUserForm: FormGroup;
  menuItems: Array<any>;
  submitted: boolean = false;

  constructor(
    private formBuilder: FormBuilder,
    private userService: UserService,
    private router: Router
  ) {
  }

  onSubmit() {
    if (this.user) {
      this.userService.updateUser(this.user.username, this.newUserForm.value)
        .subscribe(() => this.goBack());
    } else {
      this.userService.addUser(this.newUserForm.value)
        .subscribe(() => this.goBack());
    }
  }

  goBack() {
    this.router.navigate([this.redirectUrl]);
  }

  resetForm(event) {
    event.preventDefault();
    this.newUserForm.reset();
    this.submitted = false;

    // if editing user set default values
    if(this.user) {
      this.newUserForm.setValue({
        role: this.user.role,
        username: this.user.username,
        email: this.user.email || '',
      });

      this.newUserForm.updateValueAndValidity();
    }
  }

  ngOnChanges() {
    this.newUserForm = this.formBuilder.group({
      role: [this.user?.role, Validators.required],
      username: [this.user?.username, Validators.required],
      email: [this.user?.email, [Validators.required, Validators.email]]
    });

    if (!this.user) {
      this.newUserForm.addControl(
        'password',
        new FormControl(['', [Validators.required, Validators.minLength(8)]])
      );
    }
  }
}
