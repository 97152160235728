import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';
import { Observable } from 'rxjs/Observable';
import { StartLeftSidenavService } from '@compass/page-navigation/start-left-sidenav';

@Injectable({ providedIn: 'root' })
export class SideNavStatusService {

  public studyFormNavOn: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public stagesNavOn: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public stageViewerOn: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public subcategoriesNavOn: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public companiesNavOn: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public placesNavOn: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public stagesNavOnLoading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);



  public studyButtonCreateNavOn: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  // studyFormNavOn: open and close base sidenav if new study is on.
  // stagesNavOn: toggle new study and stages.
  // stageViewerOn:

  constructor(
    private http: HttpClient,
    private startLeftSidenavService: StartLeftSidenavService
  ) {
    this.startLeftSidenavService.setCompassStatus(JSON.parse(localStorage.getItem('compassIsOn')));
    this.startLeftSidenavService.setSidenavIsOpen(JSON.parse(localStorage.getItem('sidenavIsOpen')));
    this.studyFormNavOn.next(JSON.parse(localStorage.getItem('studyFormNavOn')));
    this.stagesNavOn.next(JSON.parse(localStorage.getItem('stagesNavOn')));
    this.studyButtonCreateNavOn.next(JSON.parse(localStorage.getItem('studyButtonCreateNavOn')));
  }


  // global helper
  resetAllSidenavs() {
    this.startLeftSidenavService.setCompassStatus(false);
    this.startLeftSidenavService.setSidenavIsOpen(false);
    this.setStudyFormStatus(false);
    this.setStagesNavStatus(false);
    this.setStageViewerStatus(false);
    this.setSubcategoriesNavStatus(false);
    this.setCompaniesNavStatus(false);
    this.setPlacesNavStatus(false);
    this.setStudyButtonCreateStatus(false);
    this.setStagesNavOnLoading(false);
  }

  /* MAIN STATUS FUNCTIONS */



  /** Study Form opens*/
  setStudyFormStatus(status) {
    if (typeof status === 'boolean') { localStorage.setItem('studyFormNavOn', JSON.stringify(status)); this.studyFormNavOn.next(status); }
  }

  getStudyFormStatus(): Observable<boolean> {
    return this.studyFormNavOn.asObservable();
  }

  /** Toggle between General Stages menu and Study Menu */

  setStagesNavStatus(status) {
    if (typeof status === 'boolean') { localStorage.setItem('stagesNavOn', JSON.stringify(status)); this.stagesNavOn.next(status); }
  }
  getStagesNavStatus(): Observable<boolean> {
    return this.stagesNavOn.asObservable();
  }

  setStagesNavOnLoading(status) {
    if (typeof status === 'boolean') { localStorage.setItem('stagesNavOnLoading', JSON.stringify(status)); this.stagesNavOnLoading.next(status); }
  }
  getStagesNavOnLoading(): Observable<boolean> {
    return this.stagesNavOnLoading.asObservable();
  }

  /** The content of each stage (content of POIS, content of Indicators...) */
  setStageViewerStatus(status) {
    this.stageViewerOn.next(status);
  }
  getStageViewerStatus(): Observable<boolean> {
    return this.stageViewerOn.asObservable();
  }

  /** List of local subcategories (hiper, super...) */
  setSubcategoriesNavStatus(status) {
    this.subcategoriesNavOn.next(status);
  }
  getSubcategoriesNavStatus(): Observable<boolean> {
    return this.subcategoriesNavOn.asObservable();
  }

  /** List of local Pois (vips, mcDonalds, Burger King) */
  setCompaniesNavStatus(status) {
    this.companiesNavOn.next(status);
  }
  getCompaniesNavStatus(): Observable<boolean> {
    return this.companiesNavOn.asObservable();
  }
  /** List of local Pois (vips, mcDonalds, Burger King) */
  setPlacesNavStatus(status) {
    this.placesNavOn.next(status);
  }
  getPlacesNavStatus(): Observable<boolean> {
    return this.placesNavOn.asObservable();
  }

  setStudyButtonCreateStatus(status) {
    if (typeof status === 'boolean') { localStorage.setItem('studyButtonCreateNavOn', JSON.stringify(status)); this.studyButtonCreateNavOn.next(status); }
  }
  getStudyButtonCreateStatus(): Observable<boolean> {
    return this.studyButtonCreateNavOn.asObservable();
  }
}
