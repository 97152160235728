import { Component, OnInit } from '@angular/core';
import { StudyService } from '@compass/studies';
import { StudyInterface } from '../../../../../../libs/studies/src/lib/study.model';
import { Router } from '@angular/router';

@Component({
  selector: 'lab-studies-list',
  templateUrl: './studies-list.component.html',
  styleUrls: ['./studies-list.component.scss']
})
export class StudiesListComponent implements OnInit {
  studies: StudyInterface[];

  constructor(
    private studyService: StudyService,
    private router: Router
  ) {
    this.studyService.studies$.subscribe(res => this.studies = res);
  }

  ngOnInit(): void {
  }

  openStudy(study: any) {
    this.router.navigateByUrl(`/`, {skipLocationChange: true}).then(()=> {
      this.router.navigate(['/studies', study.properties.id]);
    });
  }

}
