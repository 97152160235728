import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { AuthService } from '@compass/shared/feature-auth';
import { catchError, map } from 'rxjs/operators';

export interface Study {
  id: number;
  name: string;
  creator: string;
  date: string;
}

@Injectable({
  providedIn: 'root'
})
export class StudyService {
  private studiesUrl = `/abacusgis/study/glocally/point_study/`;

  public studies$ = new BehaviorSubject(undefined);
  public study$ = new BehaviorSubject(undefined);

  constructor(private http: HttpClient, private authService: AuthService) {}

  getAll() {
    this.studies$.next(undefined);
    const currentUser = this.authService.currentUser$.value;
    let queryUser = '';
    if (currentUser.role !== 'admin') {
      queryUser = `query={ "properties.study.username" : "${currentUser.username}" };`;
    }
    return this.http
      .get(
        `${this.studiesUrl}?${queryUser}fields=properties.id,properties.study.name,properties.study.created,properties.study.username,properties.study.study_id`
      )
      .pipe(
        map((studies: any[]) =>
          studies.map((p) => {
            let x = studies;

            const study = p.properties.study;
            study.id = p.properties.id;
            // remove seconds and milliseconds
            study.created = study.created.substring(0, 16);
            return study;
          })
        )
      )
      .subscribe((mappedStudies: Study[]) => this.studies$.next(mappedStudies));
  }

  updateStudy(id: any, property_name: any, data: any): Observable<any> {
    const body = `{
      "$set": { "${property_name}" : ${JSON.stringify(data)} }
    }`;
    return this.http
      .put(`${this.studiesUrl}${id}`, body)
      .pipe(catchError(this.handleError));
  }

  deleteStudy(id: number) {
    return this.http.delete(`${this.studiesUrl}${id}`).subscribe((d: any) => {
      this.getAll();
    });
  }

  deleteStudyBy(id: number) {
    return this.http.delete(`${this.studiesUrl}${id}`);
  }

  getListStudies() {
    const currentUser = this.authService.currentUser$.value;

    return this.http.get(
      `${this.studiesUrl}?query={"properties.study.username":"${currentUser.username}"};fields=properties.id,properties.study.name,properties.study.created,properties.study.username,properties.study.study_id`
    );
  }

  getInfoStudy(studyId: any): Observable<any> {
    const req = this.http.get(`${this.studiesUrl}` + studyId + '/');
    req.subscribe(study => {
      this.study$.next(study);
    });

    return req;
  }

  handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Code returned: ${error.status}, ` + `body was: ${error.error}`
      );
    }
    // return an observable with a user-facing error message
    return throwError('Something bad happened; please try again later.');
  }
}
