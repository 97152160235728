import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { HasEvents } from '../../../map/src/lib/classes/has-events';

/*
export interface Searchbox {
  type: string;
  properties: PoiProperty;
  geometry: PoiGeometry;
}
*/


@Injectable({
  providedIn: 'root'
})
export class SearchboxService {
  public visible$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  private searchboxDescriptive$ = new Subject<any>();
  searchboxDescriptiveChanged$ = this.searchboxDescriptive$.asObservable();


  constructor() {
  }

  /**
   * Make a search on map.
   *
   * @param searchString
   */
  search(searchString: string) {
    this.searchboxDescriptive$.next(searchString);
  }

  public getVisible() {
    return this.visible$.asObservable();
  }

  public toggle(visible: boolean): void {
    this.visible$.next(visible);
  }

}
