import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  TopToolbarWrapperComponent,
  Toolbar,
} from './top-toolbar-wrapper/top-toolbar-wrapper.component';
import { MaterialModule } from '../../../../material/src/lib/material.module';
import { RouterModule } from '@angular/router';

@NgModule({
  imports: [CommonModule, MaterialModule, RouterModule],
  exports: [TopToolbarWrapperComponent],
  declarations: [TopToolbarWrapperComponent],
  providers: [],
})
export class TopToolbarWrapperModule { }
