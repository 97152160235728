import { Component, OnInit } from '@angular/core';
import {
  Indicator,
  IndicatorGroupSelected,
  IndicatorService,
  SideNavIndicatorsService
} from '../../../../../../../../libs/utils/navigation/src';
import { BehaviorSubject } from 'rxjs';
import { BricksApiService } from '../../../../../../../../libs/brick-api/src';
import { MapService } from '../../../../../../../../libs/map/src';
import { FormControl, FormGroup } from '@angular/forms';
import { CompassMapIndicatorsService } from '../../../../../../../../libs/utils/leaflet/src';
import { SidenavService } from '../../../../../../../../libs/page-navigation/sidenav/src/lib/sidenav.service';
import { EndRightSidenavService } from '../../../../../../../../libs/page-navigation/end-right-sidenav/src';


@Component({
  selector: 'lab-indicators',
  templateUrl: './indicators.component.html',
  styleUrls: ['./indicators.component.scss']
})
export class IndicatorsComponent implements OnInit {
  indicatorGroupSelected: any;
  indicatorSelected$: BehaviorSubject<any>;

  bricks;

  formGroup: FormGroup;

  categories; // indicators categories
  indicators; // indicators list
  indicatorOptions: []; // indicator subcategories

  briks;

  constructor(
    private sideNavIndicatorsService: SideNavIndicatorsService,
    private indicatorService: IndicatorService,
    private compassMapIndicatorsService: CompassMapIndicatorsService,
    private bricksApiService: BricksApiService,
    private mapService: MapService,
    private sidenavService: SidenavService,
    private rightSidenavService: EndRightSidenavService
  ) {

    this.formGroup = new FormGroup({
      'category': new FormControl(this.sideNavIndicatorsService.indicatorGroupSelected$.value),
      'indicator': new FormControl()
    });

    this.compassMapIndicatorsService.setMap(this.mapService.map);

    this.sideNavIndicatorsService.getGeoIndicatorsApi();
    this.sideNavIndicatorsService.indicatorsGroups$.subscribe(groups => {
      if (groups) {
        this.categories = groups;
        this.formGroup.patchValue({
          // @ts-ignore
          'category': groups[0]
        });
      }
    });

    this.sideNavIndicatorsService.getIndicatorGroupSelected().subscribe((value) => {
      if (value) {
        const val: any = value; // pinopuente to avoid IndicatorGroupSelected interface
        this.indicatorGroupSelected = val;

        this.formGroup.patchValue({
          'category': val.category
        });

        this.indicatorService.setActiveIndicator(this.indicatorGroupSelected?.indicator.values[0]);
      }
    });

    this.bricksApiService.getFullBricks().subscribe((d) => {
      this.bricks = d;
    });
  }

  ngOnInit(): void {
    this.mapService.drawerService
      .removeGeojsonLayer('geojsonLayer')
      .removeGeojsonLayer('color_key');

    this.bricksApiService.bricks$.subscribe(bricks => {
      this.bricks = bricks;
    });

    this.formGroup.get('category').valueChanges.subscribe((value) => {
      this.indicators = value.values;
      this.formGroup.controls['indicator'].setValue(this.indicators[0]);
    });

    this.formGroup.valueChanges.subscribe(indicatorGroup => {
      this.mapService.drawerService.removeGeojsonLayer('geojsonLayer');
      this.mapService.drawerService.removeGeojsonLayer('color_key');

      this.indicatorOptions = indicatorGroup.indicator?.values;
      //
    });

  }

  onClickIndicator(e: Event, indicator: Indicator) {
    e.preventDefault();

    this.sidenavService.loading(true);
    this.indicatorService.setActiveIndicator(indicator).subscribe(indicator => {
      this.compassMapIndicatorsService.bricks = this.bricks;

      this.mapService.drawerService
        .removeGeojsonLayer('geojsonLayer')
        .removeGeojsonLayer('color_key');

      // this.mapService.setMapCenter([40.434176, -3.735995]);

      if (!this.compassMapIndicatorsService.minMaxIndicatorsScaleExist()) {
        this.compassMapIndicatorsService.setMinMaxIndicatorScale();
        try {
          this.compassMapIndicatorsService.setAnalyticsIndicators(
            this.mapService.map.minMaxScales,
            this.sideNavIndicatorsService.indicatorsDictionary$.value
          );

          this.rightSidenavService.setSidenavStatus(true);

        } catch (err) {

        }
      }


      this.compassMapIndicatorsService.drawGeojsonLayer();
      //this.setView(this.map);
      this.compassMapIndicatorsService.drawColorKeys();
      this.compassMapIndicatorsService.fitGeojsonBounds();

      this.sidenavService.loading(false);
    });
  }


}
