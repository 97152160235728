<ng-template #loading>
  <mat-progress-bar mode="indeterminate"></mat-progress-bar>
</ng-template>
<management-title title="Usuarios" icon_class="icon-usuarios">
  <div>
    <button
      mat-flat-button
      color="primary"
      class="button--m"
      title="Añadir Usuario"
      [routerLink]="[this.createUrl]"
    >
      <i class="material-icons sub-icons small">playlist_add</i>
      Usuario
    </button>
  </div>
</management-title>
<compass-table
  *ngIf="users$ | async as users; else loading"
  [items]="users"
  [displayedColumns]="displayedColumns"
  toErase="este usuario"
  url="usuarios"
  [editUrl]="this.editUrl"
  (dialogChange)="changeFromDialog($event)"
></compass-table>
