import { AfterViewInit, ChangeDetectorRef, Component, Input, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { SidenavService } from '../sidenav.service';
import { SidenavConfig } from '../interfaces/sidenav-config';
import { SidenavOption } from '../interfaces/sidenav-option';
import { Router } from '@angular/router';
import { StudyService } from '@compass/studies';

@Component({
  selector: 'compass-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss']
})
export class SidenavComponent implements OnInit, AfterViewInit {
  @Input() setup: SidenavConfig;
  @ViewChild('sidenavDrawer') sidenavElement;
  @ViewChild('sidenavContainer', { read: ViewContainerRef }) sidenavContainer;


  opened: boolean = false;
  actualComponent: any = null;
  menuOptions: Array<SidenavOption> = [];
  loading;
  fullLoading;
  historic = [];

  actualStudy: any;

  constructor(
    private sidenavService: SidenavService,
    private router: Router,
    private cdRef: ChangeDetectorRef,
    private studyService: StudyService
  ) {
    this.loading = this.sidenavService.loading$.value;
    this.fullLoading = this.sidenavService.fullLoading$.value;

    this.studyService.study$.subscribe((study) => {
      this.actualStudy = study;
    });
  }

  ngAfterViewChecked() {
    this.cdRef.detectChanges();
  }

  ngOnInit(): void {
    this.sidenavService.menuOptions.asObservable().subscribe(options => {
      setTimeout(() => {
        this.menuOptions = options;
      });
    });
    // subscribe for actualComponent
    this.sidenavService.getActualComponent().subscribe(component => this.actualComponent = component);
    this.sidenavService.componentsHistory.asObservable().subscribe(val => {
      setTimeout(() => this.historic = val);
    });
    this.opened = this.sidenavService.isActive();

    setTimeout(() => {
      this.sidenavService.loading$.subscribe(val => this.loading = val);
      this.sidenavService.fullLoading$.subscribe(val => this.fullLoading = val);
    }, 300);
  }

  ngAfterViewInit() {
    // subscribe to status
    this.sidenavService.getStatus().subscribe((status) => {
      this.opened = status;
      this.sidenavElement.toggle(status);
    });

    this.sidenavService.setSidenavContainerRef(this.sidenavContainer);
  }

  optionClicked(option) {
    this.sidenavService.setSelectedOption(option);
  }

  close() {
    this.sidenavService.toggle(false);
  }

  goBack() {
    this.sidenavService.backInHistory();
  }

  openActualStudy() {
    if (this.actualStudy) {
      this.sidenavService.loadStudyPreview(this.actualStudy);
    }
  }

}
