import { Component, OnInit, Input } from '@angular/core';
import { SidenavService } from '../../../../../page-navigation/sidenav/src/lib/sidenav.service';
import { UserService } from '@compass/shared/feature-user-management';
import { AuthService, User } from '@compass/shared/feature-auth';

export interface Style {
  height: string;
}

export interface Img {
  class: string;
  src: string;
  alt: string;
  width: string;
}

export interface Toolbar {
  title: string;
  style: Style;
  class: string;
  img: Img;
}

@Component({
  selector: 'compass-top-toolbar-wrapper',
  templateUrl: './top-toolbar-wrapper.component.html',
  styleUrls: ['./top-toolbar-wrapper.component.scss']
})
export class TopToolbarWrapperComponent implements OnInit {
  @Input() toolbarSetUp: Toolbar;
  showMenuBtn: boolean = false;
  menuIsOppened: boolean = false;

  authUser: User;
  isAdmin: boolean = false;

  constructor(
    private sidenavService: SidenavService,
    private authService: AuthService,
  ) {
  }

  ngOnInit(): void {
    this.sidenavService.getButtonStatus().subscribe(val => this.showMenuBtn = val);
    this.sidenavService.getStatus().subscribe(val => this.menuIsOppened = val);

    this.authUser = this.authService.currentUser$.value;
    // @ts-ignore
    this.isAdmin = (this.authUser.role === 'admin' || this.authUser.role === 'superuser');
  }

  logout() {
    this.authService.logout();
  }
}
