import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EndRightSidenavBaseComponent } from './end-right-sidenav-base/end-right-sidenav-base.component';
import { EndRightButtonCloseComponent } from './end-right-button-close/end-right-button-close.component';
import { EndRightSidenavService } from './end-right-sidenav.service';
import { MaterialModule } from '@compass/material'

@NgModule({
  declarations: [EndRightSidenavBaseComponent, EndRightButtonCloseComponent],
  imports: [CommonModule, MaterialModule],
  exports: [EndRightSidenavBaseComponent, EndRightButtonCloseComponent],
  providers: [EndRightSidenavService]
})
export class EndRightSidenavModule { }