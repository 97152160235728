import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../shared/shared.module';
import { BusinessRoutingModule } from './business-routing.module';
import { BusinessComponent } from './business.component';
import { MapModule, MapService } from '@compass/map';

@NgModule({
  declarations: [BusinessComponent],
  imports: [
    CommonModule,
    SharedModule,
    BusinessRoutingModule,
    MapModule
  ],
  exports: [],
  providers: [MapService]
})
export class BusinessModule {
}
