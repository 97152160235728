import { Component } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router, NavigationEnd, Event } from '@angular/router';
import { environment } from '../environments/environment';
import { StartLeftSidenavService } from '@compass/page-navigation/start-left-sidenav';
@Component({
  selector: 'compass-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  title = 'Compass Lab App';
  showLayout: boolean;


  constructor(
    private router: Router,
    private titleService: Title,
    private startLeftSidenavService: StartLeftSidenavService
  ) {
    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
        this.showLayout = this.router.url !== '/login';
      }
    });
    this.titleService.setTitle(`${environment.title} --> ${this.title}`);

  }
}
