import { Component, OnInit } from '@angular/core';
import { BusinessApiService } from '@compass/business';
import { BusinessViewerService } from '@compass/business';
import { StartTightStage, StartTightSidenavService } from '@compass/page-navigation/start-tight-menu';
import { OnclickStageShapeService } from '@compass/utils/navigation';

@Component({
  selector: 'lab-business',
  templateUrl: './business.component.html',
  styleUrls: ['./business.component.css']
})
export class BusinessComponent implements OnInit {
  mapIsOn: boolean = true;
  business$ = this.businessApiService.business$;
  activeStage: StartTightStage;
  clickedShape: string;
  lateral_menu: StartTightStage[] = [
    {
      'key': 'filter',
      'name': 'Fitros',
      'icon': 'filter_alt',
      'active': true,
      'path': '/business'
    },
    {
      'key': 'map',
      'name': 'Mapa',
      'icon': 'map',
      'active': false,
      'path': '/business'
    },
    {
      'key': 'table',
      'name': 'Tabla',
      'icon': 'table_chart',
      'active': false,
      'path': '/business'
    },
    {
      'key': 'indicators',
      'name': 'Indicadores',
      'icon': 'data_exploration',
      'active': false,
      'path': '/business'
    }
  ];
  isOpen: boolean;


  constructor(
    private businessApiService: BusinessApiService,
    private businessViewerService: BusinessViewerService,
    private onclickStageShapeService: OnclickStageShapeService,
    private startTightSidenavService: StartTightSidenavService
  ) {

    this.businessViewerService.getMapIsOn().subscribe((d) => {
      this.mapIsOn = d;
    });

    this.businessViewerService.getActiveStage$().subscribe((stage: StartTightStage) => {
      this.activeStage = stage;
    });

    this.onclickStageShapeService.getShape().subscribe((d: any) => {
      this.clickedShape = d;
    });

    this.startTightSidenavService.getIsOpen().subscribe((d) => {
      this.isOpen = d;

    });
  }

  isFilterStage(stage) {
    if (stage.key === 'filter' || stage.key === 'map' || stage.key === 'table') {
      return true;
    }
    return false;
  }

  ngOnInit(): void {
    this.businessViewerService.setActiveStage$(this.lateral_menu[0]);
    this.businessApiService.getBusinessData().subscribe((d) => {
      this.business$.next(d); // overall data
    });
  }

}
