<section>
  <div class="container ul__list">

    <form [formGroup]="formGroup">
      <!-- CATEGORIAS -->
      <mat-form-field appearance="fill">
        <mat-label>Categoría</mat-label>
        <mat-select formControlName="category" required>
          <mat-option *ngFor="let option of categories" [value]="option">
            {{ option.screen_name }}
          </mat-option>
        </mat-select>

      </mat-form-field>

      <!-- INDICADORES -->
      <mat-form-field appearance="fill">
        <mat-label>Indicadores</mat-label>
        <mat-select formControlName="indicator" required>
          <mat-option *ngFor="let child of indicators" [value]="child">
            {{ child.screen_name }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <!-- SUBCATEGORIAS --->
      <mat-selection-list [multiple]="false">
        <mat-list-option (click)="onClickIndicator($event, indicator)" *ngFor="let indicator of indicatorOptions" [value]="indicator">
          <p ><small>{{ indicator?.screen_name }}</small></p>
        </mat-list-option>
      </mat-selection-list>

    </form>



    <!-- <p><small> Option selected: {{indicators.selectedOptions.selected[0]?.value}}</small></p> -->

  </div>
</section>
