import { Injectable } from "@angular/core";
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject } from "rxjs";
import { Observable } from 'rxjs/Observable';

@Injectable({ providedIn: "root" })
export class StartTightSidenavService {
  public isOpen$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(undefined);

  constructor() { }

  setIsOpen(bool) {
    if (bool !== this.isOpen$.value) {
      this.isOpen$.next(bool);
    }
  }
  getIsOpen(): Observable<boolean> {
    return this.isOpen$.asObservable();
  }
}