import { AfterViewInit, Component, ElementRef, Input, OnChanges, SimpleChanges, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ClientsService } from '../clients.service';
import { CrossfilterService } from '../../../../crossfilter/src';
// @ts-ignore
import { MapService } from '@compass/map';

@Component({
  selector: 'compass-clients-filter',
  templateUrl: './clients-filter.component.html',
  styleUrls: ['./clients-filter.component.scss']
})
export class ClientsFilterComponent implements AfterViewInit, OnChanges {
  @Input() data: [];
  @ViewChild('activitySearcher') activitySearcher: ElementRef;

  form: FormGroup;

  categories = [];
  selectedCategories = [];
  showOnMap: boolean = true;

  mapLayer: string = 'clients_layer';


  constructor(
    private clientsService: ClientsService,
    private crossfilterService: CrossfilterService,
    private mapService: MapService
  ) {
    this.form = new FormGroup({
      gender: new FormControl(),
      categories: new FormControl([])
    });

    this.clientsService.categories.subscribe((categories) => {
      this.categories = categories;
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    // if data is setted, initialize crossfilter
    if (changes.data && this.data) {
      this.crossfilterService.setData(this.data);

      // timeout to wait crossfilterService to change observable value
      setTimeout(() => {
        this.drawMarkersOnMap();

        // listen when crossfilter changes
      this.crossfilterService.crossfilter$.value.onChange(() => {
        this.drawMarkersOnMap();
      });

      });

    }
  }

  ngAfterViewInit(): void {

    // client filter changed
    this.form.controls.gender.valueChanges.subscribe(gender => {
      let dimGroup = this.crossfilterService.dim('gender', (d) => d.properties.gender);
      dimGroup.dimension.filter(gender);
    });
  }

  toggleShowPois(event) {
    this.showOnMap = event.checked;
    this.drawMarkersOnMap();
  }

  ratingCrossfilterFunc(d) {
    return d.properties.EdadTramo;
    // return d.properties.ageRange && d.properties.ageRange.length ? d.properties.ageRange[0] : 0;
  }

  clientTypeFilterFunc(d) {
    return d.properties.TipoCliente || '- sin especificar -';
  }

  onCategoriesChange(categories) {
    let dimGroup = this.crossfilterService.dim('categories', (d) => d.properties.categories);
    if (categories.length) {
      dimGroup.dimension.filter(clientCategories => {
        let clientHasCategory = false;

        clientCategories.forEach(category => {
          clientHasCategory = categories.includes(category);
          if (clientHasCategory) {
            return;
          }
        });

        return clientHasCategory;
      });
    } else {
      dimGroup.dimension.filter();
    }
  }

  drawMarkersOnMap() {
    if (this.showOnMap) {
      this.mapService.poiService.removeLayer(this.mapLayer);

      const markers = this.crossfilterService.all();
      // add popup callback
      markers.map(marker => {
        marker.popUpText = this.popUpContent;
      });

      this.mapService.poiService.drawMarkers(markers, this.mapLayer);
    } else {
      this.mapService.poiService.removeLayer(this.mapLayer);
    }
  }

  popUpContent(marker) {
    const data = marker.properties;

    let content = `
      <div>
        <h4>
          <strong><small>token</small> ${data.token}</strong> <br>
          <small>${data.direction}</small>
        </h4>
        <p>
          <small>Sexo:</small>  <strong>${data.gender} </strong> <br>
          <small>Rango de edad: </small> <strong>${data.EdadTramo} </strong> <br>
        </p>

        <hr>

        <p>
          <small>Tipo de cliente: </small> <strong>${data.TipoCliente} </strong> <br>
          <small>Tipo cocinitas: </small> <strong>${data.TipoCocinitas} </strong> <br>
          <small>Tipo cuponero: </small> <strong>${data.TipoCuponero} </strong> <br>
        </p>

        <hr>

        <small>Categorías: </small> <br> <strong>${data.categories.length ? data.categories.join(', ') : '-sin datos-'} </strong> <br>

      </div>
    `;

    return content;
  }


}
