<div class="container" [attr.data-title]="toolbarSetUp.title">
  <mat-toolbar id="toolbar" [ngClass]="toolbarSetUp.class" fxlayoutalign="space-between center"
               [ngStyle]="{
                  'min-height': toolbarSetUp.style.height,
                  height: toolbarSetUp.style.height
               }">
    <button mat-icon-button *ngIf="showMenuBtn" (click)="sidenavService.toggle()"
            [title]="menuIsOppened ? 'Cerrar menú' : 'Abrir menú'">
      <mat-icon *ngIf="!menuIsOppened">menu</mat-icon>
      <mat-icon *ngIf="menuIsOppened">menu_open</mat-icon>
    </button>

    <a [routerLink]="['/']">
      <img id="toolbar-img"
           [ngClass]="toolbarSetUp.img.class"
           [src]="toolbarSetUp.img.src"
           [alt]="toolbarSetUp.img.alt"
           [width]="toolbarSetUp.img.width" />
    </a>

    <span class="hidden">{{ toolbarSetUp.title }}</span>

    <span class="spacer"></span>

    <button mat-button
            [title]="this.authUser.username"
            [matMenuTriggerFor]="userMenu">
      <mat-icon>account_circle</mat-icon>
      {{ this.authUser.username }}
    </button>

    <mat-menu #userMenu="matMenu">
       <div mat-menu-item disabled [title]="'Bienvenid@ ' + this.authUser.username">
         <!--<mat-icon>person</mat-icon>-->
         Bienvenid@
         <strong>{{ this.authUser.username }}</strong>
       </div>

      <a mat-menu-item title="Administrar usuarios" [routerLink]="['/users']" *ngIf="isAdmin">
        <mat-icon>supervised_user_circle</mat-icon>
        <strong>Usuarios</strong>
      </a>

      <a href="#" mat-menu-item (click)="this.logout()">
        <mat-icon>logout</mat-icon>
        <strong>Salir</strong>
      </a>
    </mat-menu>
  </mat-toolbar>

  <ng-content></ng-content>
</div>
