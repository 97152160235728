import { Component, OnInit, Input } from '@angular/core';
import { SideNavIndicatorsService, Indicator, IndicatorsGroups, IndicatorGroupSelected } from '@compass/utils/navigation';
import { CompassMapWrapperService, Map } from '@compass/utils/leaflet';
import { CompassMapDrawerService } from '@compass/utils/leaflet';
import { CompassMapIndicatorsService } from '@compass/utils/leaflet';
import { MapBoxService } from '@compass/utils/misc';
import { IndicatorService } from '@compass/utils/navigation';
import { BehaviorSubject } from 'rxjs';
import { ColorsService } from '@compass/utils/misc';
import { Mathematics } from '@compass/utils/misc';
import { ThematicKeySymbol } from '@compass/utils/misc';
@Component({
  selector: 'compass-list-indicators',
  templateUrl: './list-indicators.component.html',
  styleUrls: ['./list-indicators.component.scss']
})
export class ListIndicatorsComponent implements OnInit {

  // map
  @Input() bricks: any;
  map: Map;
  // Indicators list
  indicatorGroupSelected: IndicatorGroupSelected;

  analyticsIndicators$ = this.indicatorService.analyticsIndicators$;
  activeIndicator$: BehaviorSubject<Indicator> = this.indicatorService.activeIndicator$;
  activeIndicator: Indicator;
  setActiveIndicator = this.indicatorService.setActiveIndicator;
  setAnalyticsIndicators = this.indicatorService.setAnalyticsIndicators;
  setMapCenter = this.compassMapWrapperService.setMapCenter;
  setView = this.compassMapDrawerService.setView;
  drawGeojsonLayer = this.compassMapIndicatorsService.drawGeojsonLayer;
  removeGeojsonLayer = this.compassMapDrawerService.removeGeojsonLayer;
  fitGeojsonBounds = this.compassMapDrawerService.fitGeojsonBounds;
  styling = this.compassMapIndicatorsService.styling;
  setMinMaxIndicatorScale = this.compassMapIndicatorsService.setMinMaxIndicatorScale;
  resetMinMaxIndicatorScale = this.compassMapIndicatorsService.resetMinMaxIndicatorScale;
  minMaxIndicatorsScaleExist = this.compassMapIndicatorsService.minMaxIndicatorsScaleExist;
  setBrew = this.compassMapIndicatorsService.setBrew;
  normalizeSeries = this.compassMapIndicatorsService.normalizeSeries;
  brewClasses = this.compassMapIndicatorsService.brewClasses;
  getTileLayerOptions = this.compassMapDrawerService.getTileLayerOptions;


  getMinMaxIndicatorScale = this.compassMapIndicatorsService.getMinMaxIndicatorScale;
  onEachFeature = this.compassMapIndicatorsService.onEachFeature;
  getColor = this.compassMapIndicatorsService.getColor;
  mathCeil10 = this.mathematics.MathCeil10;
  mathFloor10 = this.mathematics.MathFloor10;
  addSeparatorsNF = this.mathematics.addSeparatorsNF;
  getKeySymbol = this.thematicKeySymbol.getKeySymbol;
  drawColorKeys = this.compassMapIndicatorsService.drawColorKeys;
  dic = this.sideNavIndicatorsService.indicatorsDictionary$.value;
  propertiesDB: string = this.compassMapIndicatorsService.propertiesDB;



  constructor(
    private sideNavIndicatorsService: SideNavIndicatorsService,
    private compassMapWrapperService: CompassMapWrapperService,
    private compassMapDrawerService: CompassMapDrawerService,
    private compassMapIndicatorsService: CompassMapIndicatorsService,
    private mapBoxService: MapBoxService,
    private indicatorService: IndicatorService,
    private colorsService: ColorsService,
    private mathematics: Mathematics,
    private thematicKeySymbol: ThematicKeySymbol
  ) {
    this.sideNavIndicatorsService.getIndicatorGroupSelected().subscribe((value: IndicatorGroupSelected) => {
      this.indicatorGroupSelected = value;
      this.setActiveIndicator(this.indicatorGroupSelected.indicator.values[0]);
    });
    this.indicatorService.getActiveIndicator().subscribe((value: Indicator) => {
      this.activeIndicator = value;
    });

    this.compassMapWrapperService.map$.subscribe((map: Map) => {
      this.map = map;
      this.map.access_token = this.mapBoxService.getMapBoxUrl('light')[0].value;

    });
  }

  /* ACTIONS */

  onClickIndicator(e: Event, indicator: Indicator): void {
    e.preventDefault();
    this.setActiveIndicator(indicator);

    this.removeGeojsonLayer('geojsonLayer');
    this.removeGeojsonLayer('color_key');
    this.setMapCenter([40.434176, -3.735995]);

    if (!this.minMaxIndicatorsScaleExist()) {
      this.setMinMaxIndicatorScale();
      this.setAnalyticsIndicators(this.map.minMaxScales, this.dic);
    }


    this.drawGeojsonLayer();
    //this.setView(this.map);
    this.drawColorKeys();
    this.fitGeojsonBounds();
  }

  ngOnInit(): void {
    let x = this.bricks;
    this.removeGeojsonLayer('geojsonLayer');
    this.removeGeojsonLayer('color_key');
    this.resetMinMaxIndicatorScale();
  }

}