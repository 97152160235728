import { Component, OnInit, Input } from '@angular/core';
import { SearchboxService } from './searchbox.service';
import { HasEvents } from '../../../map/src/lib/classes/has-events';

@Component({
  selector: 'compass-map-searchbox',
  templateUrl: './map-searchbox.component.html',
  styleUrls: ['./map-searchbox.component.scss']
})
export class MapSearchboxComponent implements OnInit {
  @Input() setup: any;
  searchQuery: string;
  visible: boolean;

  constructor(private searchboxService: SearchboxService) {
  }

  search: any = () => {
    this.searchboxService.search(this.searchQuery);
  };


  onKeydown(event: any) {
    // console.log(event);
  }

  ngOnInit(): void {
    this.searchboxService.visible$.subscribe(visible => {
      this.visible = visible;
    })
  }

}
