<section class="margin__top--m">
    <div class="container">
        <form [formGroup]="formGroup">
            <mat-form-field appearance="fill">
                <mat-label>{{ parentLabel }}</mat-label>
                <mat-select formControlName="category" required>
                    <mat-option *ngFor="let option of options" [value]="option">
                        {{ option.screen_name }}
                    </mat-option>
                </mat-select>

            </mat-form-field>

            <mat-form-field appearance="fill">
                <mat-label>{{ childrenLabel }}</mat-label>
                <mat-select formControlName="indicator" required>
                    <mat-option *ngFor="let child of children" [value]="child">
                        {{ child.screen_name }}
                    </mat-option>
                </mat-select>

            </mat-form-field>
        </form>
    </div>
</section>