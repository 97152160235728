
import { Component, OnInit } from '@angular/core';
import { CompassMapIndicatorsService } from '@compass/utils/leaflet';
import { SideNavIndicatorsService } from "@compass/utils/navigation";
import { GroupedVerticalBarLayoutService } from "@compass/utils/d3";


@Component({
  selector: 'compass-indicators-sidenav',
  templateUrl: './indicators-sidenav.component.html',
  styleUrls: ['./indicators-sidenav.component.scss']
})


export class IndicatorsSidenavComponent implements OnInit {
  brickEvent: Event;
  brickDataPortals: any;
  brick_id: string;
  brickGroup: any;
  areaGroup: any;
  chartData: any;
  fullTable: any;
  tableData; any;
  indicatorsDictionary = this.sideNavIndicatorsService.indicatorsDictionary$.value
  public hour: Date | undefined = undefined;
  format: string;

  chartLayout: any = {};



  constructor(
    private compassMapIndicatorsService: CompassMapIndicatorsService,
    private sideNavIndicatorsService: SideNavIndicatorsService,
    private GroupedVerticalBarLayoutService: GroupedVerticalBarLayoutService
  ) {

    this.compassMapIndicatorsService.getBrickEventToBounce().subscribe((brick) => {

      if (brick) {
        this.hour = undefined;
        this.brickEvent = brick;
        this.brick_id = this.brickEvent["brick"].target.feature.properties.id;
        this.areaGroup = this.brickEvent["brick"]['target']['feature'].properties.data_portals2019[this.brickEvent["list"]["grupo"]];
        this.brickGroup = this.brickEvent["areaPortals"][this.brickEvent["list"]["grupo"]];
        this.fullTable = this.getDataTable(this.areaGroup, this.brickGroup);
        this.tableData = this.filterTableFormats(this.fullTable, this.brickEvent["list"].formato);
        this.format = this.getTheFormat(this.tableData[0].format);
        this.chartData = this.getChartData(this.areaGroup, this.brickGroup, this.brickEvent["list"]);
        this.chartLayout = this.GroupedVerticalBarLayoutService.getLayout("indicators_bars")[0];
        this.chartLayout["data"] = this.chartData;
        this.hour = new Date();

      }
    });
  }

  filterTableFormats(table, format) {
    return table.filter((row) => row.format === format);
  }

  getDataTable(brickGroup, areaGroup) {
    let result = [];
    Object.keys(brickGroup).forEach((brickKey) => {
      Object.keys(areaGroup).forEach((areaKey) => {
        if (brickKey === areaKey) {
          this.indicatorsDictionary.forEach((indicator) => {
            if (indicator.key === brickKey) {
              result.push({
                key: brickKey,
                group: areaGroup[brickKey],
                brick: brickGroup[brickKey],
                screen_name: indicator.screen_name,
                format: indicator.formato
              });
            }
          });
        }
      });
    });
    return result;
  }

  getChartData(brickGroup, areaGroup, list) {
    let result = {
      key: list.key,
      format: list.formato,
      categories: "Áreas",
      children: [
        { category: "brick", [list.key]: brickGroup[list.key] },
        { category: "area", [list.key]: areaGroup[list.key] }
      ]

    }
    return result;
  }

  getTheFormat(d) {
    if (d === 'Volumen') {
      return 'Volúmen (nº)';
    }
    return d;
  }

  ngOnInit(): void {
  }

}
