import { Injectable } from "@angular/core";

@Injectable({
    providedIn: "root"
})

export class HeatMatrixReshapeService {

    matrixWording = [
        { "long_name": "MEDIO QUE CUMPLE MEJOR LA FUNCIÓN:DISTRAER, DIVERTIR, ENTRETENER", "short_name": "Distraer, divertir, entretener" },
        { "long_name": "DÓNDE LE PRESTA USTED MÁS ATENCIÓN A LOS ANUNCIOS", "short_name": "Prestar atención anuncios" },
        { "long_name": "MEDIO UTILIZADO PARA INFORMARSE SOBRE OCIO Y ESPECTÁCULOS", "short_name": "Informarse ocio y espectáculos" },
        { "long_name": "DÓNDE LE SON MÁS ÚTILES LOS ANUNCIOS PARA AYUDARLE A DECIDIR SUS COMPRAS", "short_name": "Anuncios y decisión compra" },
        { "long_name": "COMO CONCLUSIÓN, EL MEDIO DE COMUNICACIÓN INDISPENSABLE PARA MÍ ES:", "short_name": "Medio indispensable" },
        { "long_name": "MEDIO UTILIZADO PARA INFORMARSE SOBRE PRODUCTOS DE DECORACIÓN Y HOGAR", "short_name": "Decoración y hogar" },
        { "long_name": "MEDIO UTILIZADO PARA INFORMARSE SOBRE PRODUCTOS DE BELLEZA Y COSMÉTICOS", "short_name": "Belleza y cosméticos" },
        { "long_name": "MEDIO UTILIZADO PARA INFORMARSE SOBRE ELECTRODOMÉSTICOS", "short_name": "Electrodomésticos" },
        { "long_name": "MEDIO UTILIZADO PARA INFORMARSE SOBRE ESTABLECIMIENTOS COMERCIALES", "short_name": "Establecimientos comerciales" },
        { "long_name": "MEDIO UTILIZADO PARA INFORMARSE SOBRE PRODUCTOS DE LIMPIEZA", "short_name": "Productos limpieza" },
        { "long_name": "MEDIO UTILIZADO PARA INFORMARSE SOBRE INFORMÁTICA Y TELEFONÍA", "short_name": "Informática y telefonía móvil" },
        { "long_name": "MEDIO UTILIZADO PARA INFORMARSE SOBRE PRODUCTOS FARMACÉUTICOS", "short_name": "Productos farmaceúticos" },
        { "long_name": "MEDIO UTILIZADO PARA INFORMARSE SOBRE MODA, ROPA Y CALZADO", "short_name": "Moda, ropa y calzado" },
        { "long_name": "MEDIO QUE CUMPLE MEJOR LA FUNCIÓN:ENCONTRAR INFORMACIONES PRÁCTICAS, CONSEJOS", "short_name": "Información práctica y consejos" },
        { "long_name": "MEDIO UTILIZADO PARA INFORMARSE SOBRE AUTOMÓVILES", "short_name": "Automóviles" },
        { "long_name": "MEDIO UTILIZADO PARA INFORMARSE SOBRE ALIMENTACIÓN Y BEBIDAS", "short_name": "Alimentación y bebidas" },
        { "long_name": "MEDIO UTILIZADO PARA INFORMARSE SOBRE PRODUCTOS FINANCIEROS", "short_name": "Productos financieros" },
        { "long_name": "MEDIO UTILIZADO PARA INFORMARSE SOBRE VIAJES", "short_name": "Viajes" },
        { "long_name": "Internet", "short_name": "Internet" },
        { "long_name": "La televisión", "short_name": "TV" },
        { "long_name": "La radio", "short_name": "Radio" },
        { "long_name": "No consta", "short_name": "NC" },
        { "long_name": "Los periódicos - diarios", "short_name": "Diarios" },
        { "long_name": "Las revistas", "short_name": "Revistas" },
        { "long_name": "El cine", "short_name": "Cine" },
        { "long_name": "Los suplementos dominicales de periódicos", "short_name": "Suplementos" },
        { "long_name": "En la calle (vallas, pantallas, autobuses, etc)", "short_name": "Exterior" },
        { "long_name": "Los periódicos-diarios", "short_name": "Diarios" }
    ];


    constructor(
    ) { }

    public reshape = (table: any, selector: Array<any>, type) => {
        if (type.key === 'aimc_matrix') {
            let target: any = table
                .filter(d => d.target === selector["target"].key && d.medio !== "No consta" && d.medio !== "En la calle (vallas, pantallas, autobuses, etc)")
                .map((d) => {
                    this.matrixWording.forEach((word) => {
                        if (d.medio === word.long_name) {
                            d['xVal'] = word.short_name;
                        }
                        if (d.tipologia === word.long_name) {
                            d['yVal'] = word.short_name;
                        }
                    })
                    return d;
                });

            let result = target;
            return result;

        }
        return [];


    };
}