import { ComponentFactoryResolver, Injectable, ViewContainerRef } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { SidenavOption } from './interfaces/sidenav-option';
import { Observable } from 'rxjs/Observable';
import { NavbarContentComponent } from './classes/navbar-content-component';
import { Router } from '@angular/router';
import { MapService } from '@compass/map';
import { StudyDetailComponent } from '../../../../../apps/lab/src/app/studies/menu/study-detail/study-detail.component';

@Injectable({
  providedIn: 'root'
})
export class SidenavService {
  private sidenavContainer: ViewContainerRef;

  private status: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private navbarBtn: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  public menuOptions: BehaviorSubject<Array<SidenavOption>> = new BehaviorSubject<Array<SidenavOption>>([]);
  public actualComponent: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  public componentsHistory: BehaviorSubject<Array<any>> = new BehaviorSubject<Array<any>>([]);

  public loading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public fullLoading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    private router: Router,
    private mapService: MapService
  ) {
  }

  public setMenu(options: Array<SidenavOption>) {
    this.menuOptions.next(options);
  }

  /**
   * Toggle SideNav status
   */
  public toggle(status = undefined): SidenavService {
    // if we recieve a bool status then use it, otherwise change actual statusl
    const newStatus = status !== undefined ? status : !this.status.value;
    this.status.next(newStatus);

    window.dispatchEvent(new Event('resize'));

    return this;
  }

  public isActive(): boolean {
    return this.status.value;
  }

  public getStatus(): Observable<any> {
    return this.status.asObservable();
  }

  public getButtonStatus(): Observable<any> {
    return this.navbarBtn.asObservable();
  }

  public showNavbarButton(): void {
    this.navbarBtn.next(true);
  }

  public hideNavbarButton(): void {
    this.navbarBtn.next(true);
  }

  public getActualComponent(): Observable<NavbarContentComponent> {
    return this.actualComponent.asObservable();
  }

  public setSidenavContainerRef(containerRef) {
    this.sidenavContainer = containerRef;
  }

  public setSelectedOption(option) {
    // navigate to route
    if (option.route) {
      this.router.navigate([option.route]);
    }

    // load the component if has
    if (option.component) {
      this.loadComponent(option);
    }

    // close menu if activeClose is setted
    if (option.activeClose) {
      setTimeout(() => {
        this.toggle(false);
      }, 100);
    }
  }

  public loadStudyPreview(study) {
    this.setSelectedOption({
      title: `Estudio #${study.properties.id} <br><b>${study.properties.study.name}</b>`,
      component: StudyDetailComponent,
      icon: 'folder'
    });
  }

  public async loadComponent(options: any, pushHistory: boolean = true): Promise<any> {
    this.loading(true, true);

    if (options?.component) {
      this.actualComponent.next(options.component);

      this.sidenavContainer.clear();

      // @ts-ignore
      const factory = this.componentFactoryResolver.resolveComponentFactory(options.component);
      const ref = this.sidenavContainer.createComponent(factory);

      ref.changeDetectorRef.detectChanges();

      options.ref = ref;

      if (pushHistory) {
        this.pushHistory(options);
      }

      this.loading(false);
    }

  }

  public pushHistory(options) {
    let history = this.componentsHistory.value;
    options.simpleTitle = options.title.replace(/<[^>]*>/g, '');

    // find element with same component in hystory
    const sameComponentIndex = history.findIndex((historyComponent) => {
      return Object.is(historyComponent.component, options.component);
    });

    // remove from history elements with same component
    if (sameComponentIndex != -1) {
      history.splice(sameComponentIndex, 9e9);
    }

    history.push(options);


    this.componentsHistory.next(history);
  }

  public backInHistory() {
    let historic = this.componentsHistory.value;
    let old = historic.pop();

    // if this element has an ref assigned (because it loaded and component) destroy it
    if (old?.ref) {
      old.ref.destroy();
    }

    this.componentsHistory.next(historic);
    this.actualComponent.next(historic[historic.length - 1]);

    if (this.actualComponent.value) {
      this.loadComponent(this.actualComponent.value, false);
    }

    return this;
  }


  public loading(isLoading, fullLoader: boolean = false) {
    // ñapa to show with a delay because sometimes the component is not loaded completelly when setted new value
    this.loading$.next(isLoading);
    // if not loading, ignore fullLoader param
    this.fullLoading$.next(!isLoading ? false : fullLoader);
  }

}
