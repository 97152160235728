import { Injectable } from "@angular/core";

@Injectable()
export class ScrollIntoViewService {
    constructor() { }

    scrollIntoView(el): void {
        if (el && el.nativeElement) {
            el?.nativeElement.scrollIntoView({
                behavior: "smooth",
                block: "start",
            });
            return;
        }
        el.scrollIntoView({
            behavior: "smooth",
            block: "start",
        });
    }
}


